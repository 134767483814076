import { parseSupportingContentItem } from "./supporting-content-parser";

import styles from "./supporting-content.module.css";
import {objToTable} from "../../helpers/htmls";

export const SupportingContent = ({ supportingContent, fileUrls, notable, fileName }) => {
    return (
        <ul className={styles.supportingContentNavList}>
            {supportingContent.map((x, i) => {
                const parsed = parseSupportingContentItem(x);

                if(!notable) {
                    parsed.content = objToTable(JSON.parse(parsed.content));

                    if(i === 0)
                        return (
                            <li className={styles.supportingContentItem}>
                                <h4 className={styles.supportingContentItemHeader}>{parsed.title}</h4>
                                <p dangerouslySetInnerHTML={{ __html: parsed.content}}/>
                            </li>
                        );
                    else return null
                }
                if(parsed.title == fileName){
                    let url = "";
                    fileUrls.forEach(element => {
                        if(element.file_name == parsed.title)
                            url = `\n\n <b> Reference:</b> <a href='${element.url}' target='_blank'> ${element.file_name} </a> `
                    });
                    if(i === 0) /// TODO added temporally, delete in the future
                        return (
                            <li className={styles.supportingContentItem}>
                                <h4 className={styles.supportingContentItemHeader}>{parsed.title}</h4>
                                {/*<p dangerouslySetInnerHTML={{ __html: (parsed.content+url)}} style={{whiteSpace: 'pre-line'}} />*/}
                                <p dangerouslySetInnerHTML={{ __html: (url)}} style={{whiteSpace: 'pre-line'}} />
                            </li>
                        );
                }
                else return null
            })}
        </ul>
    );
};
