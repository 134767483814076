import { loadConfiguration, ResourceType, LogLevel, setLogLevel, setLogFunction } from "@microsoft/teamsfx";
import { useData } from "./useData";
import { useTeams } from "msteams-react-base-component";

var startLoginPageUrl = process.env.REACT_APP_START_LOGIN_PAGE_URL;
var functionEndpoint = process.env.REACT_APP_FUNC_ENDPOINT;
var clientId = process.env.REACT_APP_CLIENT_ID;

// TODO fix this when the SDK stops hiding global state!
let initialized = false;

export function useTeamsFx() {

  console.log(clientId);
  const [result] = useTeams({});
  const { error, loading } = useData(async () => {
    if (!initialized) {
      //if (process.env.NODE_ENV === "development") {
        setLogLevel(LogLevel.Verbose);
        setLogFunction((level, message) => { console.log(message); });
      //}
      loadConfiguration({
        authentication: {
          initiateLoginEndpoint: startLoginPageUrl,
          clientId: clientId,
        },
        resources: [
          {
            type: ResourceType.API,
            name: "default",
            properties: {
              endpoint: functionEndpoint,
            },
          },
        ],
      });
      initialized = true;
    }
  });
  const isInTeams = true;
  return { error, loading, isInTeams, ...result };
}

export function useTeamsFxControl() {
  const { isInTeams } = useTeamsFx();
  return isInTeams;
}

const isInIframe = () => window.self !== window.top;

export const determineLoginContext = (teamsContext) => {
  console.log("ingress in; determineLoginContext")
  if (teamsContext.context !== undefined) {
    return 'teams';
  } else if(isInIframe()) {
    return 'external';
  } else return 'standard';
    /*if (window.location.href.includes('app.mymoodbit') || window.location.href.includes('ngrok-free.app') || window.location.href.includes('localhost')) { // || window.location.href.includes('ngrok-free.app')
    return 'standard';
  } else {
    return 'none';
  }*/
};
