import {connect, useSelector} from "react-redux";

export const useSlackDatachatGuard = (route=null) => {
    const bot_access_user = useSelector(state => state.users.bot_access_user)

    const slackDatachatAccount = bot_access_user?.slackDatachatUser;
    // console.log("AWS AWS", awsSubscription)

    return !!slackDatachatAccount;
}
