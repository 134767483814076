import {
    Avatar,
    capitalize,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Chip,
    CircularProgress,
    IconButton,
    Tooltip,
    Typography
} from "@mui/material";
import {
    AccessTime, AddPhotoAlternate,
    ArrowBack, AutoMode,
    Autorenew,
    Close,
    CloudDone,
    Done,
    Edit,
    HourglassEmpty, Refresh,
    Save
} from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { toastr } from "react-redux-toastr";
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie'
import animationData from '../stars.json'
import {
    getSingleMonthPostService,
    setNewSinglePostScheduleService, updateImageMonthPostService,
    updateSingleMonthPostService
} from "../../../../services/agent-post-ai.service";
import * as moment from "moment";
import momentTz from "moment-timezone";
import wordpressLogo from "../../../../assets/Wordpress_Blue_logo.png";
import linkedinLogo from "../../../../assets/Linkedin_blue_logo.png";
import EditTime from "../edit-time/edit-time";
import { Stack } from '@fluentui/react';

const PostShow = (props) => {
    const { post, setPost } = props;
    const [sending, setSending] = useState(false);
    const [currentPost, setCurrentPost] = useState(null);
    const [itemDateTime, setItemDateTime] = useState(null);

    const [loading, setLoading] = useState(false);
    const [editable, setEditable] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);

    const titleRef = useRef(null);
    const contentRef = useRef(null);

    const navigate = useNavigate();
    const facebookLogo = 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg';

    useEffect(() => {
        // console.log("postTestData", postTestData)
        const func = (async () => {
            try {
                setLoading(true);
                const p = await getSingleMonthPostService({
                    post_id: post.postId
                })
                console.log("POST MONTH: ", p)
                setCurrentPost(p);
                setItemDateTime(p?.postDate);
                setLoading(false)
            } catch (e) {
                setLoading(false)
                console.log("ERROR get posts", e)
                toastr.error("Error", "Something went wrong while trying to get the post, please try again in a few minutes")
            }
        })

        func();

    }, [])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        hover: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    const handleSave = async () => {
        setLoading(true);

        const p = await updateSingleMonthPostService({
            title: titleRef.current.innerText,
            content: contentRef.current.innerHTML
        }, post.postId)
        console.log("POST MONTH: ", p)
        setCurrentPost(p);
        setEditable(false)
        setLoading(false)
    }

    const handleNewContent = async () => {
        try {
            setLoading(true);
            const p = await getSingleMonthPostService({
                post_id: post.postId,
                regenerate: true,
            })
            setCurrentPost(p);
            setLoading(false);

        } catch (e) {
            setLoading(false)
            console.log("ERROR get posts", e)
            toastr.error("Error", "Something went wrong while trying to get the post, please try again in a few minutes")
        }
    }

    const handleNewImage = async () => {
        setLoadingImage(true);

        const p = await updateImageMonthPostService(post.postId)
        console.log("POST MONTH: ", p)
        if (p.newImageUrl) {
            setCurrentPost({...currentPost, image: p.newImageUrl});
        } else {
            toastr.warning("Error", "Something happened while trying to update the image, please try again.")
        }
        setLoadingImage(false)
    }

    const handleCancelEdition = () => {
        setEditable(false)
        titleRef.current.innerText = currentPost.title;
        contentRef.current.innerHTML = currentPost.content;

    }

    const handleSubmit = async () => {
        console.log("SUBMIT");

        let myTime = moment(itemDateTime).format('h:mm A');
        console.log('TIME', myTime);
        let str = myTime.split(':');

        if (window.confirm(`Are you sure you have scheduled this Post to be sent at ${myTime} on the ${post?.platform.toUpperCase()} platform?`)) {

            // let teamId = this.props.selectedTeam;

            let obj = {
                day: moment(itemDateTime).format('D'),
                month: moment(itemDateTime).format('M'),
                year: moment(itemDateTime).format('YYYY'),
                hours: str[0],
                minutes: str[1],
                timeZone: momentTz.tz.guess(),
                // surveyId: this.props.surveyId,
                post_id: currentPost?.postId,
                platform: currentPost?.platform,
                // post: this.props.post,
                // credentials: this.props.credentials,
                // state: true,
                // teamId: teamId
            };
            console.log("SCHEDULE OBJ", obj);

            // await this.props.getUserSlack(this.context.accounts[0].username);

            // let res = await axios.post("https://slack-survey-schedule.azurewebsites.net/api/orchestrators/NewSchedule", obj);
            let res = await setNewSinglePostScheduleService(obj);
            // let res = {};
            console.log(res);
            if (res.id) {
                // obj.analyticsPostAiId = this.props.post.id;
                // obj.id = res.id;

                // await this.props.addScheduleSlack(obj);
                // await addSchedulePostAIService(obj);
                await updateSingleMonthPostService({ status: 'approved' }, currentPost?.postId)
                currentPost.status = 'approved';
                //const navigate = useNavigate();
                // this.setState({message: "Successfully scheduled"});
                // this.setState({success: true});
                // navigate('/stored-post');
                toastr.success("Success", "Post Approved")
                setPost(null)
            }
            // this.setState({ sending: false });
            setSending(false)
            // this.props.getAllPostaisWithSchedule(this.props.user.id);
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <IconButton color="primary" size="large" onClick={() => setPost(null)}>
                        <ArrowBack />
                    </IconButton>
                </div>
            </div>
            {loading ? <div className="d-flex justify-content-center text-center">
                <Stack.Item grow>
                    <p>
                        <div className="h-24">
                            <CircularProgress />
                            <div className="cubespinner">
                                <div className="face1">
                                    <Typography sx={{ fontSize: '15px', color: 'blue', fontWeight: '600', textAlign: 'center' }}>Hold on while we are generating content for you...</Typography>
                                </div>
                                <div className="face2">
                                    <Typography sx={{ fontSize: '15px', color: 'blue', fontWeight: '600', textAlign: 'center' }}>AI Agent is working on it...</Typography>
                                </div>
                                <div className="face3">
                                    <Typography sx={{ fontSize: '15px', color: 'blue', fontWeight: '600', textAlign: 'center' }}>Processing the data...</Typography>
                                </div>
                            </div>
                        </div>
                    </p>
                </Stack.Item>

            </div> :
                <Card className="col-lg-4 offset-lg-4 col-md-8 offset-md-2 my-3">
                    <div className="mx-2 my-2 d-flex justify-content-between align-items-center">
                        <div className="me-1 d-flex align-items-center">
                            <div className="me-1">
                                {currentPost?.platform === 'wordpress' &&
                                    <Avatar sx={{ width: 24, height: 24 }} alt="wordpress" src={wordpressLogo} />}
                                {currentPost?.platform === 'facebook' &&
                                    <Tooltip title={currentPost?.typeUserInfo?.page_id}><Avatar
                                        sx={{ width: 24, height: 24 }} alt="facebook" src={facebookLogo} /></Tooltip>}
                                {currentPost?.platform === 'linkedin' &&
                                    <Tooltip title={currentPost?.typeUserInfo?.user_sub}><Avatar
                                        sx={{ width: 24, height: 24 }} alt="linkedin" src={linkedinLogo} /></Tooltip>}
                            </div>
                            {currentPost?.status === 'approved' &&
                                <Chip label="Approved" color="primary" variant="outlined" icon={<Done />} />}
                            {currentPost?.status === 'published' &&
                                <Chip label="Published" color="success" variant="outlined" icon={<CloudDone />} />}
                            {currentPost?.status === 'not-approved' &&
                                <Chip label="No Published" color="warning" variant="outlined"
                                    icon={<HourglassEmpty />} />}
                        </div>
                        {currentPost?.status === 'not-approved' && <div className="">
                            {/**APPROVE POST*/}
                            {!editable && <Tooltip title={'Approve'}>
                                <IconButton color="primary" onClick={() => handleSubmit()}>
                                    {sending ? <Lottie options={defaultOptions} height={40} width={40} /> : <Done />}
                                </IconButton>
                            </Tooltip>}

                            {/**UPDATE IMAGE*/}
                            {!editable &&
                                <Tooltip title={'New Image'}>
                                    <IconButton onClick={() => handleNewImage()} disabled={loadingImage}>
                                        <AddPhotoAlternate />
                                    </IconButton>
                                </Tooltip>}

                            {/**UPDATE CONTENT*/}
                            {!editable &&
                                <Tooltip title={'New Content'}>
                                    <IconButton onClick={() => handleNewContent()} disabled={loading}>
                                        <AutoMode />
                                    </IconButton>
                                </Tooltip>}

                            {/**EDIT TIME POST*/}
                            {!editable && <EditTime post={currentPost} itemDateTime={itemDateTime} updateTime={setItemDateTime} />}

                            {editable &&
                                <Tooltip title={'Save'}>
                                    <IconButton color="primary" onClick={() => handleSave()}>
                                        <Save />
                                    </IconButton>
                                </Tooltip>}

                            {editable ?
                                <Tooltip title={'Cancel Edition'}>
                                    <IconButton color="error" onClick={() => handleCancelEdition()}>
                                        <Close />
                                    </IconButton>
                                </Tooltip> :
                                <Tooltip title={'Edit Content'}>
                                    <IconButton onClick={() => setEditable(true)}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>}
                    </div>
                    {currentPost?.image && !loadingImage && <CardMedia
                        component="img"
                        image={currentPost?.image}
                        alt="post image"
                    />}
                    {loadingImage &&
                        <div className="text-center">
                            <CircularProgress />
                            <p>Generating new image</p>
                        </div>
                    }
                    <CardHeader
                        title={<span contentEditable={editable}
                            ref={titleRef}
                            className={editable && 'border-edit border-success px-1 line-break'}
                            autoFocus={editable}
                            suppressContentEditableWarning={true}>
                            {currentPost?.title}
                        </span>}
                        subheader={<span><AccessTime /> <b>{moment(itemDateTime).format('h:mm A')}</b></span>} />
                    <CardContent>
                        <Typography gutterBottom component="div" color="text.secondary" className="mb-3">
                        </Typography>
                        <Typography variant="body1"
                            className={editable && 'border-edit border-success p-2 line-break'}
                            style={{
                                whiteSpace: 'pre-line'
                            }} contentEditable={editable}
                            ref={contentRef}
                            autoFocus={editable}
                            suppressContentEditableWarning={true}
                            dangerouslySetInnerHTML={{ __html: currentPost?.content }} />
                    </CardContent>
                    <CardActions className="justify-content-end">
                    </CardActions>
                </Card>}
        </>
    )
}

export default PostShow;
