import {renderToStaticMarkup} from "react-dom/server";
import {getCitationFilePathHR} from "../../../../../services/agent-hr.service";
import {Assistant} from "@mui/icons-material";
import {Chip} from "@mui/material";
import styles from "./answer.module.css";


// Función para generar la tabla HTML
function objToTable(data) {
    /*data = [
        {Nombre: 'Juan', Edad: 30, Ciudad: 'Ciudad A'},
        {Nombre: 'María', Edad: 25, Ciudad: 'Ciudad B'},
        {Nombre: 'Pedro', Edad: 35, Ciudad: 'Ciudad A'},
    ];*/
    // Verificar si hay datos en el array
    if (data.length === 0) {
        return '';
    }

    // Obtener las propiedades de los objetos (encabezados de columna)
    const headers = Object.keys(data[0]);

    // Crear la estructura de la tabla
    let tablaHTML = renderToStaticMarkup(
        <div className="position-relative w-100">
            <div className="table-content table-responsive" style={{maxHeight: '70vh'}}>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                        {headers.map((header) => {
                            const regex = /\[(.*?)\]/;
                            return (<th>{regex.exec(header)[1]}</th>)
                        })}
                        </tr>
                    </thead>
                    {data.map((row) => {
                            return (
                                <tbody>
                                    <tr>
                                        {headers.map((cel) => <td dangerouslySetInnerHTML={{__html: row[cel]}}/>)}
                                    </tr>
                                </tbody>
                            )
                        })
                    }
                </table>
            </div>
        </div>)

    return tablaHTML;
}

export const parseFollowUp = (data) => {
    const followupQuestions = [];
    if (data.followup) {
        data.followup.replace(/<<([^>>]+)>>/g, (match, content) => {
            followupQuestions.push(content);
            return "";
        });
        // trim any whitespace from the end of the answer after removing follow-up questions

    }
    return followupQuestions
}

export const parseAnswerToHtml = (answer, onCitationClicked, user, containerName, data, chatTheme, onFollowupQuestionClicked) => {
    const citations = [];
    const examples = [];

    // Extract any follow-up questions that might be in the answer
    let count = 0;
    let parsedAnswer = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
        // followupQuestions.push(content);
        examples.push(content);
        /*console.log("CONTENT: ", content, match)
        return renderToStaticMarkup(
            <a style={{
                color: chatTheme.followupBg,
                borderColor: chatTheme.followupBg
            }} className={`${styles.followupQuestion}`} title={content}
               onClick={() => {
                   console.log("CLICK")
                   // onFollowupQuestionClicked(content)
               }}>
                {`${content}`}
            </a>)*/
        count++;
        if (count === 1) return "##########";
        else return ""
    });
    parsedAnswer = parsedAnswer.trim();

    /// check if DAX data exist

    if(data.daxResult) {
        parsedAnswer = objToTable(data.daxResult);//  + parsedAnswer;
        // parsedAnswer = objToTable(data.daxResult) + parsedAnswer;
    }

    if (parsedAnswer.includes("**")) {
        /*parsedAnswer = parsedAnswer.replaceAll(' **', ' <b>')
        parsedAnswer = parsedAnswer.replaceAll('** ', '</b> ')*/
        parsedAnswer = parsedAnswer.replace(/\*\*(.*?)\*\*/g, function(match, content) {
            return '<b>' + content + '</b>';
        });
    }

    let linkAnswer = parsedAnswer;
    let pos = 0;
    while (parsedAnswer.includes('http', pos)) {
        pos = parsedAnswer.indexOf('http', pos);
        let i = pos + 1;
        while (parsedAnswer[i] !== ' ' && parsedAnswer[i] !== '\n' && parsedAnswer[i] !== ')' && i <= parsedAnswer.length) {
            i++;
        }
        let initString = parsedAnswer.substring(0, pos);
        let endString = parsedAnswer.substring(i + 1, parsedAnswer.length - 1);
        let url = parsedAnswer.substring(pos, i);

        parsedAnswer = initString + renderToStaticMarkup(<a href={url} rel="noopener noreferrer"
                                                            target="_blank">{url}</a>) + endString;
        // parsedAnswer = initString + url.link(url) + endString;

        pos = pos + url.length * 2 + 57;
    }

    // console.warn('MY TABLE', parsedAnswer)
    //verify if we have a table
    while (parsedAnswer.includes('|-') || parsedAnswer.includes('| -') || parsedAnswer.includes('|:-')) {
        //initial and end position of the TABLE
        let init = parsedAnswer.indexOf('|');
        // let end = parsedAnswer.lastIndexOf('|');
        let end = parsedAnswer.indexOf('|\n\n') === -1 ?
            parsedAnswer.lastIndexOf('|') === -1 ?
                init + 1 : parsedAnswer.lastIndexOf('|') :
            parsedAnswer.indexOf('|\n\n');

        // console.log("ENDDDDD", end);

        //init string, end string between table, and table string
        let initString = parsedAnswer.substring(0, init);
        let endString = parsedAnswer.substring(end + 1, parsedAnswer.length);

        let tableString = parsedAnswer.substring(init, end + 1);

        // generate the table
        let rows = tableString.split('\n');
        parsedAnswer = renderToStaticMarkup(
            <div className="position-relative w-100">
                <p dangerouslySetInnerHTML={{__html: initString}}/>
                <div className="table-content table-responsive" style={{maxHeight: '70vh'}}>
                    <table className="table table-bordered">
                        {rows.map((row, index) => {
                            const cels = row.split("|");
                            if (cels.length > 1 && index !== 1) {
                                // console.log(cels);
                                cels.pop();
                                cels.shift();
                                return (
                                    index === 0 ?
                                        <thead>
                                        <tr>
                                            {cels.map((cel) => <th>{cel}</th>)}
                                        </tr>
                                        </thead> :
                                        <tbody>
                                        <tr>
                                            {cels.map((cel) => <td dangerouslySetInnerHTML={{__html: cel}}/>)}
                                        </tr>
                                        </tbody>
                                )
                            }
                        })}
                    </table>
                </div>
                <p dangerouslySetInnerHTML={{__html: endString}}/>
            </div>
        );
    }
    let finalResult = '';

    // console.log("PREV RESPONSE", parsedAnswer)
    parsedAnswer = parsedAnswer.replace(/\[\[([^^]+)\]\]/g, (match, content) => {
        // console.log("TEXT", match, content)
        finalResult = content;
        return `<em><b>${content}</b></em>`
    })

    parsedAnswer = parsedAnswer.replace(/\[([^\]]+)\]/g, (match, content) => {
        // console.log("strange match", content)
        return `(${content})`
    })

    const parts = parsedAnswer.split(/\[([^\]]+)\]/g);

    const fragments = parts.map((part, index) => {
        // console.log('PART' + index, part);

        if (index % 2 === 0) {
            return part;
        } else {
            let citationIndex;
            if (citations.indexOf(part) !== -1) {
                citationIndex = citations.indexOf(part) + 1;
            } else {
                citations.push(part);
                citationIndex = citations.length;
            }

            const path = getCitationFilePathHR(part, user.id, containerName);
            // console.log('path', path);

            return renderToStaticMarkup(
                <a className="supContainer" title={part} onClick={() => onCitationClicked(path)}>
                    <sup>{citationIndex}</sup>
                </a>
            );
        }
    });
    // console.log('RESULT PARSE: ', fragments.join(""), citations, examples, finalResult);
    return {
        answerHtml: fragments.join(""),
        citations,
        examples,
        finalResult
    };
}
