import DashboardActionTypes from "./dashboard.types";

const INITIAL_STATE = {
  countDashboard: {},
  countBarDashboard: {},
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
  console.log("PL", action);
  switch (action.type) {
    case DashboardActionTypes.GET_DASHBOARD:
      return { ...state, countDashboard: action.payload };
    case DashboardActionTypes.GET_BAR_DASHBOARD:
      return { ...state, countBarDashboard: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
