import {renderToStaticMarkup} from "react-dom/server";
import {getCitationFilePathHR} from "../../../../../services/agent-hr.service";


// Función para generar la tabla HTML
function objToTable(data) {
    /*data = [
        {Nombre: 'Juan', Edad: 30, Ciudad: 'Ciudad A'},
        {Nombre: 'María', Edad: 25, Ciudad: 'Ciudad B'},
        {Nombre: 'Pedro', Edad: 35, Ciudad: 'Ciudad A'},
    ];*/
    // Verificar si hay datos en el array
    if (data.length === 0) {
        return '';
    }

    // Obtener las propiedades de los objetos (encabezados de columna)
    const headers = Object.keys(data[0]);

    // Crear la estructura de la tabla
    let tablaHTML = renderToStaticMarkup(
        <div className="position-relative w-100">
            <div className="table-content table-responsive" style={{maxHeight: '70vh'}}>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                        {headers.map((header) => {
                            const regex = /\[(.*?)\]/;
                            return (<th>{regex.exec(header)[1]}</th>)
                        })}
                        </tr>
                    </thead>
                    {data.map((row) => {
                            return (
                                <tbody>
                                    <tr>
                                        {headers.map((cel) => <td dangerouslySetInnerHTML={{__html: row[cel]}}/>)}
                                    </tr>
                                </tbody>
                            )
                        })
                    }
                </table>
            </div>
        </div>)

    return tablaHTML;
}

export const parseFollowUp = (data) => {
    const followupQuestions = [];
    if (data.followup) {
        data.followup.replace(/<<([^>>]+)>>/g, (match, content) => {
            followupQuestions.push(content);
            return "";
        });
        // trim any whitespace from the end of the answer after removing follow-up questions

    }
    return followupQuestions
}

export const parseAnswerToHtml = (answer, onCitationClicked, user, containerName, data, chatTheme) => {
    const citations = [];

    if(answer == '' || answer == undefined)
        return ''

    // Extract any follow-up questions that might be in the answer
    let parsedAnswer = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
        // followupQuestions.push(content);
        return "";
    });
    parsedAnswer = parsedAnswer.trim();

    /// check if DAX data exist

    if(data.daxResult) {
        parsedAnswer = objToTable(data.daxResult);//  + parsedAnswer;
        // parsedAnswer = objToTable(data.daxResult) + parsedAnswer;
    }

    if (parsedAnswer.includes("**")) {
        /*parsedAnswer = parsedAnswer.replaceAll(' **', ' <b>')
        parsedAnswer = parsedAnswer.replaceAll('** ', '</b> ')*/
        parsedAnswer = parsedAnswer.replace(/\*\*(.*?)\*\*/g, function(match, content) {
            return '<b>' + content + '</b>';
        });
    }

    // Adding breaklines after every ?
    parsedAnswer = parsedAnswer.replaceAll("?", "? <br>")

    // Replacing CCS coincidences
    let ccsPatterns=["College for Creative Studies (CCS)", " CCS ", "(CCS)", "CCS.", "CCS,", "College for Creative Studies" ]
    for(let idx in ccsPatterns){        
        parsedAnswer = parsedAnswer.replaceAll(ccsPatterns[idx], ` <b><a href="https://www.ccsdetroit.edu/" target="_blank">CCS</a></b> `)
    }


    const courseLinks = {
        "BASIC PATTERNS AND SEWING": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-AEXP-071-A1A&courseId=AEXP-071&categoryId=6F2F5678",
        "FIGURE DRAWING": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-ADRW-301-A1A&courseId=ADRW-301&categoryId=6F2F5678",
        "CERAMICS: TILE MAKING": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-AVIS-101-A1A&courseId=AVIS-101&categoryId=6F2F5678",
        "INTRODUCTION TO PROCREATE": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-AGRC-157-A1A&courseId=AGRC-157&categoryId=6F2F5678",
        "CERAMICS: HAND BUILDING": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-AVIS-005-A1A&courseId=AVIS-005&categoryId=6F2F5678",
        "JEWELRY MAKING WORKSHOP: METAL AND STONE": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-AVIS-022-A1A&courseId=AVIS-022&categoryId=6F2F5678",
        "CERAMICS: WHEEL THROWING": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-AVIS-100-A1A&courseId=AVIS-100&categoryId=6F2F5678",
        "CARVING SCULPTURES": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-AVIS-148-A1A&courseId=AVIS-148&categoryId=6F2F5678",
        "WELDED METAL SCULPTURE": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-AVIS-064-A1A&courseId=AVIS-064&categoryId=6F2F5678",
        "GLASSBLOWING SHORT COURSE": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-AVIS-285-A1A&courseId=AVIS-286&categoryId=6F2F5678",
        "INTRODUCTION TO DIGITAL CAMERAS": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-AVIS-300-A1A&courseId=AVIS-300&categoryId=6F2F5678",
        "BOTANICAL PASTELS AT BELLE ISLE": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-ADRW-287-A1A&courseId=ADRW-287&categoryId=6F2F5678",
        "MIXED MEDIA PHOTOGRAPHY": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-AVIS-304-A1A&courseId=AVIS-304&categoryId=6F2F5678",
        "WATERCOLORS ON THE GO": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-ADRW-292-A1A&courseId=ADRW-292&categoryId=6F2F5678",
        "FELTING WORKSHOP": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-AVIS-114-A1A&courseId=AVIS-114&categoryId=6F2F5678",
        "PHOTOGRAPHING YOUR ARTWORK: 2D AND 3D": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-AVIS-307-A1A&courseId=AVIS-307&categoryId=6F2F5678",
        "DRAWING HEADS, HANDS AND FEET": "https://www.enrole.com/ccs/jsp/session.jsp?sessionId=24SU-ADRW-079-A1A&courseId=ADRW-079&categoryId=6F2F5678"

      };
      for (const course in courseLinks) {
        if (courseLinks.hasOwnProperty(course)) {
          const link = courseLinks[course];
          // Create a regular expression to find the course title (case-insensitive)
          const regex = new RegExp(`\\b${course}\\b`, 'gi'); // \b for word boundary, 'gi' for case-insensitive global match
          // Replace the course title with a hyperlink
          parsedAnswer = parsedAnswer.replace(regex, `<b><a href="${link}">${course}</a></b>`);
        }
      }
    

    // let linkAnswer = parsedAnswer;
    // let pos = 0;
    // while (parsedAnswer.includes('http', pos)) {
    //     pos = parsedAnswer.indexOf('http', pos);
    //     let i = pos + 1;
    //     while (parsedAnswer[i] !== ' ' && parsedAnswer[i] !== '\n' && parsedAnswer[i] !== ')' && i <= parsedAnswer.length) {
    //         i++;
    //     }
    //     let initString = parsedAnswer.substring(0, pos);
    //     let endString = parsedAnswer.substring(i + 1, parsedAnswer.length - 1);
    //     let url = parsedAnswer.substring(pos, i);

    //     parsedAnswer = initString + renderToStaticMarkup(<a href={url} rel="noopener noreferrer"
    //                                                         target="_blank">{url}</a>) + endString;
    //     // parsedAnswer = initString + url.link(url) + endString;

    //     pos = pos + url.length * 2 + 57;
    // }

    // console.warn('MY TABLE', parsedAnswer)
    //verify if we have a table
    while (parsedAnswer.includes('|-') || parsedAnswer.includes('| -') || parsedAnswer.includes('|:-')) {
        //initial and end position of the TABLE
        let init = parsedAnswer.indexOf('|');
        // let end = parsedAnswer.lastIndexOf('|');
        let end = parsedAnswer.indexOf('|\n\n') === -1 ?
            parsedAnswer.lastIndexOf('|') === -1 ?
                init + 1 : parsedAnswer.lastIndexOf('|') :
            parsedAnswer.indexOf('|\n\n');

        // console.log("ENDDDDD", end);

        //init string, end string between table, and table string
        let initString = parsedAnswer.substring(0, init);
        let endString = parsedAnswer.substring(end + 1, parsedAnswer.length);

        let tableString = parsedAnswer.substring(init, end + 1);

        // generate the table
        let rows = tableString.split('\n');
        parsedAnswer = renderToStaticMarkup(
            <div className="position-relative w-100">
                <p dangerouslySetInnerHTML={{__html: initString}}/>
                <div className="table-content table-responsive" style={{maxHeight: '70vh'}}>
                    <table className="table table-bordered" style={{
                        // color: "#fff",
                        color: chatTheme.answerText,
                    }}>
                        {rows.map((row, index) => {
                            const cels = row.split("|");
                            if (cels.length > 1 && index !== 1) {
                                // console.log(cels);
                                cels.pop();
                                cels.shift();
                                return (
                                    index === 0 ?
                                        <thead>
                                        <tr>
                                            {cels.map((cel) => <th>{cel}</th>)}
                                        </tr>
                                        </thead> :
                                        <tbody>
                                        <tr>
                                            {cels.map((cel) => <td dangerouslySetInnerHTML={{__html: cel}}/>)}
                                        </tr>
                                        </tbody>
                                )
                            }
                        })}
                    </table>
                </div>
                <p dangerouslySetInnerHTML={{__html: endString}}/>
            </div>
        );
    }


    const parts = parsedAnswer.split(/\[([^\]]+)\]/g);

    const fragments = parts.map((part, index) => {
        // console.log('PART' + index, part);

        if (index % 2 === 0) {
            return part;
        } else {
            let citationIndex;
            if (citations.indexOf(part) !== -1) {
                citationIndex = citations.indexOf(part) + 1;
            } else {
                citations.push(part);
                citationIndex = citations.length;
            }

            const path = getCitationFilePathHR(part, user.id, containerName);
            // console.log('path', path);

            return renderToStaticMarkup(
                <a className="supContainer" title={part} onClick={() => onCitationClicked(path)}>
                    <sup>{citationIndex}</sup>
                </a>
            );
        }
    });
    // console.log('RESULT', fragments.join(""), citations, followupQuestions);
    return {
        answerHtml: fragments.join(""),
        citations
    };
}

export const parseTextToHtml = (text, chatTheme) => {
    // Extract any follow-up questions that might be in the answer
    /*let parsedAnswer = text.replace(/<<([^>>]+)>>/g, (match, content) => {
        // followupQuestions.push(content);
        return "";
    });*/
    let parsedAnswer = text.trim() || "";

    if (parsedAnswer.includes("**")) {
        parsedAnswer = parsedAnswer.replace(/\*\*(.*?)\*\*/g, function(match, content) {
            return '<b>' + content + '</b>';
        });
    }

    let pos = 0;
    while (parsedAnswer.includes('http', pos)) {
        pos = parsedAnswer.indexOf('http', pos);
        let i = pos + 1;
        while (parsedAnswer[i] !== ' ' && parsedAnswer[i] !== '\n' && parsedAnswer[i] !== ')' && i <= parsedAnswer.length) {
            i++;
        }
        let initString = parsedAnswer.substring(0, pos);
        let endString = parsedAnswer.substring(i + 1, parsedAnswer.length - 1);
        let url = parsedAnswer.substring(pos, i);

        parsedAnswer = initString + renderToStaticMarkup(<a href={url} rel="noopener noreferrer"
                                                            target="_blank">{url}</a>) + endString;
        pos = pos + url.length * 2 + 57;
    }

    while (parsedAnswer.includes('|-') || parsedAnswer.includes('| -') || parsedAnswer.includes('|:-')) {
        let init = parsedAnswer.indexOf('|');
        let end = parsedAnswer.indexOf('|\n\n') === -1 ?
            parsedAnswer.lastIndexOf('|') === -1 ?
                init + 1 : parsedAnswer.lastIndexOf('|') :
            parsedAnswer.indexOf('|\n\n');

        //init string, end string between table, and table string
        let initString = parsedAnswer.substring(0, init);
        let endString = parsedAnswer.substring(end + 1, parsedAnswer.length);

        let tableString = parsedAnswer.substring(init, end + 1);

        // generate the table
        let rows = tableString.split('\n');
        parsedAnswer = renderToStaticMarkup(
            <div className="position-relative w-100">
                <p dangerouslySetInnerHTML={{__html: initString}}/>
                <div className="table-content table-responsive" style={{maxHeight: '70vh'}}>
                    <table className="table table-bordered" style={{
                        // color: "#fff",
                        color: chatTheme.answerText,
                    }}>
                        {rows.map((row, index) => {
                            const cels = row.split("|");
                            if (cels.length > 1 && index !== 1) {
                                // console.log(cels);
                                cels.pop();
                                cels.shift();
                                return (
                                    index === 0 ?
                                        <thead>
                                        <tr>
                                            {cels.map((cel) => <th>{cel}</th>)}
                                        </tr>
                                        </thead> :
                                        <tbody>
                                        <tr>
                                            {cels.map((cel) => <td dangerouslySetInnerHTML={{__html: cel}}/>)}
                                        </tr>
                                        </tbody>
                                )
                            }
                        })}
                    </table>
                </div>
                <p dangerouslySetInnerHTML={{__html: endString}}/>
            </div>
        );
    }
    return {
        textHtml: parsedAnswer
    };
}
