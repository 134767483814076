import axios from "axios";

export const generateContentService = async (payload) => {
    const { data } = await axios.post(`https://moodbit-content.azurewebsites.net/api/generate`, payload);
    // const { data } = await axios.post(`http://localhost:7071/api/generate`, payload);
    return data;
}

export const getCustomeTopicsService = async (payload) => {
    const { data } = await axios.post(`https://new-messages-webhook-dev.azurewebsites.net/api/get-chats-topics`, payload);
    return data;
}

export const saveCountTokensWordsService = async (payload) => {
    const { data } = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/counts/save-count-tokens-words`, payload);
    return data;
}

export const getCountTokensWordsService = async (idWorkspace) => {
    const { data } = await axios.get(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/counts/get-counts-workspace/${idWorkspace}`);
    return data;
}
