import React, { useEffect, useState } from 'react';
import { Chip, CircularProgress, Tooltip, Badge } from "@mui/material"; // Reutilizando componentes de MUI como en LinkedIn
import { getUserWithEmailThunk } from '../../redux/users/users.thunk';
import { connect } from "react-redux";
import { setNewTwitterIntegrationService, disconnectTwitterIntegrationService } from "../../services/agent-post-ai.service";
import { toastr } from 'react-redux-toastr';

const CustomChip = (props) => {
    return (
        <Chip
            variant="filled"
            disabled={props.status === 'loading'} 
            avatar={
                props.status === 'loading' ? (
                    <CircularProgress size={20} />
                ) : (
                    <img 
                        style={{ width: '35px', height: '35px' }} 
                        src="https://upload.wikimedia.org/wikipedia/commons/2/21/1690643591twitter-x-logo-png.webp" 
                        alt="Twitter Logo" 
                    />
                )
            }
            label="Twitter"
            {...props}
        />
    );
}

function sha256(plain) {
    // returns promise ArrayBuffer
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest("SHA-256", data);
  }
  
function base64urlencode(a) {
    let str = "";
    const bytes = new Uint8Array(a);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
    }
    return btoa(str)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
}
  
async function generateCodeChallengeFromVerifier(codeVerifier) {
    const hashed = await sha256(codeVerifier);
    const base64encoded = base64urlencode(hashed);
    return base64encoded;
}

const TwitterButton = (props) => {
    const [user, setUser] = useState(null);
    const [status, setStatus] = useState('unknown');

    const existTwitterAccount = () => {
        return props.user.analytics_twitter_integrations !== null && props.user.analytics_twitter_integrations?.length > 0;
    }

    const disconnect = async () => {
        if (window.confirm(`Are you sure you want to disconnect your Twitter account ${props.user.analytics_twitter_integrations[0]['userName']}?`)) {
            setStatus("loading")
            await disconnectTwitterIntegrationService(props.user.analytics_twitter_integrations[0]['id']);
            await props.getUser(props.user.email);
            setStatus("loaded")
        }
    }

    const handleButtonClick = async () => {
        const codeVerifier = "your_secure_random_string"
        const codeChallenge = await generateCodeChallengeFromVerifier(codeVerifier);
        //const authorizationUrl = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TWITTER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_TWITTER_REDIRECT_URI}&state=secure_random_state&scope=tweet.write%20tweet.read%20users.read&code_challenge=${codeChallenge}&code_challenge_method=S256`;
        //const authorizationUrl = `https://f3d2-2800-cd0-ad3b-5a00-8428-ea2-3c8a-c0b1.ngrok-free.app/login`
        //console.log("Authorization URL:", authorizationUrl);
        setStatus('connected');

        const popup = window.open(`${process.env.REACT_APP_BACK_AI_POST}/auth/twitter/auth_url`, 'popup', 'width=600,height=600');
        console.log("Popup opened");

        const handleMessage = async (event) => {
            if(event.data.integration_id == undefined)
                return;
            setStatus("loading")
            console.log("Received event TOKTOKT:", event.data);
            const { integration_id } = event.data;
            console.log("Received data:", integration_id);

            try {
                const response = await setNewTwitterIntegrationService(integration_id, props.user.id); // update the analitycs user ifd in the tiktok integration
                // console.log("RESPONSE INTEGRATION: ", accessToken, sub, vanityName, organizationList, props.user.id );
                console.log("RESPONSE INTEGRATION: ", integration_id, props.user.id, response);
                await props.getUser(props.user.email);

                toastr.success("Connected!", "You have successfully logged in to your Twitter account");
                // alert("TikTok integration successful");
            } catch (error) {
                console.error("Failed to set Twitter integration:", error);
                toastr.error("Failed to set Twitter integration");
                // alert("Failed to set TikTok integration");
            } finally {
                setStatus("loaded")
                window.removeEventListener('message', handleMessage);
                if (popup) {
                    popup.close();
                }
            }
        };
        window.addEventListener('message', handleMessage);

    };

    const handleLogout = () => {
    };

    return (
        <Tooltip
            title={existTwitterAccount() ? props.user.analytics_twitter_integrations[0]["userName"] : ''}
            className="w-100"
        >
            <Badge color="success" variant="dot" invisible={!existTwitterAccount()}>
                {existTwitterAccount() ? (
                    <CustomChip
                        label={<small className="text-black-50">Connected</small>}
                        className="py-4 w-100 justify-content-center"
                        onClick={() => {}}
                        onDelete={() => disconnect()}
                        status={status}
                    />
                ) : (
                    <CustomChip
                        className="py-4 w-100"
                        onClick={handleButtonClick}
                        status={status}
                    />
                )}
            </Badge>
        </Tooltip>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.users.user,
        status: state.users.status,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (email) => dispatch(getUserWithEmailThunk(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TwitterButton);
