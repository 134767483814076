import { Stack } from '@fluentui/react'
import { animated, useSpring } from '@react-spring/web'

import Lottie from 'react-lottie'
import animationData from './stars.json'
// import animationData from './dots.json'

import styles from './answer.module.css'
import './text-rotation.css'
import { AnswerIcon } from './answer-icon'
import { ThreeDots } from 'react-loader-spinner'
import pose from "../../../../../assets/pose.png";
import {Avatar} from "@mui/material";
import {connect} from "react-redux";

const AnswerLoading = (props) => {
  const animatedStyles = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const defaultOptions = {
    loop: true,
    autoplay: true,
    hover: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <animated.div style={{ ...animatedStyles }}>
      <Stack
        className={styles.answerContainerLoading}
        verticalAlign="space-between"
        style={{flexFlow: 'row', maxWidth: 'none'}}
      >
        {/*<AnswerIcon />*/}
        <div style={{ width: '40px' }}>
          {props.chatTheme?.logo ? <Avatar src={props.chatTheme.logo}/> :<Lottie options={defaultOptions} height={40} width={40} />}
        </div>
        <Stack.Item grow>
          <p className={styles.answerTextLoading}>
            <div className="h-24">
              <div className="cubespinner">
                <div className="face1">
                  Hold on while we are generating insights for you...
                </div>
                <div className="face2">
                  Copilot is working on it...
                </div>
                <div className="face3">
                  Processing the data...
                </div>
              </div>
            </div>
          </p>
        </Stack.Item>
      </Stack>
    </animated.div>
  )
}

const mapStateToProps = (state) => {
  console.log('state app:', state)
  return {
    chatTheme: state.agentHrs.chatTheme
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnswerLoading)
