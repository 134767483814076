
export const permissionGuard = (user, route) => {
    let hasPermissions = true;
    route.permissions?.map((permission) => {
        if (user?.copilotAccess) {
            if (!user.copilotAccess[permission]) {
                hasPermissions = false;
            }
        } else {
            hasPermissions = false;
        }
    })
    return hasPermissions;
}

export const noPermissionGuard = (user, route) => {
    let hasPermissions = true;
    route.noPermissions?.map((permission) => {
        if (user?.copilotAccess) {
            if (user.copilotAccess[permission]) {
                hasPermissions = false;
            }
        }
    })
    return hasPermissions;
}
