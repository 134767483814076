import { apiRequest } from './azure_auth/authConfig'
import { msalInstance, msalInstanceOneDrive } from './index'
import {loginRequestJWT} from "./azure_auth/authConfigOneDrive";

export async function bearerToken() {
    
  const account = msalInstance.getActiveAccount()
  // console.log("ACTIVE ACCOUNT: ", account )
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    )
  }

  let response;
  try {
    response = await msalInstance.acquireTokenSilent({
      ...apiRequest,
      account: account,
    })
  } catch (e) {
    console.log("FAIL")
    response = await msalInstance.acquireTokenPopup({
      ...apiRequest,
      account: account,
    });
    // console.log("result", response);
  }
  // console.warn("TOKEN: ", response)
  return response.accessToken
}

export async function getAccessTokenOnedrive(id) {

  // const account = await msalInstanceOneDrive.getActiveAccount();
  const oneDriveAccount = msalInstanceOneDrive.getAllAccounts();
  const account = msalInstanceOneDrive.getAccountByLocalId(id)
  console.log("ACTIVE ACCOUNT: ", account, oneDriveAccount)
  if (!account) {
    throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    )
  }

  let response;
  try {
    response = await msalInstanceOneDrive.acquireTokenSilent({
      ...loginRequestJWT,
      account
    })
  } catch (e) {
    console.log("FAIL")
    response = await msalInstanceOneDrive.acquireTokenPopup({
      ...loginRequestJWT,
      account
    });
    // console.log("result", response);
  }
  // console.warn("TOKEN: ", response)
  return response;
}


export async function apiCall(url, reportId = null) {
  const token = await bearerToken()

  const headers = new Headers()
  const bearer = `Bearer ${token}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers: headers,
  }

  if (reportId) {
    return fetch(`${url}?reportId=${reportId}`, options)
        .then(response => response.json())
        .catch(error => console.log(error))
  } else {
    return fetch(`${url}`, options)
        .then(response => response.json())
        .catch(error => console.log(error))
  }
}
