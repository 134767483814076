import Lottie from 'react-lottie';
import animationData from './stars.json'
import {Avatar} from "@mui/material";
// import styles from './answer-icon.module.css'

export const AnswerIcon = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    hover: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
      <div style={{ width: '60px' }}>
        {props.chatTheme?.logo ? <Avatar src={props.chatTheme.logo} sx={{ width: 60, height: 60, filter: "drop-shadow(0 1px 1px #cecece)" }}/> :<Lottie options={defaultOptions} height={40} width={40} />}
      </div>

  )
}
