import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as moment from 'moment';
import {useState} from "react";
import {CircularProgress} from "@mui/material";

const PostCreate = (props) => {
    const {open, setOpen, date, topic, setTopic, newPost} = props;
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setOpen(false);
    };

    return (
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: async (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        console.log(formJson);
                        setLoading(true);
                        await newPost(formJson.topic)
                        setLoading(false);
                        handleClose();
                    },
                }}
            >
                <DialogTitle>NEW POST</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {moment(date).format('LLLL')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="topic"
                        name="topic"
                        label="Topic"
                        type="textarea"
                        value={topic}
                        fullWidth
                        variant="standard"
                        onChange={(e) => setTopic(e.target.value)}
                    />
                </DialogContent>
                {!loading ? <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Create</Button>
                </DialogActions> : <DialogActions>
                    <div className="d-flex justify-content-center">
                        <CircularProgress/>
                    </div>
                </DialogActions>}
            </Dialog>
    );
}
export default PostCreate;
