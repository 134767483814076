import DashboardActionTypes from "./dashboard.types";

//ACTION CREATORS

export const getDashboard = (payload) => ({
  type: DashboardActionTypes.GET_DASHBOARD,
  payload,
});

export const getBarDashboard = (payload) => ({
  type: DashboardActionTypes.GET_BAR_DASHBOARD,
  payload,
});
