import { useRef, useState } from "react";
import {
  Box,
  Button,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chart.js/auto"; // fix: Canvas is already in use. Chart with ID '0' must be destroyed before the canvas can be reused.
import { Bar } from "react-chartjs-2";
import CloseIcon from "@mui/icons-material/Close";
import {ChartGenerator, ChartGeneratorData} from './ChartGenerator';


const chartOptions = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};



export function ChartsPanel({
  jsonChartData,
  onClose,
  onClickChart,
  sx,
}) {

  const isWindowSmall = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  const handleClose = () => {
    onClose();
  };

  const handleClickChart = () => {
    onClickChart();
  };

  const chartRef = useRef(null);

  const downloadChartImage = () => {
    const link = document.createElement('a');
    link.download = 'chart.png';
    link.href = chartRef.current.toBase64Image();
    link.click();
  };

  return (
    <Box sx={{ width: "auto", overflow:"auto",...(isWindowSmall && { width: "100%" }) ,...sx }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontWeight: "600" }}>Charts panel</Typography>
        <Button onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </Box>
      <Box
        sx={{
          bgcolor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0 0 0 1px rgb(0 0 0/5%), 0 2px 3px 0 rgba(0,0,0,.1)",
          display: "flex",
          //flexDirection: "column",
          outline: "1px solid transparent",
          padding: "20px",
          fontSize: "1.1rem",
          width: "100%",
          //maxHeight: "70vh",
          overflow: "auto",
          cursor: "pointer",
          ...(isWindowSmall && { width: "95%" }),
        }}
      >
        <div onClick={() => handleClickChart()}>
          
          <ChartGenerator answer={jsonChartData} ref={chartRef}/> 
          
        </div>
        
      </Box>
      <Button onClick={() => downloadChartImage()}>Download</Button>
    </Box>
  );
}
