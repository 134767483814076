import { parseStringTable } from "./parseStringTable";
import { parseTextWithCitations } from "./parseTextWithCitations";

export function parseAnswer(message) {
  if (message !== undefined && message.length > 0) {
    let splittedMessage = [];
    let remainingMessage = message;

    // if there is a table at the start of the message
    if (message.charAt(0) === "|") {
      // if it is the last table and it is at the end of the message
      let tableEndIndex = 0;
      if (remainingMessage.indexOf("|\n\n") === -1) {
        tableEndIndex = remainingMessage.length;
      } else {
        tableEndIndex = remainingMessage.indexOf("|\n\n") + 1;
      }
      splittedMessage.push({
        data: parseStringTable(remainingMessage.slice(0, tableEndIndex)),
        type: "table",
      });
      remainingMessage = remainingMessage.slice(tableEndIndex + 2);
    }

    let i = 0; // this is only used to avoid a infinite loop error
    while (remainingMessage.indexOf("\n\n|") !== -1 && i <= 10) {
      const tableStartIndex = remainingMessage.indexOf("\n\n|") + 2;
      let tableEndIndex = 0;
      // if it is the last table and it is at the end of the message
      if (remainingMessage.indexOf("|\n\n") === -1) {
        tableEndIndex = remainingMessage.length;
      } else {
        tableEndIndex = remainingMessage.indexOf("|\n\n") + 1;
      }
      splittedMessage.push({
        data: parseTextWithCitations(
          remainingMessage.slice(0, tableStartIndex - 2)
        ),
        type: "text",
      });
      splittedMessage.push({
        data: parseStringTable(
          remainingMessage.slice(tableStartIndex, tableEndIndex)
        ),
        type: "table",
      });
      remainingMessage = remainingMessage.slice(tableEndIndex + 2);
      i++;
    }

    if (remainingMessage.length > 0 && remainingMessage !== "\n") {
      splittedMessage.push({
        data: parseTextWithCitations(remainingMessage),
        type: "text",
      });
    }

    return splittedMessage;
  } else {
    return [];
  }
}
