import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Container,
  Avatar,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress, capitalize, IconButton
} from '@mui/material';
import axios from 'axios';
import {getUserWithEmailThunk} from "../../../redux/users/users.thunk";
import {connect} from "react-redux";
import FinalCard from "./final-card";
import Lottie from 'react-lottie'
import animationData from './stars.json'
import { toastr } from "react-redux-toastr";
import {ArrowBack} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import UploadFileInput from "./upload-file-input";
import {toFormData} from "../../../helpers/toFormData";

function Chat(props) {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isTopicSet, setIsTopicSet] = useState(false);
  const [feedbackType, setFeedbackType] = useState('content');
  const [isLoading, setIsLoading] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [showFeedbackButtons, setShowFeedbackButtons] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [platforms, setPlatforms] = useState([]);
  const [platform, setPlatform] = useState(null);
  const [analysisType, setAnalysisType] = useState('simple');
  const [isYesButtonDisabled, setIsYesButtonDisabled] = useState(false);
  const chatContainerRef = useRef(null);
  const [showReloadButton, setShowReloadButton] = useState(false);
  const [tokenFacebook, setTokenFacebook] = useState(null);
  // LinkedIn Bussiness Acount
  const [organizations, setOrganizations] = useState([]);
  const [selectedUserSub, setSelectedUserSub] = useState(null);
  const [defaultUserSub, setDefaultUserSub] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  /** for schedule*/
  const [showFinalCard, setShowFinalCard] = useState(false);
  const [postSaved, setPostSaved] = useState(null);
  const [credentials, setCredentials] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const platfs = ["linkedin", "facebook", "wordpress", "tiktok", "instagram", "twitter", "sharepoint"]
    let currentPlat = null;
    if (!!props.user?.analytics_linkedin_integrations?.length) {
      currentPlat = "linkedin"
    }
    if (!!props.user?.analytics_facebook_integrations?.length) {
      currentPlat = "facebook"
    }
    if (!!props.user?.analytics_wordpress_integrations?.length) {
      currentPlat = "wordpress"
    }
    if (!!props.user?.analytics_tiktok_integrations?.length) {
      currentPlat = "tiktok"
    }
    if (!!props.user?.analytics_instagram_integrations?.length) {
      currentPlat = "instagram"
    }
    if (!!props.user?.analytics_twitter_integrations?.length) {
      currentPlat = "twitter"
    }
    if (!!props.user?.analytics_sharepoint_integrations?.length) {
      currentPlat = "sharepoint"
    }
    setPlatforms(platfs);
    setPlatform(currentPlat)
  }, [])

  const checkFacebookToken = () => {
    const tokenData = JSON.parse(localStorage.getItem('facebookAuthToken'));

    if (!tokenData) {
      alert('No Facebook token found. Please log in to Facebook first.');
      return false;
    }

    const currentTime = new Date().getTime();

    if (currentTime >= tokenData.expirationTime) {
      alert('Facebook token has expired. Please log in again.');
      return false;
    }

    if (tokenData.used) {
      alert('Facebook token has already been used.');
      return false;
    }

    console.log('Facebook Token Data:', tokenData);
    return true;
  };



  const [facebookPages, setFacebookPages] = useState([]); // Almacena las páginas de Facebook
  const [selectedPageId, setSelectedPageId] = useState(''); // Page ID seleccionado


  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);
  useEffect(() => {
    if (platform === 'linkedin') {
      fetchLinkedinOrganizations();  
    }
  }, [platform]);

  const fetchLinkedinOrganizations = () => {
    const linkedinCredentials = props.user.analytics_linkedin_integrations &&
      props.user.analytics_linkedin_integrations.length > 0
      ? props.user.analytics_linkedin_integrations[0]
      : { userSub: null, accessToken: null, organizationList: [] };

    if (!linkedinCredentials || !linkedinCredentials.userSub || !linkedinCredentials.accessToken) {
      console.log("No LinkedIn credentials found");
      return;
    }
    console.log("LinkCred: ", linkedinCredentials)
    // console.log("Organizations: ", organizationsList)
    if (!defaultUserSub) {
      setDefaultUserSub("person:" + linkedinCredentials.userSub);
      setSelectedUserSub("person:" + linkedinCredentials.userSub);
      setAccessToken(linkedinCredentials.accessToken);
    }

    let data = linkedinCredentials.organizationList;
    const formattedData = data.replace(/'/g, '"');
    data = JSON.parse(formattedData);
    if (data && data.length > 0) {
      const filteredData = data.map(item => ({
        id: "organization:" + item.id,
        name: item.name,
        urn: item.urn
      }));

      setOrganizations(filteredData);
      setSelectedUserSub(filteredData[0]?.id || defaultUserSub);  
    } else {
      setOrganizations([]);
      console.log('No organizations available');
    }
  };

  useEffect(() => {
  if (platform === 'linkedin') {
    fetchLinkedinOrganizations();
  }
}, [platform]);

function splitJsonObjects(concatenatedStr) {
  const jsonObjects = [];
  let currentObject = '';
  let braceStack = 0;

  for (let i = 0; i < concatenatedStr.length; i++) {
    const char = concatenatedStr[i];
    currentObject += char;

    // Track the balance of braces
    if (char === '{') {
        braceStack++;
    } else if (char === '}') {
        braceStack--;
    }

    // If the stack is balanced (braceStack == 0), we have a complete JSON object
    if (braceStack === 0 && currentObject.trim().length > 0) {
      jsonObjects.push(currentObject);
      currentObject = '';  // Reset the current object string
    }
  }

  return jsonObjects;
}

  // Handle POST stream requests
  async function handleStreamRequests(url, body, useFormData = false){
    let obj = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
    if (useFormData) {
      obj = {
        method: 'POST',
        body
      }
    }
    const response = await fetch(url, obj);
    // Ensure the response is OK
    if (!response.ok || response == null || response.body == null) {
        throw new Error('Network response was not ok');
    }

    // Get the readable stream from the response
    const reader = response.body.getReader();
    const decoder = new TextDecoder(); // For decoding the bytes to text
    let finalResponse=null;
    let buffer = '';
    while (true) {
        // Read each chunk of data
        const { value, done: streamDone } = await reader.read();
        let done = streamDone;
        if(done){ // is finished
            break;
        } 

        // Decode the chunk into text and append it to the result
        if (value) {
          let chunk = decoder.decode(value);       
          // console.log('received value', chunk);         
          let cchunks = splitJsonObjects(chunk);
          for(let idx in cchunks){
            let j = JSON.parse(cchunks[idx]);
            if(j.hasOwnProperty('chat_content')){ // content to append to the assistant message
              if(buffer == '')
                setMessages((prevMessages) => [
                  ...prevMessages,
                  { type: 'system', content: j.chat_content },
                ]);
              else{
                setMessages((prevMessages) => {
                  let cpyLast = {...prevMessages[prevMessages.length-1]};
                  cpyLast.content = buffer;
                  let n_messages = [...prevMessages];
                  n_messages[n_messages.length-1] = cpyLast;
                  return n_messages;
                });
              }
              buffer += j.chat_content;   
            }
            else if(j.hasOwnProperty('error')){ // error to show
              throw new Error('AN ERROR OCURRED PLEASE RELOAD THE PAGE: '+j['error']);
            }
            else{ // internal data to return
              finalResponse = JSON.parse(cchunks[idx]);
            }
          }
        }
    }
    return finalResponse;
  }

  const sendTopic = async () => {
    if (!inputValue.trim() || isLoading) return;
    setIsLoading(true);
    // console.log("User Sub: ",selectedUserSub)
    // console.log("Organizations: ",organizations)
    // console.log("Token: ",accessToken)
    // console.log("Defalutl: ", defaultUserSub)
    try {
      setMessages((prevMessages) => [
        ...prevMessages,
        // { type: 'system', content: `Process started with topic: ${inputValue} on ${platform}` },
        { type: 'system', content: `Process started with topic: ${inputValue}` },
      ]);
      const wordpressCredentials = props.user.analytics_wordpress_integrations &&
      props.user.analytics_wordpress_integrations.length > 0 ? props.user.analytics_wordpress_integrations[0] : {
        blogUrl: null,
        username: null,
        password: null,
      };
      let body = {
        ...{wordpressCredentials},
        topic: inputValue,
        platform: platform,
        analysis: analysisType
      };

      let parsedData = await handleStreamRequests(`${process.env.REACT_APP_BACK_AI_POST}/start_process_sse`, body);
      if(parsedData == null)
        throw new Error('No answer founded')
      // console.log("FINAL CONTENT", parsedData);
      setMessages((prevMessages) => {
        let cpyLast = {...prevMessages[prevMessages.length-1]};
        cpyLast.title = parsedData.title;
        cpyLast.content = parsedData.content;
        let n_messages = [...prevMessages];
        n_messages[n_messages.length-1] = cpyLast;
        return n_messages;
      });

      setThreadId(parsedData.thread_id);
      setIsTopicSet(true);
      setShowFeedbackButtons(true);
      setShowUploadImage(true);
      setInputValue('');
    } catch (error) {
      console.error("Error starting process:", error);
      toastr.error(`Something went wrong, please reload the page to try again`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (platform === 'facebook') {
      const tokenData = JSON.parse(localStorage.getItem('facebookAuthToken'));
      if (!tokenData) {
        alert('Please go to the integrations section and login with your Facebook account.');
        setPlatform('linkedin');
        return;
      }

      const pagesData = JSON.parse(localStorage.getItem('facebookPages')) || [];
      setFacebookPages(pagesData);
      setTokenFacebook(tokenData.accessToken);
    }
    if (platform === 'linkedin') {
      if (!props.user || !props.user.analytics_linkedin_integrations || props.user.analytics_linkedin_integrations.length === 0) {
        console.log("No LinkedIn credentials found");
        alert('Please go to the integrations section and login with your Linkedin account.');
        setPlatform('wordpress');
        return;
      }
    }
  }, [platform]);

  const sendMessage = async () => {
    if (!inputValue.trim() || isLoading) return;
    setIsLoading(true);

    setMessages((prevMessages) => [
      ...prevMessages,
      { type: 'user', content: inputValue }
    ]);

    try {
      
      let body = {
        thread_id: threadId,
        feedback_type: feedbackType,
        feedback_value: inputValue.toLowerCase(),
      }

      let data = await handleStreamRequests(`${process.env.REACT_APP_BACK_AI_POST}/submit_feedback`, body);
      // console.log('miauu', data);
      if(data == null)
        throw new Error('No answer founded')
      // console.log("Feedback Data: ", data);
      // console.log("-------------------");

      if(data.type === 'request_new_topic'){
        setMessages((prevMessages) => [
          ...prevMessages,
          { type: 'system', content: data.content }
        ]);
        setShowFeedbackButtons(false);
        setShowUploadImage(false);
        setIsTopicSet(true);
        setShowReloadButton(true);
      }
      else{
        const newMessage = {
          type: data.type === 'content' ? 'content_generation' : 'image_generation',
          content: data.content,
          title: data.title,
          question: data.question,
        };
        setFeedbackType(data.type);
        if(data.type !== 'content')
          setMessages((prevMessages) => [...prevMessages, newMessage]);
        else{
          setMessages((prevMessages) => {
            let cpyLast = {...prevMessages[prevMessages.length-1]};
            cpyLast.title = data.title;
            cpyLast.content = data.content;
            let n_messages = [...prevMessages];
            n_messages[n_messages.length-1] = cpyLast;
            return n_messages;
          });
        }
        setShowFeedbackButtons(true);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toastr.error(`Something went wrong, please reload the page to try again`);
    } finally {
      setInputValue('');
      setIsLoading(false);
    }
  };

  const handleNoFeedback = () => {
    setShowFeedbackButtons(false);
  };
  const [pageId, setPageId] = useState('');

  const handleYesFeedback = async (uploadImageData = null) => {
    setIsYesButtonDisabled(true);
    
    try {
      const wordpressCredentials = props.user.analytics_wordpress_integrations &&
          props.user.analytics_wordpress_integrations.length > 0 ? props.user.analytics_wordpress_integrations[0] : {
        blogUrl: null,
        username: null,
        password: null,
      };
      // const linkedinCredentials = props.user.analytics_linkedin_integrations &&
      //   props.user.analytics_linkedin_integrations.length > 0 ? props.user.analytics_linkedin_integrations[0] : {
      //   userSub: null,
      //   accessToken: null,
      // };
      console.log("---------------- facebook credentials is: ", tokenFacebook, selectedPageId)
      let dataToSend = {
        ...wordpressCredentials,
        page_id: selectedPageId,
        access_token_facebook: tokenFacebook,
        userSub: selectedUserSub,
        accessToken: accessToken,
        thread_id: threadId,
        feedback_type: feedbackType,
        feedback_value: 'y',
        master_user_id: props.user.id
      }
      console.log("IMAGEE", uploadImageData)
      if (uploadImageData) {
        dataToSend = toFormData({
          ...dataToSend,
          feedback_type: "upload-image",
          feedback_value: 'upload-image',
          file: uploadImageData
        });
      }
      console.log("---------------- dataToSend: ", dataToSend)
  
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: 'user', content: 'Approved' }
      ]);

      let data = await handleStreamRequests(`${process.env.REACT_APP_BACK_AI_POST}/submit_feedback`, dataToSend, !!uploadImageData);
      // console.log('miauu', data);
      if(data == null)
        throw new Error('No answer founded')
      
      // let data = response.data;
      
      if(data.type === 'request_new_topic') {
        /*setMessages((prevMessages) => [
          ...prevMessages,
          { type: 'system', content: data.content }
        ]);*/
        setShowFeedbackButtons(false);
        setShowUploadImage(false);
        setIsTopicSet(true);
        setShowReloadButton(true);

        console.log("POST SAVED", data)
        setPostSaved(data.post);
        setCredentials(dataToSend);
        setShowFinalCard(true);
      } else {
        const newMessage = {
          type: data.type === 'content' ? 'content_generation' : 'image_generation',
          content: data.content,
          question: data.question,
        };
        console.log("YES BTN", data.type)
        if (data.type !== 'content') {
          /// hide the upload btn, if user generated AI image
          setShowUploadImage(false)
        }
        setFeedbackType(data.type)
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setShowFeedbackButtons(true);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toastr.error(`Something went wrong, please reload the page to try again`);
    } finally {
      setIsYesButtonDisabled(false);
    }
  };

  const handleReloadPage = () => {
    window.location.reload();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    hover: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const handlePlatformChange = (e) => {
    const {value} = e.target;
    console.log(value);
    if (isValidPlatform(value)) {
      setPlatform(value);
    } else {
      toastr.warning("Warning", `To select ${value.toUpperCase()} you must first integrate it`)
    }
  };

  const isValidPlatform = (platform) => {
    switch (platform) {
      case 'linkedin': return !!props.user?.analytics_linkedin_integrations?.length;
      case 'facebook': return !!props.user?.analytics_facebook_integrations?.length;
      case 'wordpress': return !!props.user?.analytics_wordpress_integrations?.length;
      case 'tiktok': return !!props.user?.analytics_tiktok_integrations?.length;
      case 'twitter': return !!props.user?.analytics_twitter_integrations?.length;
      case 'instagram': return !!props.user?.analytics_instagram_integrations?.length;
      case 'sharepoint': return !!props.user?.analytics_sharepoint_integrations?.length;
      default: return false;
    }
  }

  return (
    <Container maxWidth="md" sx={{ height: showFinalCard ? 'auto' : '89vh', display: 'flex', flexDirection: 'column', bgcolor: '#DFDFE8', p: 2, borderRadius:'10px' }}>
      <div className="row">
        <div className="col-md-12">
          <IconButton color="primary" size="large" onClick={() => navigate("/stored-post")}>
            <ArrowBack />
          </IconButton>
        </div>
      </div>
      {showFinalCard ?
      <FinalCard post={postSaved} credentials={credentials}/> :
      <Paper sx={{ flexGrow: 1, p: 2, overflowY: 'auto', display: 'flex', flexDirection: 'column', bgcolor: '#F5F5F5', borderRadius:'10px' }} ref={chatContainerRef}>
        {/** CHAT*/}
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: message.type === 'user' ? 'flex-end' : 'flex-start',
              mt: 1,
              mb: 1,
            }}
          >
            <Avatar
              sx={{
                bgcolor: message.type === 'user' ? '#0d47a1' : '#F5F5F5',
                color: '#fff',
                width: 40,
                height: 40,
                mr: 1,
              }}
            >
              {message.type === 'user' ? <Avatar src={props.chatTheme} sx={{ width: 40, height: 40 }}/> : <Lottie options={defaultOptions} height={40} width={40}/> }
            </Avatar>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                bgcolor: message.type === 'user' ? '#1976d2' : '#0B376B',
                color: '#fff',
                maxWidth: '70%',
                borderRadius: '15px'
              }}
            >
              {message.type === 'image_generation' ? (
                <img src={message.content} alt="Generated Visual" style={{ maxWidth: '100%' }} />
              ) : (
                  <div>
                    {message.title && message.title !== '' && <Typography sx={{fontSize:'30px', fontWeight:'700'}} >{message.title}</Typography>}
                    <Typography variant="body1" style={{
                      whiteSpace: 'pre-line'
                    }} dangerouslySetInnerHTML={{__html: message.content}}/>
                  </div>
              )}
              {message.question && (
                <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1}}>
                  {message.question}
                </Typography>
              )}
            </Paper>
          </Box>
        ))}
      </Paper>}

      <Box sx={{ p: 2, bgcolor: '#DFDFE8', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {/** PLATFORM SELECT*/}
        {!isTopicSet && platform && (
          <FormControl variant="outlined" sx={{ mr: 2, minWidth: 120, width:'140px' }}>
            <InputLabel sx={{ color: '#bbb' }}>Platform</InputLabel>
            <Select
              value={platform}
              onChange={(e) => handlePlatformChange(e)}
              label="Platform"
              sx={{ bgcolor: '#F5F5F5', color: '#000' }}
            >
              {platforms.map( (plat, i) => <MenuItem key={i} value={plat}>{capitalize(plat)}</MenuItem>)}
            </Select>
          </FormControl>)}
        {/** MODE SELECT (SIMPLE, COMPLEX)*/}
        {!isTopicSet && (
          <FormControl variant="outlined" sx={{ mr: 2, minWidth: 120 }}>
            <InputLabel sx={{ color: '#bbb' }}>Analysis</InputLabel>
            <Select
              value={analysisType}
              onChange={(e) => setAnalysisType(e.target.value)}
              label="Analysis"
              sx={{ bgcolor: '#F5F5F5', color: '#000' }}
            >
              <MenuItem value="simple">Simple</MenuItem>
              <MenuItem value="complex">Complex</MenuItem>
            </Select>
          </FormControl>
        )}
        {/** LINKEDIN SELECT*/}
        {platform === 'linkedin' && organizations.length > 0 && !isTopicSet &&(
          <FormControl variant="outlined" sx={{ bgcolor: '#DFDFE8',mr: 2, minWidth: 100 }}>
            <InputLabel sx={{ color: '#bbb' }}>Select Account</InputLabel>
            <Select
              value={selectedUserSub}
              onChange={(e) => setSelectedUserSub(e.target.value)}
              label="Select Account"
              sx={{ bgcolor: '#F5F5F5', color: '#000' }}
            >
              {/* First option is the default personal account */}
              <MenuItem value={defaultUserSub}>Personal</MenuItem>
              {/* Show filtered organizations */}
              {organizations.map((org) => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {/** FACEBOOK SELECT*/}
        {platform === 'facebook' && (
          <FormControl variant="outlined" sx={{ mr: 2, minWidth: 200 }}>
            <InputLabel sx={{ color: '#bbb' }}>Select Facebook Page</InputLabel>
            <Select
              value={selectedPageId}
              onChange={(e) => {
                console.log("Selected Page ID:", e.target.value);
                setSelectedPageId(e.target.value);
              }}
              label="Select Facebook Page"
              sx={{ bgcolor: '#555', color: '#fff' }}
            >
              {facebookPages && facebookPages.length > 0 ? (
                facebookPages.map((page) => (
                  <MenuItem key={page.id} value={page.id}>
                    {page.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  No pages available
                </MenuItem>
              )}
            </Select>
          </FormControl>
        )}

        {isLoading ? (
          <Lottie options={defaultOptions} height={60} width={60}/>
        ) : showFeedbackButtons ? (
          <>
            <Button variant="contained" color="success" onClick={() => handleYesFeedback()} sx={{ mr: 2 }} disabled={isYesButtonDisabled}>
            {isYesButtonDisabled ? <Lottie options={defaultOptions} height={40} width={40}/> : 'YES'}
            </Button>
            <Button variant="contained" color="error" onClick={handleNoFeedback} disabled={isYesButtonDisabled}>
              NO
            </Button>

            {showUploadImage && <UploadFileInput handleYesFeedback={handleYesFeedback} disabled={isYesButtonDisabled}/>}
          </>
        ) : showReloadButton ? (
            <div>
              <Button variant="contained" color="primary" onClick={handleReloadPage}>
                Reload
              </Button>
            </div>
        ) : (
          <TextField
            variant="outlined"
            placeholder={isTopicSet ? "Type your feedback here..." : "Enter the topic to start the process..."}
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && (isTopicSet ? sendMessage() : sendTopic())}
            sx={{ mr: 2, bgcolor: '#555', input: { color: '#404040', bgcolor: '#F5F5F5' }, label: { color: '#bbb' } }}
            disabled={isLoading || showFeedbackButtons}
          />
        )}

        {!showFeedbackButtons && !showReloadButton &&(
          <Button
            variant="contained"
            color="primary"
            onClick={isTopicSet ? sendMessage : sendTopic}
            disabled={isLoading}
            sx={{height:'50px', borderRadius:'20px', width:'140px', fontWeight:'600'}}
          >
            {isTopicSet ? "Send" : "Start"}
          </Button>
        )}
      </Box>
    </Container>
  );
}
const mapStateToProps = (state) => {
  console.log('state app:', state);
  return {
    user: state.users.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
