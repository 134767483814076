import * as React from 'react';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Chip, CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@mui/material";
import {ArrowDropDown, ArrowDropUp, PaidOutlined, Sms} from "@mui/icons-material";
import nave from '../../assets/nave.png';
import './dashboard.css';
import Bar from "./bar";
import {connect} from "react-redux";
import {getBarDashboardThunk, getDashboardThunk} from "../../redux/dashboard/dashboard.thunk";
import {Component, useEffect, useState} from "react";
import {MsalContext} from "@azure/msal-react";
import CardCustomers from "./CardCustomers";
import TitleCard from "./TitleCard";
import CardChannels from "./CardChannels";
import {useTranslation} from "react-i18next";
import RotatingSpinner from "../layouts/RotatingSpinner";

const Welcome = (props) => {
    const {t} = useTranslation();
    return (
        <Card sx={{display: 'flex', flexWrap: 'wrap', overflow: 'visible'}}>
            {/*<CardMedia
                component="img"
                className="col-md-3"
                sx={{ top: '-50px', zIndex: (theme) => theme.zIndex.tooltip + 1}}
                image={nave}
                alt=""
            />*/}
            <div className="col-lg-4 position-relative">
                <img src={nave} className="img-ship" alt="ship"/>
            </div>
            <CardContent className="col-lg-8">
                <Typography component="div" variant="h5">
                    {t('dashboard.welcome')} <span className="text-primary">{props.name}!</span>
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="p">
                    {t('dashboard.welcomeContent')}
                </Typography>
                <button className="btn btn-primary">{t('dashboard.welcomeButton')}</button>
            </CardContent>
        </Card>
    );
}

const CardInfo = (props) => {
    const {title, value, difference, percent, color, loading} = props;
    return (
        <Card sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
              className="flex-md-wrap flex-lg-nowrap">
            { !loading ?<CardContent>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                    { title || ''}
                </Typography>
                <Typography component="div" variant="h5">
                    {value}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                    <small className="fs-sm">LastWeek
                        {difference > 0 ?
                            <span className="text-success"> <ArrowDropUp/> +{difference} | {percent}%</span> :
                            <span className="text-danger"> <ArrowDropDown/> {difference} | {percent}%</span>
                        }
                    </small>
                </Typography>
            </CardContent>
            : <CardContent>
                <div className="text-center">
                    <p>Loading...</p>
                    <RotatingSpinner/>
                </div>
            </CardContent>}
            <Box className="me-3 m-md-3">
                <button className={`btn btn-sm btn-${color}`} disabled={true}>
                    <Sms fontSize={'0.5rem'}/>
                </button>
            </Box>
        </Card>
    );
}

class Dashboard extends Component {

    static contextType = MsalContext;
    state = {
        loading: true,
        loadingBar: true,
        avgData: null,
        maxData: null,
        minData: null,
        usersData: null,
        channelItems: [],
        dataBar: null
    }

    async componentDidMount() {
        const tenantId = this.context.accounts[0].tenantId;
        console.log('TENANT IDDD', tenantId);
        await this.props.getDashboard(tenantId);
        if (this.props.countDashboard && this.props.countDashboard?.getCount) {
            console.log('RESSSS', this.props.countDashboard);
            const {avgCurrentVolume, avgLastVolume, maxCurrentVolume, maxLastVolume, minCurrentVolume, minLastVolume} = this.props.countDashboard?.getCount;
            this.setState({
                avgData: {
                    value: avgCurrentVolume,
                    difference: avgCurrentVolume - avgLastVolume,
                    percent:  Math.floor(Math.abs(avgCurrentVolume - avgLastVolume) * 100 / (avgLastVolume + 0.00001))
                },
                maxData: {
                    value: maxCurrentVolume,
                    difference: maxCurrentVolume - maxLastVolume,
                    percent:  Math.floor(Math.abs(maxCurrentVolume - maxLastVolume) * 100 / (maxLastVolume + 0.00001))
                },
                minData: {
                    value: minCurrentVolume,
                    difference: minCurrentVolume - minLastVolume,
                    percent:  Math.floor(Math.abs(minCurrentVolume - minLastVolume) * 100 / (minLastVolume + 0.00001))
                },
                // userItems: this.loadItems(this.props.countDashboard.getCountUser),
                ChannelItems: this.props.countDashboard.getCountChannel,
                loading: false,
                loadingBar: false
            })
        }

        /* await this.props.getBarDashboard(tenantId);

        if (this.props.countBarDashboard) {
            this.setState({
                loadingBar: false
            })
        }*/

    }

    render() {
        const {avgData, minData, maxData, channelItems, loading, loadingBar} = this.state;
        return (
            <div className="w-100">
                <div className="row">
                    <div className="col-12 mb-5">
                        <h5>DASHBOARD</h5>
                    </div>
                    <div className="col-md-6 my-2">
                        <Welcome name={this.context?.accounts[0].name}/>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-lg-6 my-2">
                                <CardInfo value={0}
                                          difference={0}
                                          percent={0}
                                          loading={false}
                                          title={'None'}
                                          color={'warning'} />
                            </div>
                            <div className="col-lg-6 my-2">
                                <CardInfo value={avgData?.value}
                                          difference={avgData?.difference}
                                          percent={avgData?.percent}
                                          loading={loading}
                                          title={'Overall Avg Volume'}
                                          color={'primary'} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 my-2">
                                <CardInfo value={maxData?.value}
                                          difference={maxData?.difference}
                                          percent={maxData?.percent}
                                          loading={loading}
                                          title={'Overall High'}
                                          color={'success'} />
                            </div>
                            <div className="col-lg-6 my-2">
                                <CardInfo value={minData?.value}
                                          difference={minData?.difference}
                                          percent={minData?.percent}
                                          loading={loading}
                                          title={'Overall Low'}
                                          color={'danger'} />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 my-2">
                        <Bar loading={loadingBar} open={this.props.open}/>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2">
                        <CardCustomers loading={loading}/>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2">
                        <CardChannels loading={loading}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        countDashboard: state.dashboard.countDashboard,
        // countBarDashboard: state.dashboard.countBarDashboard
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDashboard: (tenantId) => dispatch(getDashboardThunk(tenantId)),
        getBarDashboard: (tenantId) => dispatch(getBarDashboardThunk(tenantId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
