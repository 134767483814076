import { CircularProgress} from "@mui/material";
import { useEffect, useState } from "react";
import { getAllPlansMonthPostService } from "../../../../services/agent-post-ai.service";
import { connect } from "react-redux";
import PlanMonthItem from "./plan-month-item";
import './post-month-list-index.css'

const PostMonthListIndex = (props) => {
    const [plans, setPlans] = useState(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        // console.log("postTestData", postTestData)
        const func = (async () => {
            try {
                setLoading(true)
                const plns = await getAllPlansMonthPostService({
                    analytics_user_id: props.user.id
                })
                setLoading(false)
                console.log("POSTS MONTH: ", plns)

                setPlans(plns);
            } catch (e) {
                setLoading(false)
                console.log("ERROR get posts", e)
            }
        })

        func();

    }, [])
    return (<div className="container-fluid mt-2">
        <div className="w-100">
            {loading && <div className="d-flex justify-content-center">
                <CircularProgress />
            </div>}
            <div className="content-scroll">
                <div className="row scroll-vertical">
                    {plans && plans.map((item, index) => (
                        <div key={index} className="col-md-12 my-2">
                            <PlanMonthItem item={item} />
                        </div>
                    ))}
                </div>
            </div>
            {plans && plans.length === 0 && <h4 className="text-secondary">You don't have any plans yet</h4>}
        </div>
    </div>)
}
const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(PostMonthListIndex);
