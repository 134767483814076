import { useRoutes, Navigate } from 'react-router-dom';
import {noPermissionGuard, permissionGuard} from "./permissions-guard";
import {useSelector} from "react-redux";

const andGuardElementOld = (route) => {
    let pass = true;
    route.guards?.map((guard) => {
        if (!guard(route)) {
            pass = false;
        }
    })
    return pass;
}

const orGuardElementOld = (route) => {
    let pass = false;
    route.orGuards?.map((guard) => {
        if (guard(route)) {
            pass = true;
        }
    })
    return route.orGuards ? pass : true;
}

const andGuardElement = (route) => {
    return route.guards?.every(guard => guard(route)) ?? true;
};

const orGuardElement = (route) => {
    return route.orGuards?.some(guard => guard(route)) ?? true;
};

const noGuardElement = (route) => {
    if (route.noGuards)
        return route.noGuards?.some(guard => guard(route)) ?? false;
    else {
        return true;
    }
};

export const useRoutesGuard = (routes) => {
    const user = useSelector(state => state.users.user)
    return useRoutes(
        routes.map(route => ({
            ...route,
            element: noPermissionGuard(user, route) && permissionGuard(user, route) && andGuardElement(route) && orGuardElement(route) && noGuardElement(route) ? route.element : !noPermissionGuard(user, route) ? <Navigate to={route.redirect}/> : <Navigate to="/"/>
        }))
    );
};


export const itemsGuard = (user, items) => {
    // const user = useSelector(state => state.users.user)
    return items.map(item => ({
            ...item,
            show: noPermissionGuard(user, item) && permissionGuard(user, item) && andGuardElement(item) && orGuardElement(item) && noGuardElement(item)
        }));
};
