import React, {Component} from 'react';
import axios from "axios";
import {ThreeDots} from "react-loader-spinner";
import "./DateTimePicker.css";
import {connect} from "react-redux";
import {MsalContext} from '@azure/msal-react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormGroup,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from "@mui/material";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StaticTimePicker} from '@mui/x-date-pickers/StaticTimePicker';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {AutoDelete, History, Schedule} from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';

import {getUserThunk} from "../../../../redux/users/users.thunk";
import {updateSingleMonthPostService} from "../../../../services/agent-post-ai.service";

class EditTime extends Component {
    static contextType = MsalContext;

    state = {
        isOpen: false,
        openMenu: null,
        type: "SCHEDULE",
        //0 - 6, Sunday - Saturday
        myDays: [],
        time: "09:00",
        timezone: momentTz.tz.guess(),
        dateTime: new Date(new Date().setHours(9, 0, 0)),
        sending: false,
        success: false,
        userId: '',
        message: ''
    }

    //let teamId = this.context.accounts[0].idTokenClaims.extension_teamId;  TODO change to this

    componentDidMount() {

    }


    handleClickOpen = () => {
        // console.log('hola!');
        this.setState({isOpen: true});
        let myHours = moment(this.props.itemDateTime).format('HH');
        let myMinutes = moment(this.props.itemDateTime).format('mm')
        let myTime = myHours + ":" + myMinutes;

        this.setState({time: myTime, dateTime: new Date().setHours(myHours, myMinutes, 0)});
    };

    handleClose = () => {
        this.setState({isOpen: false});
        this.setState({success: false});
    };

    handleTimeChange = (e) => {
        const timeLocal = moment(e).format('HH:mm');
        const zone = momentTz.tz.guess();
        console.log('TIME LOCALL!', timeLocal, zone);
        this.setState({dateTime: new Date(e), time: timeLocal, timezone: zone});
    }

    handleSubmit = async () => {
        console.log("SUBMIT");
        // console.log('scheduleId:', this.props.scheduleId)

            //no way to edit a durable function running instance so we have to delete it and create a new one

            const myTime = moment(this.state.time, "HH:mm");
            console.log("TIME", this.state.time)

            // let teamId = this.props.selectedTeam;
            const newPostDate = moment(this.props.itemDateTime).hour(myTime.hour()).minute(myTime.minute()).format("YYYY-MM-DD HH:mm:ss.SSS Z")
            console.log("NEW TIMEEE", newPostDate)
            let obj = {
                postDate: newPostDate,
                time_zone: this.state.timezone,
            };
            console.log("SCHEDULE OBJ", obj);
            this.setState({sending: true});

            let res = await updateSingleMonthPostService(obj, this.props.post.postId);
            this.props.updateTime(newPostDate);
            // let res = {};
            this.setState({message: "Successfully scheduled", success: true, sending: false});

    }

    handleOpenMenu = (event) => {
        this.setState({openMenu: event.currentTarget})
    }

    handleCloseMenu = () => {
        this.setState({openMenu: null})
    }

    render() {

        return (
            <div className="d-inline">
                <Tooltip title="Edit Time">
                    <IconButton onClick={this.handleClickOpen}>
                        <Schedule/>
                    </IconButton>
                </Tooltip>
                <Dialog open={this.state.isOpen} onClose={this.handleClose}>
                    {this.state.success ?
                        <div className="successBlock">
                            <Typography>{this.state.message}</Typography>
                            <Button onClick={this.handleClose} color="primary">
                                Close
                            </Button>
                        </div> :
                        <div>
                            <DialogContent>
                                <FormGroup>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <StaticTimePicker displayStaticWrapperAs="mobile"
                                                          value={this.state.dateTime}
                                                          onChange={this.handleTimeChange}
                                                          renderInput={(params) => <TextField {...params} />}/>
                                    </LocalizationProvider>
                                </FormGroup>

                            </DialogContent>

                            <DialogActions>
                                <ThreeDots color="#EC2C93"
                                           height="100"
                                           width="100"
                                           visible={this.state.sending}/>
                                <div className="text-end w-100">
                                    <Button onClick={this.handleClose} color="primary">
                                        CANCEL
                                    </Button>

                                    <Button variant="contained" onClick={this.handleSubmit} color="primary">
                                        UPDATE
                                    </Button>
                                </div>

                            </DialogActions>
                        </div>
                    }

                </Dialog>
            </div>
        );
    }
}

// Map state to props;
const mapStateToProps = (state) => {
    return {
        user: state.users.user,
        selectedTeam: state.users.selectedTeam
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {

        // addScheduleSlack: (obj) => dispatch(addScheduleSlackThunk(obj)),
        getUser: (email) => dispatch(getUserThunk(email))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditTime);
