import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";
import styles from './select-index.module.css';
import './select-index.css';
import {setContainer, setIndex, setIndexObj} from "../../../../../redux/agent-hr/agent-hr.actions";
import {connect} from "react-redux";

let indexs = [
    {value: 'hr-index', label: 'SAP PDFs', container: 'content-hr'},
    {value: 'hr-index-json-2', label: 'SAP JSONs', container: 'test-miguel'},
    {value: 'hr-index-csv', label: 'DB Employees CSVs', container: 'csv-cotent-hr'},
];
const SelectIndex = (props) => {
    const [indexs, setIndexs] = useState(props.user.indexList);
    const {setIndexName, indexName} = props;

    /*const exist = indexs.find((item) => {
        return item.value === 'hr-index-json-cbn'
    })

    if (!exist && (props.user.id === '80c156e3-914d-4ab3-b9b6-a456a2c7e4f5' || props.user.id === 'f97b8ebd-7822-4029-bacb-ac04874401de'  || props.user.id === 'eb542598-a259-4246-a1f9-1cd435c6b39a' || props.user.id === '7851c4f5-2dd4-403e-8ec8-9f3801d5b90e')) {
        indexs.push({value: 'hr-index-json-cbn', label: 'DB CBN JSON', container: 'json-content-hr-vacations'})
    }*/

    const handleChange = (event) => {
        console.log(props.user);
        setIndexName(event.target.value);
        const item = indexs.find((it) => {
            return it.value === event.target.value;
        })
        if (item) {
            console.log(item);
            props.setContainer(item.container);
            props.setIndex(item);
        }
    };
    return (
        <Box sx={{minWidth: 120, background: '#fff'}} className={styles.selectIndex}>
            <FormControl fullWidth id="select-index-id">
                <InputLabel id="demo-simple-select-label">Sources</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={indexName}
                    label="Sources"
                    onChange={handleChange}
                >
                    {indexs?.map((item, i) =><MenuItem value={item.value} key={i}>{item.label}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
    )
}

const mapStateToProps = (state) => {
    console.log('state app:', state)
    return {
        user: state.users.user,
        indexName: state.agentHrs.index
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIndexName: (index) => dispatch(setIndex(index)),
        setContainer: (container) => dispatch(setContainer(container)),
        setIndex: (obj) => dispatch(setIndexObj(obj))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectIndex);
