import styles from './user-chat-message.module.css'
import Avatar from '@mui/material/Avatar'
import { connect } from 'react-redux'

const UserChatMessage = (props) => {
  return (
    <div className={styles.container}>
      <Avatar
        alt={props.user?.firstName}
        src={`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/users/download/${props.user?.photo}`}
      />

      <div className={`${styles.message} ms-2`} style={{
          background: props.chatTheme.questionBg,
          color: props.chatTheme.questionText,
      }}>{props.message}</div>
    </div>
  )
}

const mapStateToProps = (state) => {
  console.log('state app:', state)
  return {
    user: state.users.user,
    status: state.users.status,
    chatTheme: state.agentHrs.chatTheme
  }
}

export default connect(mapStateToProps)(UserChatMessage)
