import {useState} from "react";
import {useAwsPostAIGuard} from "../../guards/aws-post-ai-guard";
import {useSlackDatachatGuard} from "../../guards/slack-datachat-guard";
import {useTeamsDatachatGuard} from "../../guards/teams-datachat-guard";
import {useTeamsPostAIGuard} from "../../guards/teams-postai-guard";
import {useStripeGuard} from "../../guards/stripe-guard";
import {useAwsGuard} from "../../guards/aws-guard";
import AgentHrChatIndex from "../agents/agent-hr/agent-hr-chat-index";
import Chat from "../agents/agent-post/Chat";
import IntegrationIndex from "../integrations/integration-index";
import BillingIndex from "../billing/billing-index";

const RootRoute = () => {
    const [hasPostAIAws, setHasPostAIAws] = useState(useAwsPostAIGuard())
    const [isSlackDatachatUser, setIsSlackDatachatUser] = useState(useSlackDatachatGuard())
    const [isTeamsDatachatUser, setIsTeamsDatachatUser] = useState(useTeamsDatachatGuard())
    const [isTeamsPostaiUser, setIsTeamsPostaiUser] = useState(useTeamsPostAIGuard())
    const [hasStripe, setHasStripe] = useState(useStripeGuard())
    const [hasCopilotAws, setHasCopilotAws] = useState(useAwsGuard())

    const renderContent = () => {
        console.log("stripe", hasStripe)
        console.log("copilotaws", hasCopilotAws)
        console.log("haspostaiaws", hasPostAIAws)
        console.log("isslack", isSlackDatachatUser)
        console.log("isteamsbot1 & 2", isTeamsDatachatUser, isTeamsPostaiUser)
        if (hasStripe || hasCopilotAws) {
            return <AgentHrChatIndex/>;
        } else {
            if (hasStripe || hasPostAIAws) {
                return <Chat/>;
            } else {
                if (isSlackDatachatUser || isTeamsDatachatUser || isTeamsPostaiUser) {
                    return <IntegrationIndex/>;
                } else {
                    return <BillingIndex/>;
                }
            }
        }
    }

    return (<>
        {renderContent()}
    </>)
}

export default RootRoute;
