import UserActionTypes from "./users.types";

//ACTION CREATORS

export const changeUserStatus = (payload) => ({
    type: UserActionTypes.CHANGE_STATUS_USER,
    payload,
});

export const changeCreateUserStatus = (payload) => ({
    type: UserActionTypes.CHANGE_CREATE_STATUS,
    payload,
});

export const getUser = (payload) => ({
    type: UserActionTypes.GET_USER,
    payload,
});

export const addUser = (payload) => ({
    type: UserActionTypes.ADD_USER,
    payload,
});

export const editUser = (payload) => ({
    type: UserActionTypes.EDIT_USER,
    payload,
});

export const updateProfilePhoto = (payload) => ({
    type: UserActionTypes.UPDATE_PROFILE_PHOTO,
    payload,
});

export const updateCountry = (payload) => ({
    type: UserActionTypes.UPDATE_COUNTRY,
    payload,
});

export const getBotAccessUse = (payload) => ({
    type: UserActionTypes.GET_BOT_ACCESS_USER,
    payload,
});
