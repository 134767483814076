import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Collapse,
    IconButton,
    styled, Tooltip,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useState} from "react";
import {addUserThunk, getUserWithEmailThunk} from "../../redux/users/users.thunk";
import {connect} from "react-redux";

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const BillingAwsIndex = (props) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (<>
        <Card>
            <CardHeader title="Your AWS Subscriptions" subheader={props.awsSubscription.contactEmail}/>

            <CardActions disableSpacing>
                <Button variant="contained" href="https://aws.amazon.com/marketplace/pp/prodview-4nle42fgoqen4?sr=0-3&ref_=beagle&applicationId=AWSMPContessa" target="_blank" color="warning" className="btn-primary">
                    See in MarketPlace
                </Button>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography paragraph>
                        <b>Product Code: </b>{JSON.parse(props.awsSubscription.entitlement).Entitlements[0].ProductCode}
                    </Typography>
                    <Typography paragraph>
                        <b>Expiration Date: </b>{JSON.parse(props.awsSubscription.entitlement).Entitlements[0].ExpirationDate}
                    </Typography>
                    <Typography paragraph>

                    </Typography>
                    <Typography>

                    </Typography>
                </CardContent>
            </Collapse>
        </Card>
    </>)
}
// Map state to props;
const mapStateToProps = (state) => {
    return {
        awsSubscription: state.users.user.all_aws_live_subscriptions,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingAwsIndex);
