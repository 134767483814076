export const Approaches = {
    RetrieveThenRead: "rtr",
    ReadRetrieveRead: "rrr",
    ReadDecomposeAsk: "rda"
}

export const AnalysisPanelTabs = {
    ThoughtProcessTab: "thoughtProcess",
    SupportingContentTab: "supportingContent",
    CitationTab: "citation"
}
