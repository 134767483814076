import * as React from 'react';
import {Axis, DataProvider, Grid, LineSeries, XYChart, Tooltip} from '@visx/xychart';
import {curveCatmullRom} from '@visx/curve';
import {Card, CardContent, CardHeader, CircularProgress, Typography} from '@mui/material';
import TitleCard from "./TitleCard";
import {getBarDashboardThunk, getDashboardThunk} from "../../redux/dashboard/dashboard.thunk";
import {connect} from "react-redux";
import {Forum} from "@mui/icons-material";
import {useEffect, useState} from "react";
import * as moment from 'moment';
import RotatingSpinner from "../layouts/RotatingSpinner";

const makeDatum = (y, x) => ({x, y});

// const useTheme = makeVisxTheme();

const accessors = {
    xAccessor: ({x}) => x,
    yAccessor: ({y}) => y
};

const {format: formatDate} = new Intl.DateTimeFormat(undefined, {
    month: 'short',
    day: 'numeric'
});
const formatDay = (i, items) => {
    const date = moment(items[i].date).format('YYYY-MM-DD HH:mm:ss');
    // console.log(date);
    return formatDate(new Date(date));
}

const getSeries = (items) => {
    return [
        {
            dataKey: 'Avg',
            data: items?.map((item, x) => {
                return {x, y: item.avgCurrentVolume}
            })
        },
        {
            dataKey: 'Min',
            data: items?.map((item, x) => {
                return {x, y: item.minCurrentVolume}
            })
        },
        {
            dataKey: 'Max',
            data: items?.map((item, x) => {
                return {x, y: item.maxCurrentVolume}
            })
        },
    ];
}

const Bar = (props) => {
   //  const theme = useTheme();
    const {loading, open} = props;
    const [load, setLoad] = useState(loading);

    /*const getLoading = () => {
        if(open) {
            setLoad(true);
            setInterval(() => {
                setLoad(loading);
            }, 300);
        }
        console.log('ASDASDSAD', load);
    }
    getLoading();*/

    useEffect(() => {
            setLoad(loading);
    }, [loading]);

    /*useEffect(() => {
        if(open) {
            setLoad(true);
            setTimeout(() => {
                setLoad(false);
            }, 1000)
        }
    }, [open]);*/
    return (
        <Card>
            <CardHeader title={<TitleCard>TRENDS AND CHANGES</TitleCard>}/>
            { !load ?
                <CardContent className="pe-1">
                    <DataProvider
                    xScale={{type: 'band'}}
                    yScale={{type: 'linear', nice: true}}>
                    <XYChart
                        height={280}
                        margin={{top: 0, bottom: 30, left: 20, right: 40}}>
                        <Grid columns={false} numTicks={5}/>
                        {getSeries(props.countDashboard?.getCountUserDate).map((props) => (
                            <LineSeries
                                key={props.dataKey}
                                {...props}
                                curve={curveCatmullRom}
                                {...accessors}
                            />
                        ))}
                        <Axis orientation="bottom" tickFormat={(data) => {
                            return formatDay(data, props.countDashboard?.getCountUserDate)
                        }}/>
                        <Axis
                            orientation="right"
                            hideAxisLine
                            numTicks={5}/>
                        <Tooltip
                            renderTooltip={({tooltipData}) => {
                                const nearestDatum = tooltipData?.nearestDatum;
                                if (!nearestDatum) return null;
                                return (
                                    <>
                                        <Typography variant="h6">
                                            {nearestDatum.datum.y} <Forum fontSize="small"/>
                                        </Typography>
                                        <Typography variant="body2">
                                            {formatDay(nearestDatum.datum.x, props.countDashboard.getCountUserDate)}
                                        </Typography>
                                    </>
                                );
                            }}
                        />
                    </XYChart>
                </DataProvider>
            </CardContent> : <CardContent>
                    <div className="text-center">
                        <p>Loading...</p>
                        <RotatingSpinner/>
                    </div>
                </CardContent>}
        </Card>
    );
}

const mapStateToProps = (state) => {
    return {
        countDashboard: state.dashboard.countDashboard,
        // countBarDashboard: state.dashboard.countBarDashboard
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bar);
