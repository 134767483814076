import * as Yup from "yup";
import * as React from "react";
import { useState } from "react";
import { Form, Formik } from "formik";
import TextFieldWrapper from "../../../layouts/forms/text-field-wrapper";
import { months, platforms } from "./data";
import {
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
    Typography,
    IconButton
} from "@mui/material";
import ButtonWrapper from "../../../layouts/forms/button-wrapper";
import { useNavigate } from "react-router-dom";
import { createMonthService } from "../../../../services/agent-post-ai.service";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import SelectFieldMonthsWrapper from "../../../layouts/forms/select-field-months-wrapper";
import SelectFieldPlatformsWrapper from "../../../layouts/forms/select-field-platform-wrapper";
import SelectFieldLinkedinOrganizationsWrapper
    from "../../../layouts/forms/select-field-linkedin-organizations-wrapper";
import SelectFieldFacebookOrganizationsWrapper
    from "../../../layouts/forms/select-field-facebook-organizations-wrapper";
import momentTz from "moment-timezone";
import { Stack } from '@fluentui/react';
import WeekDays from "./week-days";
import {ArrowBack} from "@mui/icons-material";

const PostMonthCreate = (props) => {
    const [ref, setRef] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [platformSelected, setPlatformSelected] = React.useState(null);
    const [selectedUserSub, setSelectedUserSub] = useState(null);
    const [days, setDays] = useState([]);

    const navigate = useNavigate();

    const INITIAL_FROM_STATE = {
        topics: '', site_url: '', platform: '', user_sub_linkedin: '', page_id: '', month: '', year: 2024, extra_information: '', day_frequency: 1
    };
    const FORM_VALIDATION = Yup.object().shape({
        month: Yup.mixed().required('The Month is required'),
        day_frequency: Yup.number().required('The day frequency is required')
            .integer('The day frequency must be an integer')
            .min(1, 'The day frequency must be at least 1')
            .max(5, 'The day frequency must be at most 5'),
        platform: Yup.mixed().required('The Platform is required'),
        topics: Yup.mixed().required('The Topic is required'),
        site_url: Yup.string().url('Web URL must be a valid url'), /*otherType: Yup.mixed().when([], {
            is: () => customeType,
            then: Yup.mixed().required('The Type topic is required'),
            otherwise: Yup.mixed().notRequired()
        }),*/

    });

    const onSubmitForm = async (values) => {
        /*analytics_user_id
        platform
        topics
        site_url (OPT)
        extra_information (OPT)*/
        try {
            console.log("FORM VALS", values, days)

            if (days.length > 0 && days.some(value => value === true)) {
                setLoading(true)
                const obj = {
                    ...values,
                    days,
                    analytics_user_id: props.user.id,
                    time_zone: momentTz.tz.guess(),

                };
                const newPlan = await createMonthService(obj)
                setLoading(false)
                // navigate(`/show-post-month/${newPlan.plan_id}`);
                navigate(`/post-month/${newPlan.plan_id}`);
            } else {
                toastr.error("Error", "Select at least one day of the week")
            }
        } catch (e) {
            console.error("error to create new Plan", e)
            toastr.error("Error", "Something went wrong while creating the post plan")
            setLoading(false)
        }
    }

    return (
        <div className="container-fluid mt-2">

            <div className="row">
                <div className="col-md-12">
                    <IconButton color="primary" size="large" onClick={() => navigate("/post-month")}>
                        <ArrowBack />
                    </IconButton>
                </div>
            </div>

            <div className="">
                <Formik
                    innerRef={(fa) => fa ? setRef(fa.values) : setRef({})}
                    initialValues={INITIAL_FROM_STATE}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={values => onSubmitForm(values)}>
                    <Form>
                        <div className="row mb-3 max-h-60">
                            <div className="col-md-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group my-2">
                                            <SelectFieldMonthsWrapper name="month"
                                                label="Month"
                                                simple={true}
                                                labelValue="name"
                                                idValue="value"
                                                required
                                                options={months} />
                                        </div>
                                        <div className="form-group my-2">
                                            <WeekDays days={days} setDays={setDays}/>
                                        </div>
                                        <div className="form-group my-2">
                                            <TextFieldWrapper type="number" name="day_frequency" label="Frequency per Day" required />
                                        </div>
                                        <div className="form-group my-2">
                                            <SelectFieldPlatformsWrapper name="platform"
                                                label="Platform"
                                                simple={true}
                                                updateState={setPlatformSelected}
                                                labelValue="name"
                                                idValue="value"
                                                required
                                                options={platforms} />
                                        </div>
                                        {platformSelected === 'linkedin' && (
                                            <div className="form-group my-2">
                                                <SelectFieldLinkedinOrganizationsWrapper name="user_sub_linkedin"
                                                    label="Select Linkedin Account" />
                                            </div>)}
                                        {platformSelected === 'facebook' && (
                                            <div className="form-group my-2">
                                                <SelectFieldFacebookOrganizationsWrapper name="page_id"
                                                    label="Select Facebook Page" />
                                            </div>
                                        )}

                                        <div className="form-group my-2">
                                            <TextFieldWrapper name="topics" label="Topics" type="text" required />
                                        </div>
                                        {/*<div className="form-group my-2">
                                            <TextFieldWrapper name="site_url" label="Web URL" type="text"/>
                                        </div>*/}
                                        <div className="form-group my-2">
                                            <TextFieldWrapper name="extra_information"
                                                label="Extra Information"
                                                placeholder="E.g.: Language, specific instructions, desired style, key points."
                                                multiline={true}
                                                rows={3} />
                                        </div>
                                        <div className="text-center mb-2">
                                            <div disabled={loading}>
                                                {loading ?
                                                    <Stack.Item grow>
                                                        <p>
                                                            <div className="h-24">
                                                                <CircularProgress />
                                                                <div className="cubespinner">
                                                                    <div className="face1">
                                                                        <Typography sx={{fontSize: '15px', color: 'blue', fontWeight:'600', textAlign:'center'}}>Hold on while we are generating content for you...</Typography>
                                                                    </div>
                                                                    <div className="face2">
                                                                        <Typography sx={{fontSize: '15px', color: 'blue', fontWeight:'600', textAlign:'center'}}>AI Agent is working on it...</Typography>
                                                                    </div>
                                                                    <div className="face3">
                                                                        <Typography sx={{fontSize: '15px', color: 'blue', fontWeight:'600', textAlign:'center'}}>Processing the data...</Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </p>
                                                    </Stack.Item> :
                                                    <ButtonWrapper>Generate</ButtonWrapper>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>
                </Formik>
            </div>
        </div>)
}
const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(PostMonthCreate);
