import PostaiActionTypes from "./post-ai.types";

const INITIAL_STATE = {
    status: 'initial',
    allPostais: [],
    singlePostai: null,
    singlePostaiCreated: null,
    singleQuestion: {}

};

const PostaisReducer = (state = INITIAL_STATE, action) => {
    console.log("PL", action);
    switch (action.type) {
        case PostaiActionTypes.CHANGE_STATUS:
            return { ...state, status: action.payload };
        case PostaiActionTypes.ADD_NEW_POSTAI:
            return {...state, singlePostaiCreated: action.payload};
        case PostaiActionTypes.GET_POST_WITH_SCHEDULE:
            return {...state, singlePostai: action.payload};
        case PostaiActionTypes.UPDATE_POST:
            // return {...state, singlePostai: {...state.singlePostai, ...action.payload}};
            return {...state, singlePostai: action.payload};
        case PostaiActionTypes.ADD_JSON_CARD_TO_POSTAI:
            return {...state};
        case PostaiActionTypes.GET_ALL_POSTAIS:
            return {...state, allPostais: action.payload};
        case PostaiActionTypes.ADD_SCHEDULE:
            return {...state};
        case PostaiActionTypes.GET_ALL_POSTAIS_WITH_SCHEDULE:
            return {...state, allPostais: action.payload};
        case PostaiActionTypes.DELETE_SCHEDULE:
            return {...state};
        default:
            return state;
    }
};

export default PostaisReducer;
