import Making from "../../assets/making.gif";
import {CircularProgress, Tooltip} from "@mui/material";
import {Bookmark, Cached, ContentCopy, Delete, Edit} from "@mui/icons-material";
import {useState} from "react";
import axios from "axios";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toastr} from "react-redux-toastr";
import {useTranslation} from "react-i18next";
import {generateContentService, saveCountTokensWordsService} from "../../services/content.service";
import {InfinitySpin} from "react-loader-spinner";
import {palette} from "../../assets/styles/theme";
import {countWords} from "../../helpers/text";
import {connect} from "react-redux";

const Content = (props) => {
    const {t} = useTranslation();
    const {dataAux, plat, onDelete} = props;
    const [editable, setEditable] = useState(false);
    const update = async () => {
        const aux = {...dataAux, platform: [plat.platform]};
        setLoading(true);
        console.log('UPDATING:', content);
        let data = await generateContentService(aux);

        let subcontent = '';
        if (data.content) {
            /*const pos = data.content.indexOf(plat.platform);
            console.log('position 1', pos);
            subcontent = data.content.substring(pos !== -1 ? pos : 0);
            const endlinePos = subcontent.indexOf('\n');
            console.log('position 2', endlinePos);
            setContent(subcontent.substring(endlinePos + 1));*/
            setContent(data.content[0].text);
            plat.content = data.content[0].text;
        }

        setLoading(false);
        console.log('UPDATED:', data.content);

        if (data.usage) {
            // count number of words generated
            let completionWords = 0;
            data.content.map((item) => {
                completionWords += countWords(item.text, props.outputLanguage);
            })

            // update number of words, number of tokens generated
            ///TODO replace this for the current workspace selected
            if (props.user?.analytics_workspaces?.length > 0) {
                await saveCountTokensWordsService({
                    tokenCount: data.usage.completion_tokens,
                    wordCount: completionWords,
                    analyticsWorkspaceId: props.user.analytics_workspaces[0].id
                })
            }
        }
    }
    const [content, setContent] = useState(plat.content);

    const [loading, setLoading] = useState(plat.loading);

    return (
        <div className="grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-start">
                        <div>
                            <h5 className="card-title text-primary">{plat.title || plat.platform}</h5>
                            {/*<p className="card-description">{plat.platform}</p>*/}
                        </div>
                        <div className="text-end btn-group" role="group"
                             aria-label="Basic example">
                            <Tooltip title={t('content.create.generateOther')}>
                            <button onClick={() => update()}
                                    className="btn p-2 border-0 btn-outline-secondary">
                                <Cached fontSize="small"/>
                            </button>
                            </Tooltip>
                            <CopyToClipboard text={plat.content}
                                             onCopy={() => toastr.success('', t('content.create.copySuccess'))}>
                                <Tooltip title={t('content.create.copy')}>
                                <button className="btn p-2 border-0 btn-outline-secondary">
                                    <ContentCopy fontSize="small"/>
                                </button>
                                </Tooltip>
                            </CopyToClipboard>
                            {/*<CopyToClipboard text={plat.content}
                                             onCopy={() => toastr.success('', 'Shareable content copied to clipboard')}>
                                <button className="btn p-2 border-0 btn-outline-secondary">
                                    <Bookmark fontSize="small"/></button>
                            </CopyToClipboard>*/}
                            <Tooltip title={t('content.create.edit')}>
                            <button className="btn p-2 border-0 btn-outline-secondary"
                                    onClick={() => setEditable(true)}>
                                <Edit fontSize="small"/>
                            </button>
                            </Tooltip>
                            <Tooltip title={t('content.create.delete') + ' ' + plat.platform}>
                            <button className="btn p-2 border-0 btn-outline-secondary" onClick={onDelete}>
                                <Delete fontSize="small"/>
                            </button>
                            </Tooltip>
                        </div>
                    </div>
                    {!loading ?
                        <div>
                            <p className={editable ? 'border-edit border-success p-2 line-break' : 'card-description line-break'}
                               contentEditable={editable}
                               autoFocus={editable}
                               suppressContentEditableWarning={true}>{content}</p>
                            {editable ?
                                <div className="text-end">
                                    <button className="btn btn-sm btn-success"
                                            onClick={() => setEditable(false)}>
                                        {t('content.create.update')}
                                    </button>
                                </div> : null}
                        </div>
                        : <div className="w-100 text-center">
                            {/*<img className="my-2" width="50%" src={Making} alt=""/>*/}

                            <div className="text-center">
                                <div className="d-flex justify-content-center text-center">
                                    <InfinitySpin
                                        color={palette.primary}
                                        width="200"
                                        visible={true}/>
                                </div>
                                <p className='card-description line-break'>{t('content.create.rendering')}...</p>
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        inputLanguage: state.contents.inputLanguage,
        outputLanguage: state.contents.outputLanguage,
        user: state.users.user,
        status: state.users.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // updateContentLanguage: (obj) => dispatch(updateLanguage(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
