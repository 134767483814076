import axios from "axios";
import {bearerToken} from "../apiCall";

export const getDashboardService = async (tenantId) => {
     const token = await bearerToken();

     // const headers = new Headers();
     const bearer = `Bearer ${token}`;
    // console.log('TOKENN', token);

    // headers.append('Authorization', bearer);
    const {data} = await axios.post(`${process.env.REACT_APP_DASHBOARD_URL}/api/getDashboard`,
        {tenantId}, {
            headers: {
                Authorization: bearer
            }
    });
    // const {data} = await axios.post(`${process.env.REACT_APP_DASHBOARD_URL}/api/getDashboard`,{tenantId});
    return data;
}

export const getBarDashboardService = async (tenantId) => {
    const token = await bearerToken();
    const bearer = `Bearer ${token}`;
    const {data} = await axios.post(`${process.env.REACT_APP_DASHBOARD_URL}/api/getChatDateDashboard`,
        {tenantId}, {
            headers: {
                Authorization: bearer
            }
        });
    // const {data} = await axios.post(`${process.env.REACT_APP_DASHBOARD_URL}/api/getChatDateDashboard`, {tenantId});
    return data;
}
