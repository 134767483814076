import { useState } from "react";
import { format } from "date-fns";
import {
    Box,
    Button, Drawer,
    IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton,
    Stack, styled, Tooltip,
    Typography,
    useMediaQuery, useTheme,
} from "@mui/material";
import {
    Add as AddIcon, ChevronLeft, ChevronRight,
    Close as CloseIcon,
    Delete as DeleteIcon,
    Menu as MenuIcon,
} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import EditIcon from '@mui/icons-material/Edit';
import {useParams} from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    minWidth: 240,
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    background: 'white',
    zIndex: 1,
    justifyContent: 'space-between',
    [theme.breakpoints.up('xs')]: {
        // position: 'initial',
        marginTop: 60
    },
    [theme.breakpoints.up('sm')]: {
        position: 'fixed',
        marginTop: 0
    },
}));

const openedMixin = (theme) => ({
    width: 240,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
});

export function ChatDrawerLeft({
  conversations = [],
  isOpenDefault,
  onOpen,
  onNewConversation,
  onConversationClick,
  onModifyConversationTitle,
  onDeleteConversation,
  sx,
    handleDrawer,
    open,
    isLoadingConversations
}) {
  const isWindowSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isWindowMedium = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const [isOpen, setIsOpen] = useState(isOpenDefault);
  const { conversationId } = useParams();
  const handleSetIsOpen = (value) => {
    setIsOpen(value);
    // onOpen(value);
  };

  const handleNewConversation = () => {
    onNewConversation();
  };

  const handleConversationClick = (id) => {
    onConversationClick(id);
  };

  const handleModifyConversationTitle = (e, id) => {
    // e.stopPropagation();
    onModifyConversationTitle(id);
  };

  const handleDeleteConversation = (e, id) => {
    // e.stopPropagation();
    onDeleteConversation(id);
  };


    const theme = useTheme();
    const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Drawer
            variant={lessThanSmall ? 'temporary' : 'persistent'}
            anchor="left"
            open={open}
            sx={{
                ...(open && {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                }),
                ...(!open && {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                }),
                position: {xs: 'fixed', sm: 'relative'},
                zIndex: '1000'
            }}
            PaperProps={{
                sx: {
                    position: 'relative',
                    justifyContent: 'start !important',
                    flexDirection: 'column !important'
                }
            }}
        >
            <DrawerHeader>
                <Tooltip title="New conversation">
                    <IconButton onClick={() => handleNewConversation()} color="primary" disabled={!conversationId}>
                        <AddIcon/>
                    </IconButton>
                </Tooltip>
                <IconButton onClick={() =>handleDrawer(false)} className="me-2">
                    {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                </IconButton>
            </DrawerHeader>
            <Divider className="mt-md-5 pt-md-3"/>
            {isLoadingConversations ? <div className="">
                    <Skeleton className="my-2 mx-auto" variant="rounded" width={'90%'} height={30} />
                    <Skeleton className="my-2 mx-auto" variant="rounded" width={'90%'} height={50} />
                    <Skeleton className="my-2 mx-auto" variant="rounded" width={'90%'} height={50} />
                    <Skeleton className="my-2 mx-auto" variant="rounded" width={'90%'} height={50} />
                    <Skeleton className="my-2 mx-auto" variant="rounded" width={'90%'} height={50} />
                    <Skeleton className="my-2 mx-auto" variant="rounded" width={'90%'} height={50} />

            </div> :
            <List>
                {conversations.map((conversation, index) => (
                    <ListItem key={index}
                              secondaryAction={
                                  <span>
                                      <IconButton aria-label="Edit" size="small" onClick={(e) =>
                                          handleModifyConversationTitle(e, conversation.id)
                                      }>
                                          <EditIcon fontSize="small"/>
                                      </IconButton>
                                      <IconButton aria-label="Delete" size="small" color="error" onClick={(e) => handleDeleteConversation(e, conversation.id)}>
                                          <DeleteIcon fontSize="small"/>
                                      </IconButton>
                                  </span>
                              }
                              disablePadding sx={{display: 'block'}}>
                        <ListItemButton
                            onClick={() => handleConversationClick(conversation.id)}
                            sx={{
                                minHeight: 48,
                                //px: 2.5,
                                pr: '96px !important'
                            }}>
                            <ListItemText primary={<b>{conversation.title}</b> || <em>None</em>}
                                          secondary={<small>{format(new Date(conversation.createdAt), "yyyy-dd-MM")}</small>}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>}

        </Drawer>
    )
}
