import { parseSupportingContentItem } from "./supporting-content-parser";

import styles from "./supporting-content.module.css";
import {objToTable} from "../../helpers/htmls";

export const SupportingContent = ({ supportingContent }) => {
    return (
        <ul className={styles.supportingContentNavList}>
            {supportingContent.map((x, i) => {
                const parsed = parseSupportingContentItem(x);

                parsed.content = objToTable(JSON.parse(parsed.content));

                if(i === 0)
                    return (
                        <li className={styles.supportingContentItem}>
                            <h4 className={styles.supportingContentItemHeader}>{parsed.title}</h4>
                            <p dangerouslySetInnerHTML={{ __html: parsed.content}}/>
                        </li>
                    );
                else return null
            })}
        </ul>
    );
};
