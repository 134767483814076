import {renderToStaticMarkup} from "react-dom/server";

export function objToTable(data) {
    console.log("DATA TABLE", data)
    /*data = [
        {Nombre: 'Juan', Edad: 30, Ciudad: 'Ciudad A'},
        {Nombre: 'María', Edad: 25, Ciudad: 'Ciudad B'},
        {Nombre: 'Pedro', Edad: 35, Ciudad: 'Ciudad A'},
    ];*/
    // Verificar si hay datos en el array
    if (data.length === 0) {
        return '';
    }

    // Obtener las propiedades de los objetos (encabezados de columna)
    const headers = Object.keys(data[0]);

    // Crear la estructura de la tabla
    let tablaHTML = renderToStaticMarkup(
        <div className="position-relative w-100">
            <div className="table-content table-responsive" style={{maxHeight: '70vh'}}>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        {headers.map((header) => {
                            console.log("HEAD", header)
                            /*const regex = /\[(.*?)\]/;
                            return (<th>{regex.exec(header)[0]}</th>)*/
                            return (<th>{header}</th>)
                        })}
                    </tr>
                    </thead>
                    {data.map((row) => {
                        return (
                            <tbody>
                            <tr>
                                {headers.map((cel) => <td dangerouslySetInnerHTML={{__html: row[cel]}}/>)}
                            </tr>
                            </tbody>
                        )
                    })
                    }
                </table>
            </div>
        </div>)

    return tablaHTML;
}
