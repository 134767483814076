import {Checkbox, FormControl, FormControlLabel, FormGroup} from "@mui/material";
import {useField, useFormikContext} from "formik";
import {useState} from "react";
import {useTranslation} from "react-i18next";

const CheckFieldWrapper = (props) => {
    const {name, options, labelValue, idValue, ...otherProps} = props;
    const [results, setResultsfromCheck] = useState({})
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);
    const {t} = useTranslation();

    const exist = (item) => {
        let sw = false;
        field.value.forEach((it) => {
            if (it === item) sw = true;
        })
        return sw;
    }

    const handleChange = (e) => {
        const {value, checked} = e.target;
        // console.log(value, checked);
        let temp = field.value;
        if (checked) {
            if (!exist(value)) {
                temp.push(value);
            }
        } else {
            if (exist(value)) {
                temp = temp.filter((val)=> val !== value);
            }
        }

        setFieldValue(name, temp);
        // console.log(temp, field.value);
    };


    const configRadio = {
        ...field,
        ...otherProps,
        select: true,
        variant: 'outlined',
        fullWidth: true,
        onChange: handleChange
    }

    if (meta && meta.touched && meta.error) {
        configRadio.error = true;
        configRadio.helperText = meta.error;
    }

    return (
        <FormControl className="">
            {/*<FormLabel id="custom-topics">Customized topics</FormLabel>*/}
            {/*<FormGroup component="fieldset" variant="standard" row className="w-300">*/}
            <div className="row">
                {options.map((item, pos) =>
                    <div className="col-md-6"  key={pos + item[idValue]}>
                    <FormControlLabel value={item[idValue]}
                                      checked={exist(item[idValue])}
                                      control={<Checkbox onChange={handleChange}/>}
                                      label={<span>{item.icon ? item.icon : null}{t(otherProps.prefixTranslate+item[labelValue])}</span>}/>
                    </div>
                )}
            </div>
        </FormControl>
    )
}

export default CheckFieldWrapper;
