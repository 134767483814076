import {Article, Code, Description, InsertDriveFile, PictureAsPdf, GridOn, PermMedia, PresentToAll} from "@mui/icons-material";

const IconFile = (props) => {
    let mimeType = ''
    if(props.item.mimeType != undefined) // google drive
        mimeType = props.item.mimeType
    else // onedrive
        mimeType = props.item.file.mimeType
    
    switch (mimeType) {
        case 'application/pdf':
            return <PictureAsPdf color="error"/>
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.google-apps.document':
            return <Description color="info"/>
        case 'text/text':
        case 'text/plain':
            return <Article color="info"/>
        case 'text/html':
            return <Code color="warning"/>
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.google-apps.spreadsheet':
        case 'text/csv':
        case 'application/vnd.ms-excel':
            return <GridOn color="success"/>
        case 'image/png':
        case 'image/jpeg':
            return <PermMedia color="info"/>
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return <PresentToAll color="warning"/>
        default: return <InsertDriveFile color="info"/>
    }

}

export default IconFile;
