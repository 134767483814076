import { useState } from "react";
import {
  Box,
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";

export function ModifyConversationTitle({ onSubmit, onClose, open }) {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(inputValue);
    setLoading(false);
    setInputValue("");
  };

  const handleClose = () => {
    console.log("CLOSE (COMPONENT)");
    onClose();
  };

  /*return (
    <Draggable>
    <Box
      sx={{
        position: "absolute",
        top: "40%",
        left: "40%",
        padding: 1.5,
        bgcolor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 0 0 1px rgb(0 0 0/5%), 0 2px 3px 0 rgba(0,0,0,.1)",
      }}
    >
      <Box sx={{ justifyContent: "space-between", display: "flex" }}>
        <Typography sx={{ fontWeight: 600 }}>Moodbit Copilot</Typography>
        <Button onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </Box>
      <Box sx={{display:'flex',padding: 2 }}>
        <Typography>New title:</Typography>
        <TextField
          id="outlined-basic"
          label="Title"
          variant="outlined"
          sx={{
            width: "100%",
            marginBottom: 1,
            fontSize: "16px",
          }}
          onChange={handleChange}
        />
      </Box>
      <Box>
        <Button
          onClick={() => handleSubmit()}
          sx={{ bgcolor: "blue", color: "white", minWidth: "60px" }}
        >
          Ok
        </Button>
        <Button
          onClick={() => handleClose()}
          sx={{ bgcolor: "blue", color: "white", minWidth: "60px" }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
    </Draggable>
  );*/
  return (
      <Dialog
          open={open}
          onClose={handleClose}
      >
        <DialogTitle>Moodbit Copilot</DialogTitle>
        <DialogContent>
          <TextField
              id="outlined-basic"
              label="New Title"
              variant="outlined"
              sx={{
                width: "100%",
                marginBottom: 1,
                fontSize: "16px",
              }}
              onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={() => handleSubmit()}>
            {loading ? <CircularProgress color="inherit"/> : 'Update'}
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
  )
}
