import axios from "axios";

export const createSessionService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/stripe/live/create-checkout-session`, obj);
    return data;
}

export const createSessionTrialService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/stripe/live/create-checkout-session-trial`, obj);
    return data;
}

export const createCustomePortalService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/stripe/live/create-customer-portal-session`, obj);
    return data;
}
