import { Pivot, PivotItem } from '@fluentui/react'
import DOMPurify from 'dompurify'
import styles from './analysis-panel.module.css'
import { AnalysisPanelTabs } from '../../../models'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import {SupportingContent} from "../supporting-content/supporting-content";

const pivotItemDisabledStyle = { disabled: true, style: { color: 'grey' } }

export const AnalysisPanel = ({
  answer,
  activeTab,
  activeCitation,
  citationHeight,
  className,
  onActiveTabChanged,
}) => {
  const isDisabledThoughtProcessTab = !answer.thoughts
  const isDisabledSupportingContentTab = !answer.data_points.length
  const isDisabledCitationTab = !activeCitation

  const sanitizedThoughts = DOMPurify.sanitize(answer.thoughts)
  /*const downloadPDF = (url = activeCitation) => {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                // CReate a temporal url
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;

                link.click();

                URL.revokeObjectURL(link.href);
            })
            .catch(error => {
                console.error('Error to download PDF:', error);
            });
    }*/

  return (
    <div className={styles.pdfZone}>
      <div className={styles.closeBtn}>
        <IconButton
          className="bg-white"
          color="error"
          onClick={() => onActiveTabChanged(undefined)}
        >
          <Close />
        </IconButton>
      </div>
      <Pivot
        className={className}
        selectedKey={activeTab}
        onLinkClick={(pivotItem) =>
          pivotItem && onActiveTabChanged(pivotItem.props.itemKey)
        }
      >
        {/*<PivotItem
                itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                headerText="Thought process"
                headerButtonProps={isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}
            >
                <div className={styles.thoughtProcess} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
            </PivotItem>*/}
            <PivotItem
                itemKey={AnalysisPanelTabs.SupportingContentTab}
                headerText="Sources"
                headerButtonProps={isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined}
            >
                {/*<SupportingContent supportingContent={answer.data_points} />*/}
                <SupportingContent supportingContent={answer.sqlResults?.map((item, index) => `${index + 1}: ${JSON.stringify(item)}` )} />
            </PivotItem>
          {/*<PivotItem
          itemKey={AnalysisPanelTabs.CitationTab}
          headerText="Citation"
          headerButtonProps={
            isDisabledCitationTab ? pivotItemDisabledStyle : undefined
          }
        >
          <iframe
            title="Citation"
            src={activeCitation}
            width="100%"
            height={citationHeight}
            position="absolute"
          />
        </PivotItem>*/}
      </Pivot>
    </div>
  )
}
