import { Text } from "@fluentui/react";
import { Delete24Regular } from "@fluentui/react-icons";

import styles from "./send-email-button.module.css";
import {ForwardToInbox, Send} from "@mui/icons-material";
import {CircularProgress, IconButton, Menu, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useEffect, useState} from "react";
import {
    downloadVisual,
    downloadVisual2,
    getCitationFilePathHR,
    sendEmailService
} from "../../../../../services/agent-hr.service";
import {toastr} from "react-redux-toastr";
import './send-email-button.css';
import {renderToStaticMarkup} from "react-dom/server";
import {PowerBIEmbed} from "powerbi-client-react";
import blobToBase64 from "blob-to-base64";
import {apiCall} from "../../../../../apiCall";
import {models} from "powerbi-client";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import {getY} from "../../helpers/pdf";

export const SendEmailButton = ({ className, content, citations, user, answer, index, chatTheme }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [email, setEmail] = useState(null);
    const [sending, setSending] = useState(false);
    const [config, setConfig] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const blobPDF = async () => {
        const doc = new jsPDF();
        doc.setFontSize(10);
        const div = document.createElement('div');
        div.innerHTML = content;

        let base64 = null;

        const table = div.querySelector('table');
        if (table) {
            const contentTable = div.querySelector('.table-content');
            let prevElements = [];
            let currentElement = contentTable.previousElementSibling;
            while (currentElement !== null) {
                prevElements.unshift(currentElement);
                currentElement = currentElement.previousElementSibling;
            }

            let nextElements = [];
            currentElement = contentTable.nextElementSibling;
            while (currentElement !== null) {
                nextElements.unshift(currentElement);
                currentElement = currentElement.nextElementSibling;
            }

            //join all in a pdf
            // await doc.html(prevElements[0],{margin: [10, 10, 10, 10], autoPaging: 'text', x: 10, y: 10, width: 150, windowWidth: 500});

            let currentY = getY(doc, prevElements.length > 0 ? prevElements[0].outerHTML : '', 15) + 10;

            doc.autoTable({html: table, startY: currentY + 10});

            currentY = getY(doc, nextElements.length > 0 ? nextElements[0].outerHTML : '', doc.autoTable.previous.finalY + 10) + 10;
            // await doc.html(nextElements[0],{margin: [10, 10, 10, 10], autoPaging: 'text', width: 150, windowWidth: 500,x: 10, y: doc.autoTable.previous.finalY + 10});

            base64 = doc.output("datauristring")
            // doc.save('document');
        } else {
            await doc.html(div, {
                async callback(doc) {
                    // console.log("BASE64", doc.output("datauri"))
                    base64 = doc.output("datauristring");
                    div.classList.remove('page-size');
                },
                margin: [10, 10, 10, 10],
                autoPaging: 'text',
                x: 15,
                y: 15,
                width: 150, //target width in the PDF document
                windowWidth: 500 //window width in CSS pixels
            });
            // console.log("BASE 2", base64)
        }
        return base64;
    }

    const sendEmail = async () => {
        console.log("SENDING 1")
        content = `<div style="white-space: pre-line">${content}</div>`
        setAnchorEl(null);
        setSending(true);
        // console.log(email, content, citations);
        /* const path = `http://localhost:7071/api/sendPdfByEmail?htmlTemplate=${content}`;
        let citationsFiles = renderToStaticMarkup(
            <a href={path} target="_blank">
                {`Download PDF`}
            </a>
        );*/
        let htmlContent;
        let citationsFiles = '';

        let pbi = null;
        // if (answer.isPbi) {

            /*const response = await downloadVisual({
                reportId: '75aea719-1c91-4212-a27b-23861c3263aa',
                groupId: 'd58bd78b-8c28-4a74-ba03-0c8def1b3747'
            });*/
            pbi = await blobPDF();
            pbi = pbi.split("base64,")[1];
            // console.log("PBI", pbi);

            //pbi = response.base64;

            // htmlContent = `The response has a report that we attach as a file. ${citationsFiles}`;
         // }
         /* else {
            htmlContent = `${content} ${citationsFiles}`;
        }*/

        htmlContent = `${content} ${citationsFiles}`;
        // htmlContent = `<h3>Dear passenger 1, welcome to <a href="http://localhost:3000/26065d4e-597a-4a65-98cc-7635593962f6">DOWNLOAD FILE</a>!</h3><br />May the delivery force be with you! TEST`;
        console.log('SEND HTML cONTENT', htmlContent);
        try {
            await sendEmailService({
                email,
                senderName: 'Moodbit AI Copilot',
                subject: 'Moodbit AI Copilot Sent You an Email',
                body: htmlContent,
                pbi
            })
            toastr.success(`The mail was successfully sent to ${email}`);
            setSending(false);
        } catch(e) {
            toastr.error(`Something went wrong, try again`);
            setSending(false);
        }

    }

    const sendEmail1 = async () => {

        const response = await downloadVisual({
            reportId: '75aea719-1c91-4212-a27b-23861c3263aa',
            groupId: 'd58bd78b-8c28-4a74-ba03-0c8def1b3747'
        });
        console.log("DOWNLOAD V", response);

        const base = await blobToBase64(response);
        console.log("BASE", base)

        /*const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'visual.pdf');
        document.body.appendChild(link);
        link.click();*/

        /*const contenidoArrayBuffer = new TextEncoder().encode(response);
        const blob = new Blob([contenidoArrayBuffer], { type: 'application/pdf' });

        console.log("BLOB", blob);

        const file = new File([blob], "visual.pdf", { type: blob.type });
        console.log("FILE", file);*/

        /*const url = URL.createObjectURL(blob);
        console.log("BLOB url", url);

        const iframe = document.createElement('iframe');
        iframe.src = url;
        iframe.width = '100%';
        iframe.height = '500px'; // Establece el alto según tus necesidades
        document.body.appendChild(iframe);*/

        /*const a = document.createElement('a');
        a.href = URL.createObjectURL(file);
        a.download = file.name;
        a.style.display = 'none';

        document.body.appendChild(a);
        a.click();*/

        // URL.revokeObjectURL(url);
    }

    const loaded = async () => {
        console.log("LOADED")

        const pages = await window.report.getPages();

        // Retrieve the active page.
        let page = pages.filter(function (page) {
            return page.isActive
        })[0];

        const visuals = await page.getVisuals();
        console.log(
            visuals.map(function (visual) {

                // let qnaContainer = $('#qnaContainer')[0];


                return {
                    name: visual.name,
                    type: visual.type,
                    title: visual.title,
                    layout: visual.layout
                };
            }));

    }

    return (
        <div className={`${styles.container} ${className ?? ""} d-inline`}>
            {/** All the visible content **/}
            {!sending ?
            <IconButton onClick={handleClick}>
                <ForwardToInbox style={{
                    color: chatTheme.iconColor
                }}/>
            </IconButton>: <CircularProgress size={30}/>}
            <Menu
                id="f-menu"
                MenuListProps={{
                    'aria-label': 'f-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem autoFocus={false}>
                    <TextField id="outlined-basic"
                               onChange={(e) => setEmail(e.target.value)}
                               type="email"
                               label="Email"
                               variant="outlined"
                               placeholder="example@domain.com"/>
                    <IconButton className="ms-2" onClick={() => sendEmail()}>
                        <Send color="success"/>
                    </IconButton>
                </MenuItem>
            </Menu>
        </div>
    );
};
