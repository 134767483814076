import AgentHrActionTypes from "./agent-hr.types";

const INITIAL_STATE = {
    index: null,
    container: null,
    indexObj: null,
    chatTheme: {
        answerBg: "#F9F9F9FF",
        answerText: "#000",
        questionBg: "#e8ebfa",
        questionText: "#000",
        followupBg: "#0000FE",
        followupText: "#fff",
        bgChat: "#e8ebfa",
        iconColor: "rgba(0, 0, 0, 0.54)",
        /**FOR CUSTOMER*/
        /*answerBg: "#ffdf1c",
        answerText: "#000",
        questionBg: "#251c5c",
        questionText: "#f5f5f5",
        followupBg: "#1c83ea",
        followupText: "#fff"*/
    }
};

const agentHrReducer = (state = INITIAL_STATE, action) => {
    console.log("PL", action);
    switch (action.type) {
        case AgentHrActionTypes.SET_INDEX:
            return {...state, index: action.payload};
        case AgentHrActionTypes.SET_CONTAINER:
            return {...state, container: action.payload};
        case AgentHrActionTypes.SET_INDEX_OBJ:
            return {...state, indexObj: action.payload};
        case AgentHrActionTypes.SET_THEME:
            return {...state, chatTheme: action.payload};
        default:
            return state;
    }
};


export default agentHrReducer;
