export function parseTextWithCitations(text) {
  let splittedText = [];
  let remainingText = text;

  // if there is a citation at the start of the text
  if (text.charAt(0) === "[" && remainingText.indexOf("[query_") !== -1) {
    const citationEndIndex = remainingText.indexOf(".sql]") + 5;
    splittedText.push({
      data: remainingText.slice(1, citationEndIndex - 1),
      type: "citation",
    });
    remainingText = remainingText.slice(citationEndIndex);
  }

  let i = 0; // this is only used to avoid a infinite loop error
  while (remainingText.indexOf("[query_") !== -1 && i <= 10) {
    const citationStartIndex = remainingText.indexOf("[query_");
    //const citationEndIndex = remainingText.indexOf(".sql]") + 5;
    let citationEndIndex = 0;
    if (remainingText.indexOf(".sql]") !== -1) {
      citationEndIndex = remainingText.indexOf(".sql]") + 5;
    } else {
      citationEndIndex = remainingText.length - 1;
    }

    splittedText.push({
      data: remainingText.slice(0, citationStartIndex),
      type: "text-fragment",
    });
    splittedText.push({
      data: remainingText.slice(citationStartIndex + 1, citationEndIndex - 1),
      type: "citation",
    });
    remainingText = remainingText.slice(citationEndIndex);
    i++;
  }

  if (remainingText.length > 0 && remainingText !== "") {
    splittedText.push({ data: remainingText, type: "text-fragment" });
  }

  return splittedText;
}
