import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import {Error} from "@mui/icons-material";

export function ConfirmDelete({ onConfirm, onClose, open }) {
  const handleConfirm = () => {
    onConfirm();
  };

  const handleClose = () => {
    onClose();
  };

  /*return (
    <Draggable>
      <Box
        sx={{
          position: "absolute",
          top: "40%",
          left: "40%",
          padding: 1.5,
          bgcolor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0 0 0 1px rgb(0 0 0/5%), 0 2px 3px 0 rgba(0,0,0,.1)",
        }}
      >
        <Box sx={{ justifyContent: "space-between", display: "flex" }}>
          <Typography sx={{ fontWeight: 600 }}>Moodbit Copilot</Typography>
          <Button onClick={() => handleClose()}>
            <CloseIcon />
          </Button>
        </Box>
        <Typography sx={{ padding: 2 }}>
          Are you sure you want to delete this conversation?
        </Typography>
        <Button
          onClick={() => handleConfirm()}
          sx={{ bgcolor: "blue", color: "white", minWidth: "60px" }}
        >
          Yes
        </Button>
        <Button
          onClick={() => handleClose()}
          sx={{ bgcolor: "blue", color: "white", minWidth: "60px" }}
        >
          No
        </Button>
      </Box>
    </Draggable>
  );*/
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Moodbit Copilot</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Error className="me-2"/>
                    Are you sure you want to delete this conversation?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={() => handleConfirm()}>Yes</Button>
                <Button onClick={handleClose}>No</Button>
            </DialogActions>
        </Dialog>
    )
}
