import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import './index.css';

import './i18n';
import {useTranslation} from "react-i18next";

import './assets/feather/feather.css';
import './assets/ti-icons/css/themify-icons.css';
import './assets/css/vendor.bundle.base.css';
import './assets/vertical-layout-light/style.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

import {HashRouter} from "react-router-dom"

//Redux
import {Provider} from "react-redux";
import store from "./redux/store";

import {EventType, PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {msalConfig} from "./azure_auth/authConfig";
import { msalConfigOneDrive } from './azure_auth/authConfigOneDrive';

import {palette, theme} from "./assets/styles/theme";
import {CircularProgress, ThemeProvider} from "@mui/material";
import ReduxToastr from "react-redux-toastr";
import {InfinitySpin, RotatingLines} from "react-loader-spinner";
import RotatingSpinner from "./components/layouts/RotatingSpinner";
import TagManager from "react-gtm-module";

import { initializeIcons } from "@fluentui/react";
import {useTeamsFx} from "./azure_auth/lib/useTeamsFx";
import {msalConfigSharepoint} from "./azure_auth/authConfigSharepoint";

initializeIcons();

const tagManagerArgs = {
    gtmId: 'GTM-WHWTTRM'
}

TagManager.initialize(tagManagerArgs)

export const msalInstance = new PublicClientApplication(msalConfig);
export const msalInstanceOneDrive = new PublicClientApplication(msalConfigOneDrive)
export const msalInstanceSharepoint = new PublicClientApplication(msalConfigSharepoint)

const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account
        msalInstance.setActiveAccount(account)
    }
})

const AppComponent = () => {
    const {t} = useTranslation();
    const teamsContext = useTeamsFx();
    return <App instance={msalInstance} t={t} teamsContext={teamsContext}/>
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

        <MsalProvider instance={msalInstance}>

            <Provider store={store}>
                <HashRouter>
                    <ThemeProvider theme={theme}>
                        <Suspense fallback={
                            <div className="text-center col-12">
                                <p>Loading...</p>
                                <RotatingSpinner/>
                            </div>
                        }>
                            <AppComponent/>
                        </Suspense>
                    </ThemeProvider>
                </HashRouter>
                <div>
                    <ReduxToastr
                        timeOut={2000}
                        newestOnTop={false}
                        preventDuplicates
                        position="top-right"
                        getState={(state) => state.toastr} // This is the default
                        transitionIn="fadeIn"
                        transitionOut="fadeOut"
                        closeOnToastrClick/>
                </div>
            </Provider>
        </MsalProvider>

);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
