import axios from "axios";
import { Box, Button, Card, TextField , FormControl  } from "@mui/material";
import { useState } from "react";
import Draggable from "react-draggable";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import TextFieldWrapper from "../../layouts/forms/text-field-wrapper";

const EditPanel = ({ data, onClickClose }) => {
  const [title, setTitle] = useState(data.title);
  const [content, setContent] = useState(data.content);
  const [platform, setPlatform] = useState(data.platform);

  const handleClickUpdate = () => {
    axios.put(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-ai/editPost/${data.id}`, { id: data.id, title, content, platform });
  };

  const handleClickClose = () => {
    onClickClose();
  };

  return (
    <Draggable>
    <Card sx={{width:'350px', padding: '20px',cursor:'pointer', position:'absolute',top: '30%', left: '35%'}}>
      <Box sx={{padding:'15px', fontSize:'20px'}}>Edit Panel</Box>
      <TextField
        id="outlined-controlled"
        label="Title"
        value={title}
        onChange={(event) => {
          setTitle(event.target.value);
        }}
      />
      <Box label="Content" sx={{paddingY:'10px',width:'300px'}}>   
      <TextField
        id="outlined-controlled"
        label="Content"
        value={content}
        onChange={(event) => {
          setContent(event.target.value);
        }}
        sx={{width:'300px'}}
        multiline={true}
        rows={3}
      />
      </Box>      
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Platform</InputLabel>
        <Select
          id="outlined-controlled"
          label="Platform"
          value={platform}
          onChange={(event) => {
            setPlatform(event.target.value);
          }}
        >
          <MenuItem value={'wordpress'}>Wordpress</MenuItem>
          <MenuItem value={'linkedin'}>LinkedIn</MenuItem>
          <MenuItem value={'facebook'}>Facebook</MenuItem>
          {/*<MenuItem value={'twitter'}>Twitter</MenuItem>*/}
        </Select>
      </FormControl>
      <div>
      <Button onClick={() => handleClickUpdate()}>Update</Button>
      <Button onClick={() => handleClickClose()}>Close</Button>
      </div>
    </Card>
    </Draggable>
  );
};

export default EditPanel;
