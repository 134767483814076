import {Autocomplete, createFilterOptions, darken, lighten, styled, TextField} from "@mui/material";
import {useField, useFormikContext} from "formik";

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.secondary,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const AutocompleteFieldWrapper = (props) => {
    const {name, options, labelValue, idValue, setItem, ...otherProps} = props;
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const filterOptions = createFilterOptions({
        limit: otherProps.limit ? otherProps.limit : null
    });

    const handleChange = (e, newValue) => {
        setFieldValue(name, newValue);
        console.log('1. paso')
        // if (setItem) setItem(newValue);
        //setFieldValue(name, newValue[idValue]);
        if (otherProps.updateState) {
            otherProps.updateState(newValue);
        }
        console.log(field);
    };

    const configSelect = {
        ...field,
        ...otherProps,
        // value: null,
        fullWidth: true,
        onChange: handleChange
    }

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    return (
        <Autocomplete
            disablePortal
            options={options}
            /*groupBy={(option) => option.groupName}
            renderGroup={(params) => (
                <li>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                </li>
            )}*/
            filterOptions={filterOptions}
            autoHighlight
            {...configSelect}
            getOptionLabel={(option) => option[labelValue] ? option[labelValue] : ''}
            freeSolo
            renderInput={
                (params) =>
                    <TextField {...configSelect} {...params} label={otherProps.label}/>}
        />
    )
}

export default AutocompleteFieldWrapper;
