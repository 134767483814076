import { Example } from './example'
import Lottie from 'react-lottie'
import animationData from './stars.json'

import styles from './example.module.css'
import {connect} from "react-redux";

const EXAMPLES = []

const ExampleList = (props) => {
  const { onExampleClicked, indexName } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    hover: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div style={{
      color: props.chatTheme.answerText,
      background: props.chatTheme.answerBg
    }} className={styles.example}>
      <div className={styles.initialTextContainer}>
        <div style={{ width: '40px', flex: '1' }}>
          <Lottie options={defaultOptions} height={40} width={40} />
        </div>
        {indexName === 'smart-goals' || indexName === 'feedback-assistant' ?
            <div className={styles.initialText}>
              Hi, I'm Moodbit Copilot, your AI-HR agent. I'm here to help you with your human resources tasks, such as::
        </div> :
            <div className={styles.initialText}>
              <b>Welcome to CCS Creative Paths:</b> Your Personalized Journey in Art & Design! 
              I'm <b> Watson</b>, your dedicated Assistant here to help you discover the perfect courses, resources, and opportunities for your career and learning aspirations. <br></br>
              To better assist you, I'll ask you a few questions. Could you please share details about your highest level of education and your area of specialization?
              Additionally, do you have any relevant work experience in a particular industry or field?
            </div>}
      </div>
      <ul className={styles.examplesNavList}>
        {indexName === 'sap-sql' ?
            <div>
              <p className={styles.initialText + " ps-5 ms-2"}>With this Resource you have access to the following SAP information.</p>
              {[{value: "Employee Information SAP"},
                /*{value: "Employee Time information SAP"},*/
                {value: "Employee Absents SAP"},
                {value: "Forms for performance review in SAP"},
                {value: "Competencies in Forms in SAP"},
                {value: "Goals in Forms in SAP"},
              ].map((x) => (
                <li>
                  <Example text={x.value} autoHeight={true}/>
                </li>
            ))}
            </div>
            :
            indexName === 'smart-goals' || indexName === 'feedback-assistant' ?
                <div>
                  {[{value: "Help me write my Smart Goal"},
                    {value: "Help me write a feedback"},
                  ].map((x) => (
                      <li>
                        <Example text={x.value} autoHeight={true} value={x.value} onClick={onExampleClicked}/>
                      </li>
                  ))}
                </div> :
            EXAMPLES.map((x) => (
          <li>
            <Example text={x.text} value={x.value} onClick={onExampleClicked} />
          </li>
        ))}
      </ul>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    chatTheme: state.agentHrs.chatTheme,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(ExampleList)
