import * as React from 'react';
import {Annotation, Axis, DataProvider, Grid, LineSeries, Tooltip, XYChart} from '@visx/xychart';
import {curveLinear} from '@visx/curve';
import {Typography} from '@mui/material';
import {connect} from "react-redux";
import {Spellcheck} from "@mui/icons-material";
import * as moment from 'moment';
import {LegendOrdinal, LegendThreshold} from "@visx/legend";
import {scaleOrdinal, scaleThreshold} from "@visx/scale";

const makeDatum = (y, x) => ({x, y});

// const useTheme = makeVisxTheme();

const accessors = {
    xAccessor: ({x}) => x,
    yAccessor: ({y}) => y
};

const {format: formatDate} = new Intl.DateTimeFormat(undefined, {
    month: 'short',
    day: 'numeric'
});
const formatDay = (i, items) => {
    const date = moment(items[i].date).format('YYYY-MM-DD HH:mm:ss');
    // console.log(date);
    return formatDate(new Date(date));
}

const getSeries = (items) => {
    return [
        {
            dataKey: 'Words',
            data: items?.map((item, x) => {
                return {x, y: item.wordCount}
            })
        },
        {
            dataKey: 'Tokens',
            data: items?.map((item, x) => {
                return {x, y: item.tokenCount}
            })
        },
    ];
}

const BarChart = (props) => {
    //  const theme = useTheme();
    const {open, resource} = props;

    /*const getLoading = () => {
        if(open) {
            setLoad(true);
            setInterval(() => {
                setLoad(loading);
            }, 300);
        }
        console.log('ASDASDSAD', load);
    }
    getLoading();*/

    /*useEffect(() => {
        if(open) {
            setLoad(true);
            setTimeout(() => {
                setLoad(false);
            }, 1000)
        }
    }, [open]);*/
    const threshold = scaleOrdinal({
        domain: ['Words', 'Tokens'],
        range: ['#66d9e8', '#0b7285'],
    });
    return (

        <div className="pt-2 pe-1">
            <div className="d-flex justify-content-end mb-3">
                <LegendOrdinal
                    scale={threshold}
                    direction="column"
                    itemDirection="row-reverse"
                    labelMargin="0 20px 0 0"
                    shapeMargin="1px 0 0"
                />
            </div>
            <DataProvider
                xScale={{type: 'band'}}
                yScale={{type: 'linear', nice: true}}>
                <XYChart
                    height={280}
                    margin={{top: 0, bottom: 30, left: 20, right: 40}}>
                    <Grid columns={true} rows={true} numTicks={2}/>
                    {getSeries(resource).map((props) => (
                        <LineSeries
                            key={props.dataKey}
                            {...props}
                            curve={curveLinear}
                            {...accessors}
                        />

                    ))}
                    <Axis orientation="bottom" tickFormat={(data) => {
                        return formatDay(data, resource)
                    }}/>
                    <Axis
                        orientation="right"
                        hideAxisLine
                        numTicks={5}/>
                    <Tooltip
                        renderTooltip={({tooltipData}) => {
                            const nearestDatum = tooltipData?.nearestDatum;
                            if (!nearestDatum) return null;
                            return (
                                <>
                                    <Typography variant="h6">
                                        <b>{nearestDatum.datum.y}</b> <Spellcheck fontSize="small"/>
                                    </Typography>
                                    <Typography variant="body2">
                                        {formatDay(nearestDatum.datum.x, resource)}
                                    </Typography>
                                </>
                            );
                        }}
                    />
                </XYChart>
            </DataProvider>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        // countDashboard: state.dashboard.countDashboard,
        // countBarDashboard: state.dashboard.countBarDashboard
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BarChart);
