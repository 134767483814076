import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import * as React from "react";
import {useState} from "react";
// import CustomerPortal from "../OldComponents/HomePage/CustomerPortal";
import {Avatar, Badge, Box, capitalize, Divider, IconButton, MenuList, Tooltip} from "@mui/material";
import NotificationMobile from "./NotificationMobile";
import {Logout, PhotoCamera, Settings} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import UserEditPhotoIndex from "../user/user-edit-photo/user-edit-photo-index";
import './MenuUser.css';

const MenuUser = ({...props}) => {
    const {t} = useTranslation();
    const settings = [
        /*{
            name: 'Account',
            route: '/user-profile'
        },*/
        {
            name: 'Logout',
            translation: 'Logout',
            route: '/',
            icon: <Logout/>
        },
    ];

    const {anchorElUser, handleCloseUserMenu, user} = props;
    const [openEditPhoto, setOpenEditPhoto] = useState(false);
    console.log('PROPSSSSSSSS', props);
    console.log("handleCloseUserMenu: " , handleCloseUserMenu)

    return (
        <Box>
            <Menu
                sx={{mt: {sm: '-50px', xs: '100px'}}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={Boolean(anchorElUser)}
                onClose={() => handleCloseUserMenu()}>
                {/*<CustomerPortal email={email} handleCloseUserMenu={handleCloseUserMenu} handleTeamChange={handleTeamChange} team={team} t={t}/>*/}
                <MenuItem className="text-secondary">
                    <Typography textAlign="center" className="text-small">{props.user?.email}</Typography>
                    <Tooltip title={t('user.settings')}>
                        <IconButton size="large" aria-label="" color="inherit"
                                    onClick={() => handleCloseUserMenu({route: '/user-profile'})}>
                            <Settings fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                </MenuItem>
                <Divider/>
                <MenuList disableListWrap={true} className="justify-content-center">
                    <div className="text-center">
                        <Badge overlap="circular"
                               anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                               badgeContent={
                                   <Tooltip title={t('user.picture')}>
                                       <Box className="bg-white bg-photo">
                                           <IconButton className="photo"
                                                       onClick={() => setOpenEditPhoto(true)}>
                                               <PhotoCamera fontSize="small"/>
                                           </IconButton>
                                       </Box>
                                   </Tooltip>
                               }>
                            <Avatar alt={user?.firstName} className="m-auto"
                                    src={`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/users/download/${user.photo}`}
                                    sx={{width: 80, height: 80}}/>
                        </Badge>
                        <p className="my-2 px-2">
                            <b>{capitalize(user?.firstName || '') + ' ' + capitalize(user?.lastName || '')}</b>
                        </p>
                    </div>
                </MenuList>
                {/*<NotificationMobile/>*/}
                <Divider/>
                {settings.map((setting, index) => (
                    <span key={index}>
                    {index === settings.length - 1 ? <Divider variant="middle"/> : null}
                        <MenuItem onClick={() => handleCloseUserMenu(setting)} key={index}>
                            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                                    {setting.icon}
                            </IconButton>
                        <Typography textAlign="center">{setting.translation}</Typography>
                    </MenuItem>
                </span>
                ))}
            </Menu>
            {openEditPhoto ? <UserEditPhotoIndex open={openEditPhoto}
                                                 user={user}
                                                 t={t}
                                                 onClose={setOpenEditPhoto}/> : null}</Box>
    )
}
export default MenuUser;
