
export function parseSupportingContentItem(item) {
    // Assumes the item starts with the file name followed by : and the content.
    // Example: "sdp_corporate.pdf: this is the content that follows".
    // console.log("ITEM PARSE", item)
    if (item.content) {
        if (item.content.includes("FILE NAME=")) {
            /** for CCS sources by google drive*/
            const title = item.title;
            const content = item.content;
            return {
                title,
                content
            };
        }
        item = item.content;
    }
    const parts = item.split(": ");
    const title = parts[0];
    const content = parts.slice(1).join(": ");

    return {
        title,
        content
    };
}
