import {RotatingLines} from "react-loader-spinner";
import {palette} from "../../assets/styles/theme";

const RotatingSpinner = () => {
    return (
        <div className="d-flex justify-content-center text-center">
            <RotatingLines
                strokeColor={palette.primary2}
                strokeWidth="3"
                animationDuration="1.2"
                width="40"
                visible={true}/>
        </div>
    )
}
export default RotatingSpinner;
