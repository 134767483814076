import {
    Avatar,
    Card, CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    IconButton,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {CalendarMonth, DateRange, EventRounded, Visibility} from "@mui/icons-material";
import React, {useState} from "react";
// import './post-month.css';
import animationData from '../stars.json';
import {truncateString} from "../../../../helpers/text";
import wordpressLogo from '../../../../assets/Wordpress_Blue_logo.png';
import linkedinLogo from '../../../../assets/Linkedin_blue_logo.png';
import instagramLogo from '../../../../assets/Instagram_icon.png';
import tiktokLogo from '../../../../assets/TikTokLogo.png';
import twitterLogo from '../../../../assets/Twitter_icon.png';
import {useNavigate, useParams} from "react-router-dom";
import * as moment from "moment";
import PlatformIcon from "../layouts/platform-icon";

const createOptions = (name, routerLink, translation) => {
    return {
        name,
        routerLink,
        translation
    }
}

const PlanMonthItem = (props) => {
    const {plan_id} = useParams();
    const facebookLogo = 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg';

    const options = [
        createOptions('Delete', '', 'delete'),
    ]

    const {item} = props;
    const navigate = useNavigate();

    return (
        <div className="h-100">
            <Card className={`h-100 position-relative`} style={{
                /*background: plan_id === item.id && '#ace5b5'*/
                border: plan_id === item.id && '2px solid #14A828FF',
                boxShadow: plan_id === item.id && '0 0 10px rgba(20, 168, 40, 0.5)'
            }}>
                <CardActionArea onClick={() => navigate(`/post-month/${item.id}`)}>
                    <CardHeader title={truncateString(item.topic, 20)} avatar={<span className="me-1">
                        <PlatformIcon platform={item.platform} sz={24} currentPost={item}/>
                        </span>}/>
                    <CardContent className="mb-4">
                        <Typography gutterBottom component="div" color="text.secondary">
                            <DateRange/> <b>{moment(item.startDate).format('MMMM YYYY')}</b>
                        </Typography>
                        { item.extraInfo && <Typography gutterBottom component="div" color="text.secondary">
                            Extra Info: <b>{item.extraInfo}</b>
                        </Typography>}

                        { item.dayFrequency && <Typography gutterBottom component="div" color="text.secondary">
                            Post per Day: <b>{item.dayFrequency}</b>
                        </Typography>}

                        { item.days && <Typography gutterBottom component="div" color="text.secondary">
                            Days of the week: <Stack direction="row" className="d-inline flex-wrap">
                            {item.days.map((day, index) => day && <Chip label={moment().weekday(index).format('ddd')} key={index} icon={<EventRounded/>} color="info" size="small" className="mb-1 ms-1"/>)}
                        </Stack>
                        </Typography>}
                    </CardContent>
                    <CardActions className="justify-content-end w-100 position-absolute bottom-0">
                        {/*<Tooltip title={'See Plan'}>
                            <IconButton size="small" onClick={() => navigate(`/post-month/${item.id}`)}>
                                <CalendarMonth/>
                            </IconButton>
                        </Tooltip>*/}
                    </CardActions>
                </CardActionArea>
            </Card>
            {/*<div className="card-action-bottom border-top">
                <div className="d-flex justify-content-evenly animateHover">
                    <Tooltip title={'Send post'}>
                        <IconButton onClick={() => sendPost()}>
                            {sending ? <Lottie options={defaultOptions} height={40} width={40}/> : <Telegram/>}
                        </IconButton>
                    </Tooltip>
                </div>
            </div>*/}
        </div>
    )
}

export default PlanMonthItem;
