import {Mail, Notifications} from "@mui/icons-material";
import {Badge, Box, IconButton} from "@mui/material";

const Notification = () => {

    return (
        <Box sx={{ display: 'none' }} className="me-2">
            <IconButton size="large" aria-label="show 4 new mails">
                <Badge badgeContent={8} color="error">
                    <Mail/>
                </Badge>
            </IconButton>
            <IconButton
                size="large"
                aria-label="show 17 new notifications">
                <Badge badgeContent={5} color="error">
                    <Notifications />
                </Badge>
            </IconButton>
        </Box>
    )
}

export default Notification;
