import {connect, useSelector} from "react-redux";

export const useTeamsPostAIGuard = (route=null) => {
    const bot_access_user = useSelector(state => state.users.bot_access_user)

    const teamPostAIAccount = bot_access_user?.teamPostAIUser;
    // console.log("BOT ACCESS", teamPostAIAccount)

    return !!teamPostAIAccount;
}
