import AgentHrActionTypes from "./agent-hr.types";

//ACTION CREATORS

export const setIndex = (payload) => ({
    type: AgentHrActionTypes.SET_INDEX,
    payload,
});

export const setContainer = (payload) => ({
    type: AgentHrActionTypes.SET_CONTAINER,
    payload,
});

export const setIndexObj = (payload) => ({
    type: AgentHrActionTypes.SET_INDEX_OBJ,
    payload,
});

export const setCopilotTheme = (payload) => ({
    type: AgentHrActionTypes.SET_THEME,
    payload,
});
