import {MenuItem, TextField} from "@mui/material";
import {useField, useFormikContext} from "formik";
import {useTranslation} from "react-i18next";
import * as moment from "moment";

const SelectFieldMonthsWrapper = (props) => {
    const {name, options, labelValue, idValue, simple, ...otherProps} = props;
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (e) => {
        const {value} = e.target;
        console.log(value);
        setFieldValue(name, value);
        if (otherProps.updateState && !simple) {
            otherProps.updateState(value);
        }
    };

    const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        variant: 'outlined',
        fullWidth: true,
        onChange: handleChange
    }

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    const isValidMonth = (month) => {
        const currentMonth = moment().format('M');
        return currentMonth <= month;
    }

    return (
        <TextField {...configSelect}>
            {options.map((item, pos) => {
                return (
                    <MenuItem key={pos + item[idValue]} value={item[idValue]}
                              disabled={!isValidMonth(item[idValue])}>
                        { item[labelValue]}
                    </MenuItem>
                )
            })}
        </TextField>
    )
}

export default SelectFieldMonthsWrapper;
