import { Stack } from '@fluentui/react'
import { animated, useSpring } from '@react-spring/web'

import Lottie from 'react-lottie'
import animationData from './stars.json'
// import animationData from './dots.json'

import styles from './answer.module.css'
import './text-rotation.css'
import { AnswerIcon } from './answer-icon'
import { ThreeDots } from 'react-loader-spinner'
import {AutoFixHigh, AutoFixNormal} from "@mui/icons-material";
import {useState} from "react";
import {connect} from "react-redux";

const FollowupLoading = (props) => {
  const animatedStyles = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  })
  const [sw, setSw] = useState(false)

  setTimeout(() => {
    setSw(!sw)
  }, 300)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    hover: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <animated.div style={{ ...animatedStyles }}>
      <Stack
        className={styles.answerContainerLoading}
        verticalAlign="space-between"
        style={{flexFlow: 'row', maxWidth: 'none'}}
      >
        {/*<AnswerIcon />*/}
        {/*<div style={{ width: '40px' }}>
          <Lottie options={defaultOptions} height={40} width={40} />
        </div>*/}
        {sw ?
            <AutoFixHigh style={{
                color: props.chatTheme.followupBg
              }}/> :
            <AutoFixNormal style={{
              color: props.chatTheme.followupBg
            }}/>
        }
        <Stack.Item grow>
          {/*<p className={styles.answerTextLoading}>
            <div className="h-24">
              <div className="cubespinner">
                <div className="face1">
                  Hold on while we are generating insights for you...
                </div>
                <div className="face2">
                  Copilot is working on it...
                </div>
                <div className="face3">
                  Processing the data...
                </div>
              </div>
            </div>
          </p>*/}
        </Stack.Item>
      </Stack>
    </animated.div>
  )
}

const mapStateToProps = (state) => {
  console.log('state app:', state)
  return {
    chatTheme: state.agentHrs.chatTheme
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowupLoading)
