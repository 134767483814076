import {Component, useState} from "react";
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Chip, CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText
} from "@mui/material";
import * as React from "react";
import {getDashboardThunk} from "../../redux/dashboard/dashboard.thunk";
import {connect} from "react-redux";
import TitleCard from "./TitleCard";
import {Sms} from "@mui/icons-material";
import RotatingSpinner from "../layouts/RotatingSpinner";

class CardCustomers extends Component {

    sortItems = () => {
        const items = this.props.countDashboard.getCountUser.map((el, index) => el);
        items.sort((a, b) => {
            // return (b.currentVolume - b.lastVolume) - (a.currentVolume - a.lastVolume);
            return b.currentVolume - a.currentVolume
        });

        console.log('ITEMS', items);

        const newItems = items.filter((item) => {
            return item.currentVolume !== 0 || item.lastVolume !== 0;
        });

        if (newItems.length >= 5) return newItems.slice(0,5);
        else return newItems;
    }

    status = (items, item) => {
        let max = -999;
        items.forEach((a) => max = Math.max(a.currentVolume, max));

        console.log('MAX', max);
        const per = max !== 0 ? item.currentVolume * 100 / max : 0;
        // return per >= 50 ? 'Influencer' : 'Isolate';
        return per;
    }

    render() {
        const {loading, countDashboard} = this.props;
        return (
            <Card>
                <CardHeader title={<TitleCard>INFLUENCERS & ISOLATED</TitleCard>}/>
                { !loading ?
                    <CardContent className="px-1 content-scroll" sx={{maxHeight: '420px', overflowY: 'hidden'}}>
                        <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                            {this.sortItems().map((user, index) =>
                                <div key={user.userName + index}>
                                    <ListItem className="px-1 flex-wrap">
                                        <ListItemAvatar sx={{minWidth: 24}}>
                                            <Avatar className="me-2" sx={{width: 24, height: 24}} alt={user.userName}
                                                    src={user.userImage}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={user.userName}
                                            secondary={<>{user.currentVolume} <Sms fontSize="small"/></>}
                                        />
                                        <Chip label={this.status(countDashboard.getCountUser, user) >= 50 ? "Influencer" : 'Isolate'}
                                              color={this.status(countDashboard.getCountUser, user) >= 50 ? 'success' : 'error'}
                                              size="small"/>
                                    </ListItem>
                                    {index + 1 < countDashboard.getCountUser.length ? <Divider variant="inset" component="li"/> : null}
                                </div>)}
                        </List>
                    </CardContent> : <CardContent>
                        <div className="text-center">
                            <p>Loading...</p>
                            <RotatingSpinner/>
                        </div>
                    </CardContent>}
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        countDashboard: state.dashboard.countDashboard
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // getDashboard: (tenantId) => dispatch(getDashboardThunk(tenantId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardCustomers);
