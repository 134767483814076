import {Card, CardContent, CardHeader, CircularProgress, Divider, List, ListItem, ListItemText} from "@mui/material";
import TitleCard from "./TitleCard";
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import {connect} from "react-redux";
import * as React from "react";
import {getPercent} from "../../helpers/math";
import RotatingSpinner from "../layouts/RotatingSpinner";

const CardChannels = (props) => {
    const {loading, countDashboard} = props;

    const loadData = () => {
        return countDashboard.getCountChannel.map((item) => {
            item.difference = item.avgCurrentVolume - item.avgLastVolume;
            item.percent = getPercent(item.avgCurrentVolume, item.avgLastVolume);
            return item;
        });
    }
    return (
        <Card>
            <CardHeader title={<TitleCard>TEAMS</TitleCard>}/>
            { !loading ? <CardContent className="pe-1">
                <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                    {loadData().map((item, index) =>
                        <div key={item.channelName + index}>
                            <ListItem>
                                <ListItemText
                                    primary={item.channelName}
                                    secondary={
                                        <React.Fragment>
                                            <small className="fs-sm">
                                                {item.percent >= 0 ?
                                                    <span
                                                        className="text-success"><ArrowDropUp/>{`${item.percent} % `}</span> :
                                                    <span
                                                        className="text-danger"><ArrowDropDown/>{`${item.percent * -1} % `}</span>}
                                                last week
                                            </small>
                                        </React.Fragment>
                                    }
                                />
                                <div className="text-end">
                                    <h5>{item.avgCurrentVolume}</h5>
                                    {/*<small className="text-black-50">{item.date}</small>*/}
                                </div>
                            </ListItem>
                            {index + 1 < countDashboard.getCountChannel.length ? <Divider variant="inset" component="li"/> : null}
                        </div>)}
                </List>
            </CardContent> : <CardContent>
            <div className="text-center">
                <p>Loading...</p>
                <RotatingSpinner/>
            </div>
        </CardContent>}
        </Card>
    );
}

const mapStateToProps = (state) => {
    return {
        countDashboard: state.dashboard.countDashboard
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // getDashboard: (tenantId) => dispatch(getDashboardThunk(tenantId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardChannels);
