import {
  parseAnswer,
  getCitationsFromParsedAnswer,
  getSqlResultsFromParsedAnswer,
} from "./parseAnswer";


function compareDates(a, b) {
  // Create Date objects from the createdAt values (assuming they are strings)
  const dateA = new Date(a.createdAt);
  const dateB = new Date(b.createdAt);

  // Compare the timestamps of the Date objects for accurate sorting
  return dateA.getTime() - dateB.getTime();
}


export function createQuestionAndAnswersFromMessages(messages) {
  let questionsAndAnswers = [];
  let question = "";
  let answer = "";
  let insights = "";
  let lastSourceName = "";
  let lastContainerName = "";
  let data_points = [];
  let file_urls = [];
  let rate = undefined;
  messages.sort(compareDates);
  for (const message of messages) {
    rate = undefined;
    if (message.role === "user") {
      question = message.message.content ? message.message.content : "";
    }
    if (message.role === "assistant") {
      answer = message.message.content ?  message.message.content : "";
      data_points = message.message.data_points ? message.message.data_points : [];
      file_urls = message.message.file_urls ?  message.message.file_urls : [];
      rate = message.message.rate !== undefined ?  message.message.rate : undefined;
    }
    insights =
      message.message.insight !== undefined ? message.message.insight : "";
    if (question !== "" && answer !== "") {
      const parsedAnswer = parseAnswer(answer);
      questionsAndAnswers.push([question, {
        id: message.id,
        answer: answer,
        // parsedAnswer: parsedAnswer,
        // insights: insights,
        // citations: getCitationsFromParsedAnswer(parsedAnswer),
        // sqlResults: getSqlResultsFromParsedAnswer(parsedAnswer),
        // sourceName: message.message.indexName,
        containerName: message.message.containerName,
        finish: true,
        rate,
        data_points,
        file_urls
      }])
      // questionsAndAnswers.push({
      //   question: question,
      //   answer: {
      //     answer: answer,
      //     parsedAnswer: parsedAnswer,
      //     insights: insights,
      //     citations: getCitationsFromParsedAnswer(parsedAnswer),
      //     sqlResults: getSqlResultsFromParsedAnswer(parsedAnswer),
      //     sourceName: message.message.indexName,
      //     containerName: message.message.containerName,
      //     /*
      //     chart:
      //       message.message?.chart !== undefined ? message.message.chart : "",
      //     */
      //     generateChart:
      //       question.length <= 1000 && answer.length <= 6000
      //         ? message.message.generateChart
      //         : "",
      //   },
      // });
      question = "";
      answer = "";
      insights = "";
      lastSourceName = message.message.indexName;
      lastContainerName = message.message.containerName;
    }
  }

  return {
    questionsAndAnswers,
    lastSourceName,
    lastContainerName,
  };
}

export function createHistoryFromQuestionsAndAnswers(questionsAndAnswers) {
  const history = questionsAndAnswers.map((value) => ({
    user: value.question,
    bot: value.answer.answer,
    sql_bot: "",
  }));
  return history;
}

/*
export function createHistoryFromMessages(messages) {
  const history = [];
  let question = "";
  let answer = "";
  for (const message of messages) {
    if (message.role === "user") question = message.content;
    if (message.role === "assistant") answer = message.content;
    if (question !== "" && answer !== "") {
      history.push({
        user: question,
        bot: answer,
        sql_bot: "",
      });
      question = "";
      answer = "";
    }
  }
  return history;
}
*/
