import {
    Autocomplete,
    Box, Chip,
    darken,
    lighten, List,
    ListItem,
    ListItemText,
    ListSubheader,
    styled,
    TextField
} from "@mui/material";
import {useField, useFormikContext} from "formik";
import './autocomplete-chip.css';
import {useTranslation} from "react-i18next";

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.secondary,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const AutocompleteFieldWrapperChip = (props) => {
    const {name, options, labelValue, idValue, setItem, ...otherProps} = props;
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);
    const {t} = useTranslation();

    const handleChange = (e, newValue) => {
        setFieldValue(name, newValue);
        //console.log('1. paso')
        // if (setItem) setItem(newValue);
        //setFieldValue(name, newValue[idValue]);
        //console.log(field);
        setFieldValue(name, newValue);
        if (otherProps.updateState) {
            otherProps.updateState(newValue);
        }
    };

    const configSelect = {
        ...field,
        ...otherProps,
        // value: null,
        fullWidth: true,
        onChange: handleChange
    }

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    return (
        <Autocomplete
            disablePortal
            options={options}
            renderOption={(props, option) => (
                <Box {...props}>
                    <Chip label={otherProps.labelTranslate ? t(option[otherProps.labelTranslate]) : option[labelValue]} variant="filled" className="custom-chip" color="secondary"/>
                </Box>
            )}
            autoHighlight
            {...configSelect}
            getOptionLabel={(option) => otherProps.labelTranslate ? t(option[otherProps.labelTranslate]) : option[labelValue] ? option[labelValue] : ''}
            freeSolo
            renderInput={
                (params) =>
                    <TextField {...configSelect} {...params} label={otherProps.label}/>}
        />
    )
}

export default AutocompleteFieldWrapperChip;
