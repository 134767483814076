import {List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {Done} from "@mui/icons-material";
import DialogContact from "./dialog-contact/dialog-contact";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import BillingContact from "./billing-contact";

const BillingContactIndex = () => {
    return (
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <BillingContact/>
            </div>
        </div>
    )
}

export default BillingContactIndex;
