import * as React from 'react';
import {memo, useEffect, useState} from 'react';
import {Avatar, Box, IconButton, styled, Toolbar, Tooltip, Typography, useTheme} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/MoodbitLogo.png';
import MenuUser from "./MenuUser";
import {adminItems, isNormalPage} from "../admin-items";
import {useNavigate} from "react-router-dom";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {connect} from "react-redux";
import './NavBar.css';
import {getUserThunk} from "../../redux/users/users.thunk";
import {AccountCircle, Close, Search} from "@mui/icons-material";
import {SearchIconWrapper, SearchInput, StyledInputBase} from "./SearchInput";
import Notification from "./Notification";
import {useTranslation} from "react-i18next";
import {isCountries} from "../../helpers/region";

//const pages = ['Dashboard', 'Surveys', 'Reports'];

const drawerWidth = 240;

const styles = {
    logo: {
        width: '180px',
        cursor: 'pointer',
        // opacity: localStorage.getItem('gtp-country') === 'Bolivia' ? 0 : 1
    }
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
         width: `calc(100% - ${drawerWidth}px + ${theme.spacing(7)} + 1px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const NavBar = memo((props) => {

    const {t} = useTranslation();

    const items = adminItems();
    const theme = useTheme();

    const isAuthenticated = useIsAuthenticated();
    const {open, handleDrawerOpen, handleDrawerClose, loginContext} = props;

    const [anchorElNav, setAnchorElNav] = useState(null);

    const [team, setTeam] = useState(props.selectedTeam);

    const {accounts, instance, inProgress} = useMsal();

    const navigate = useNavigate();

    /*const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };*/


    /*const handleCloseNavMenu = (route = null) => {
        setAnchorElNav(null);
        if (route !== null) {
            navigate(route);
        }
    };*/



    const routerLink = (route) => {
        navigate(route);
    }

    const handleTeamChange = (e) => {

        setTeam(e.target.value)
        // props.changeSelectedTeam(e.target.value, props.user)

    }
    /*const [openTeams, setOpenTeams] = useState(false);
    const valuePage = false;//isNormalPage();
    console.log("*********** extra page information: ", isNormalPage())*/

    useEffect(() => {
        setTeam(props.selectedTeam);

    }, [props.selectedTeam])

    /*if(!valuePage) {
        handleDrawerOpen()
    }*/
    return (
        <AppBar position="fixed" color={isAuthenticated ? "inherit" : '' }
                sx={{
                    top: {xs: 'auto', sm: 0},
                    paddingLeft: {xs: open ? `calc(${theme.spacing(5)} + 1px)` : 'auto', sm: `calc(${theme.spacing(7)} + 1px)`},
                    /*paddingLeft: 'auto',*/
                    zIndex: (theme) => theme.zIndex.drawer - 1
                }}
                className={!isAuthenticated ? 'bg-light-2' : 'border-bottom'}
                open={open}
                style={{boxShadow: 'none'}}>

            <Toolbar>
                {loginContext === 'standard' && isAuthenticated ?
                    <IconButton size="large"
                                /*sx={{display: {xs: 'inline', sm: 'none'}}}*/
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={open ? handleDrawerClose : handleDrawerOpen}
                                color="inherit">
                        { open ? <Close/> :<MenuIcon/>}
                    </IconButton> : null}
                {!isAuthenticated ?
                    <Typography variant="h6" noWrap component="div" sx={{mr: 2, display: 'inline'}}>
                        <img style={{...styles.logo, opacity: isCountries(props.country) ? 0 : 1}} src={logo} alt="" onClick={() => routerLink('/')}/>
                    </Typography> : null}

                {/*isAuthenticated ?
                    <SearchInput>
                        <SearchIconWrapper>
                            <Search/>
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </SearchInput>
                    : null*/}

                {isAuthenticated ?
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}/>:
                    null}
                {isAuthenticated ? <Notification/> : null}

                {/*isAuthenticated ? <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {items.map((page, index) => (
                            <NavLink to={page.routerLink} style={{textDecoration: 'none'}} key={index}>
                                <Button variant={page.link ? "text" : "contained"}
                                        color="primary"
                                        sx={{my: 2, color: page.link ? '#4c4b4b' : 'white', display: 'block'}}>
                                    {page.name}
                                </Button>
                            </NavLink>))}
                    </Box> : null*/}
                {/* {isAuthenticated && loginContext === 'standard' && accounts[0] && props.user ?
                    <Box sx={{ display: {xs: 'none', sm: 'inline'}}}>
                    <button className="btn btn-primary me-3" onClick={() => navigate('/billing-contact')}>Upgrade Now</button>
                    </Box>
                : null} */}

                {isAuthenticated && accounts[0] && props.user ?
                    <Box>
                        <Tooltip title={t('settings')}>
                            <IconButton size="large" onClick={() => navigate('/user-profile')} sx={{p: 0}}>
                                <Avatar alt={accounts[0].idTokenClaims?.extension_firstName}
                                        src={`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/users/download/${props.user?.photo}`}/>

                            </IconButton>
                        </Tooltip>
                    </Box> : null}
            </Toolbar>
        </AppBar>
    );
});

const mapStateToProps = (state) => {
    console.log("state Nav", state);
    return {
        user: state.users.user,
        country: state.users.country,
        selectedTeam: state.users.selectedTeam,
        selectedAccount: state.users.selectedAccount
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // getUserSlack: (email) => dispatch(getUserThunk(email)),
        // changeSelectedTeam: (teamId, user) => dispatch(changeSelectedTeamThunk(teamId, user))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
