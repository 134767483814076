import {MenuItem, TextField} from "@mui/material";
import {useField, useFormikContext} from "formik";
import {useTranslation} from "react-i18next";

const SelectFieldWrapper = (props) => {
    const {name, options, labelValue, idValue, simple, ...otherProps} = props;
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const {t} = useTranslation();

    const handleChange = (e) => {
        const {value} = e.target;
        console.log(value);
        setFieldValue(name, value);
        if (otherProps.updateState && !simple) {
            otherProps.updateState(value);
        }
    };

    const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        variant: 'outlined',
        fullWidth: true,
        onChange: handleChange
    }

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    return (
        <TextField {...configSelect}>
            {options.map((item, pos) => {
                return (
                    <MenuItem key={pos + item[idValue]} value={simple ?
                        (otherProps.prefixTranslate ? t(otherProps.prefixTranslate+item[idValue]) : item[idValue])
                        : (otherProps.prefixTranslate ? t(otherProps.prefixTranslate+item) : item)}>
                        {item.code ?
                            <img
                                loading="lazy"
                                width="25"
                                src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${item.code.toLowerCase()}.png 2x`}
                                alt=""
                                className="me-1"
                            />
                            : null}
                        { otherProps.prefixTranslate ? t(otherProps.prefixTranslate+item[labelValue]) : item[labelValue]}
                    </MenuItem>
                )
            })}
        </TextField>
    )
}

export default SelectFieldWrapper;
