const TitleCard = (props) => {
    const {children, color} = props;
    const styles = {
        bar: {
            borderLeft: `4px solid ${color || '#1C1CB3FF'}`,
            paddingLeft: '5px',
        }
    };

    return (
        <h6 style={styles.bar}>{children}</h6>
    );
}

export default TitleCard;
