import Lottie from 'react-lottie';
import animationData from './stars.json'
// import styles from './answer-icon.module.css'

export const AnswerIcon = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    hover: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div style={{ width: '40px' }}>
      <Lottie options={defaultOptions} height={40} width={40} />
    </div>
  )
}
