import ContentActionTypes from "./content.types";

//ACTION CREATORS

export const changeContentStatus = (payload) => ({
    type: ContentActionTypes.CHANGE_STATUS,
    payload,
});

export const generateContent = (payload) => ({
    type: ContentActionTypes.GENERATE_CONTENT,
    payload,
});

export const getCustomeTopics = (payload) => ({
    type: ContentActionTypes.GET_TOPICS,
    payload,
});

export const updateLanguage = (payload) => ({
    type: ContentActionTypes.UPDATE_LANGUAGE,
    payload,
});

export const saveForm = (payload) => ({
    type: ContentActionTypes.SAVE_FORM,
    payload,
});
