import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {useState} from "react";

const WeekDays = (props) => {
    const {days, setDays} = props;
    const [myDays, setMyDays] = useState([]);
    const handleDays = (e, days) => {
        console.log("DAYSSS", e, days);
        if (days.length) {
            setMyDays(days);

        } else {
            setMyDays([])
        }
        let newDays = [false, false, false, false, false, false, false];
        days.map(item => {
            newDays[item] = true;
        });
        setDays(newDays);
    }
    return (
        <>
            <p className="text-secondary">Days of the week *</p>
            <ToggleButtonGroup value={myDays}
                               onChange={handleDays}
                               color="primary"
                               className="justify-content-center"
                               aria-label="myDays">
                <ToggleButton value="1" aria-label="monday"
                              sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                    Mon
                </ToggleButton>
                <ToggleButton value="2" aria-label="tuesday"
                              sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                    Tue
                </ToggleButton>
                <ToggleButton value="3" aria-label="wednesday"
                              sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                    Wed
                </ToggleButton>
                <ToggleButton value="4" aria-label="thursday"
                              sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                    Thur
                </ToggleButton>
                <ToggleButton value="5" aria-label="friday"
                              sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                    Fri
                </ToggleButton>
                <ToggleButton value="6" aria-label="saturday"
                              sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                    Sat
                </ToggleButton>
                <ToggleButton value="0" aria-label="sunday"
                              sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                    Sun
                </ToggleButton>
            </ToggleButtonGroup>
        </>
    )
}

export default WeekDays;
