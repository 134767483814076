import {Component} from "react";
import {getCountTokensWordsService} from "../../../services/content.service";
import {connect} from "react-redux";
import {InfinitySpin} from "react-loader-spinner";
import {palette} from "../../../assets/styles/theme";
import BarChart from "../../layouts/charts/bar-chart";

class CountIndex extends Component {
    state = {
        counts: null,
        loading: false,
        totalCounts: null
    }
    async componentDidMount() {
        ///TODO replace this for the current workspace selected
        this.setState({loading: true})

        if (this.props.user?.analytics_workspaces?.length > 0) {
            const idWorkspace = this.props.user.analytics_workspaces[0].id;

            const counts = await getCountTokensWordsService(idWorkspace);
            if(counts) {
                const totalCounts = this.getTotal(counts);
                this.setState({counts, loading: false, totalCounts});
            } else {
                this.setState({counts, loading: false, totalCounts: {
                        totalTokens: 0,
                        totalWords: 0
                    }});
            }
        }
    }

    getTotal = (counts) => {
        let totalTokens = 0;
        let totalWords = 0;
        counts.map((item) => {
            totalTokens += item.tokenCount;
            totalWords += item.wordCount;
        });
        return {
            totalTokens,
            totalWords
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="card my-3">
                        <div className="card-body">
                            {this.state.loading ?
                                <div className="text-center">
                                    <div className="d-flex justify-content-center text-center">
                                        <InfinitySpin
                                            color={palette.primary}
                                            width="200"/>
                                    </div>
                                    <p className='card-description line-break'>Loading...</p>
                                </div>:
                                <div className="">
                                    <p className="my-3">Total Tokens Used<h4 className="font-weight-bold">{this.state.totalCounts?.totalTokens || 0}</h4></p>
                                    <p className="my-3">Total Words Used<h4 className="font-weight-bold">{this.state.totalCounts?.totalWords || 0}</h4></p>
                                </div>
                            }
                            {this.state.counts ? <BarChart resource={this.state.counts}/> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        user: state.users.user,
        status: state.users.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // updateContentLanguage: (obj) => dispatch(updateLanguage(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountIndex);
