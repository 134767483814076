import React, {useEffect} from 'react';
import {Avatar, Badge, Button, Chip, CircularProgress, Drawer, Fab, Skeleton, Tooltip} from "@mui/material";
import {AddToDrive, Close, Cloud, Google} from "@mui/icons-material";
import {msalInstanceOneDrive} from "../../index";
import {loginRequest, loginRequestJWT} from "../../azure_auth/authConfigOneDrive";
import {
    deleteGoogleDriveFolderFilesService, deleteOneDriveAccountService, deleteOneDriveFolderFilesService,
    getFoldersOneDriveService,
    loadOneDriveFolderFilesService,
    storeTokenOneDriveService
} from "../../services/drive-hr.service";
import {useState} from "react";
import OnedriveFolderIndex from "./onedrive-folder-index";
import {addIntegrationDriveService, updateIndexDriveService} from "../../services/user.service";
import {
    addUserThunk, deleteDriveUserThunk,
    editUserThunk,
    getUserWithEmailThunk,
    updateDriveUserThunk,
    updateIndexUserThunk
} from "../../redux/users/users.thunk";
import {updateCountry} from "../../redux/users/users.actions";
import {connect} from "react-redux";
import RotatingSpinner from "../layouts/RotatingSpinner";
import {toastr} from "react-redux-toastr";
import logo from '../../assets/GoogleDriveLogo.png';
import {AuthenticatedTemplate, useMsal} from "@azure/msal-react";

import * as microsoftTeams from "@microsoft/teams-js";
// google drive
import {getFoldersGoogleDriveService, loadGoogleDriveFolderFilesService} from "../../services/drive-hr.service";
import GoogleDriveFolderIndex from "./googledrive-folder-index";
import DriveStatusFiles from "./drive-status-files";
import Unauthenticated from "../../azure_auth/Unauthenticated";
//
import {useTeamsFx} from "../../azure_auth/lib/useTeamsFx"
import {getAccessTokenOnedrive} from "../../apiCall";
import OnedriveButton from "./onedrive-button";
import WordpressButton from "./wordpress/worpress-button";
import FacebookLoginButton from './facebook-button';
import LinkedinButton from './linkedin-button';
import {useAwsGuard} from "../../guards/aws-guard";
import {useAwsPostAIGuard} from "../../guards/aws-post-ai-guard";
import {useStripeGuard} from "../../guards/stripe-guard";
import TiktokButton from './tiktok-button';
import InstagramButton from './instagram-button';
import TwitterButton from './twitter-button';
import {useSlackDatachatGuard} from "../../guards/slack-datachat-guard";
import {useTeamsPostAIGuard} from "../../guards/teams-postai-guard";
import {useTeamsDatachatGuard} from "../../guards/teams-datachat-guard";
import SharepointButton from "./sharepoint/sharepoint-button";
const IntegrationIndex = (props) => {
    const [loading, setLoading] = useState(false);
    const [showFolders, setShowFolders] = useState(false);
    const [integration, setIntegration] = useState(null);

    const [openAlert, setOpenAlert] = useState(false);
    const [filesResult, setFilesResult] = useState(null);

    const teamsContext = useTeamsFx();

    /// FOR GOOGLE DRIVE
    useEffect(() => {
        window.addEventListener('message', handlerGoogleCallbackMessage); // add event listener
        return () => {
            window.removeEventListener('message', handlerGoogleCallbackMessage); // remove event listener
        };
    }, []);

    const [gdToken, setGdToken] = useState({}); // only for development
    const [googleDriveFolders, setGoogleDriveFolders] = useState([]);

    const openGoogleOauthPopUp = (force) => {
        // open the consent screen in a pop up
        if(force)
            window.open(`${process.env.REACT_APP_LOGIN_GOOGLEDRIVE}/api/get-token?force=true&location=${window.location.origin}`, '_blank', 'width=500,height=500'); 
        else
            window.open(`${process.env.REACT_APP_LOGIN_GOOGLEDRIVE}/api/get-token?location=${window.location.origin}`, '_blank', 'width=500,height=500');
    }

    let handlerGoogleCallbackMessage = async (event) => {

        try {
            if (event.origin != `${process.env.REACT_APP_LOGIN_GOOGLEDRIVE}`) {
                return; // ignore from another origin
            }
            let data = event.data;
            console.log(data);


            let loadFolders = false;
            let newUser = false;
            if (props.user.googleAccounts) {
                if(props.user.googleAccounts?.length > 0 && data.email === props.user.googleAccounts[0].email) {
                    loadFolders = true;
                } else {
                    if(props.user.googleAccounts?.length === 0) {
                        loadFolders = true;
                        newUser = true;
                    }
                    else if(data.email != props.user.googleAccounts[0].email){
                        alert("You have to select the user with this email: "+props.user.googleAccounts[0].email)
                        openGoogleOauthPopUp(true)
                        return;
                    }
                }
            } else {
                newUser = true;
                loadFolders = true;
            }
            if (loadFolders) {
                setGdToken(data);
                setLoading(true)
                setShowFolders(true);
                setIntegration('googledrive');

                // save user and index in the DB
                if(newUser){
                    let index_info = {
                        googleAccount: {
                            id: data.email,
                            email: data.email,
                            token: data.token,
                            exp_date: data.expiry,
                        }
                    }
                    await props.updateDriveUser(index_info, props.user.id)
                }
                else{
                    let index_info = {
                        index: {
                            // collection_id: data.email,
                            collection_id: `${props.user.email}-${data.email}-googledrive`,
                            email: data.email,
                            token: data.token,
                            exp_date: data.expiry,
                        }
                    }
                    await props.updateIndexUser(index_info, props.user.id)
                }

                let folders = await getFoldersGoogleDriveService(data.token, props.user.email)
                console.log("folders: ", folders)
                setGoogleDriveFolders(folders);
                setLoading(false);

            } else {
                toastr.error('Incorrect User', `The User does not match the current integration.`);
            }
        } catch (e) {
            console.log(e)
            toastr.error(`Something went wrong, try again`);
        }
        setLoading(false);
    }

    const loadGoogleDriveFolderFiles = async (folder_id, mime_type) => {
        setLoading(true)
        try {
            //exist user and index
            // const response = await loadGoogleDriveFolderFilesService(gdToken.token, folder_id)
            const response = await loadGoogleDriveFolderFilesService(gdToken.token, folder_id, mime_type, props.user.email)
            console.log(response);
            //TODO: control user not authenticated
            let email_registed_before = false;
            for (let i = 0; i < props.user.indexList.length; i++) {
                let index = props.user.indexList[i]
                console.log(
                    index
                )
                if (index.collection_id === `${props.user.email}-${gdToken.email}-googledrive`)
                    email_registed_before = true;
            }
            if (email_registed_before == false) {
                let index_info = {
                    "googleAccount": {
                        "id": gdToken.email,
                        "email": gdToken.email,
                        "token": gdToken.token,
                        "exp_date": gdToken.expiry,
                    }
                }
                console.log(props.user);
                // const res = await addIntegrationDriveService(index_info, props.user.id);
                //console.log("new index", res);
                await props.updateDriveUser(index_info, props.user.id)
            } else {
                let index_info_update = {
                    "index": {
                        "collection_id": `${props.user.email}-${gdToken.email}-googledrive`,
                        "email": gdToken.email,
                        "token": gdToken.token,
                        "exp_date": gdToken.expiry
                    }
                }
                // const res = await updateIndexDriveService(index_info_update, props.user.id);
                // console.log('updated token', res);
                await props.updateIndexUser(index_info_update, props.user.id)
            }
            setLoading(false)
            /**NEW message, because the process runs in the background*/
            // toastr.success(`${response?.length || 0} Files have been successfully indexed`);
            toastr.success(`${response?.message}`);

            /** for the alert*/
            setOpenAlert(true);
            setFilesResult(response?.message);

            // update file list
            let folders = await getFoldersGoogleDriveService(gdToken.token, props.user.email)
            console.log('folders updated: ', folders)
            setGoogleDriveFolders(folders)
        } catch (e) {
            toastr.error(`Something went wrong, try again`);
        }
        setLoading(false);
    }

    const deleteGoogleDriveFolderFiles = async (folder_id) => {
        setLoading(true)
        try {
            //exist user and index
            // const response = await loadGoogleDriveFolderFilesService(gdToken.token, folder_id)
            const response = await deleteGoogleDriveFolderFilesService(gdToken.token, folder_id, props.user.email)
            console.log(response);
            //TODO: control user not authenticated
            setLoading(false)
            toastr.success(`${response?.status}`);

            /** for the alert*/
            setOpenAlert(true);
            setFilesResult(response?.status);

            // update file list
            let folders = await getFoldersGoogleDriveService(gdToken.token, props.user.email)
            console.log('folders updated: ', folders)
            setGoogleDriveFolders(folders)
        } catch (e) {
            toastr.error(`Something went wrong, try again`);
        }
        setLoading(false);
    }
    /////////////////////

    const loadFolderOneDrive = async (token, emailTeams="") => {
        console.log("--- data input is: ", token, emailTeams)
        let loadFolders = false;
        if (props.user.microsoftAccounts) {
            if(props.user.microsoftAccounts?.length > 0 && token.uniqueId === props.user.microsoftAccounts[0].id) {
                loadFolders = true;
            }  else {
                if(props.user.microsoftAccounts?.length === 0) {
                    loadFolders = true;
                }
            }
        } else {
            loadFolders = true;
        }
        if (loadFolders) {
            setLoading(true)
            setShowFolders(true);
            setIntegration('onedrive');
            const folders = await getFoldersOneDriveService(token.accessToken, props.user.email, token.tenantId)
            setLoading(false)
            setOneDriveFolders(folders)

            await props.updateDriveUser({
                microsoftAccount: {
                    id: token.uniqueId,
                    email: token.account? token.account.username : emailTeams,
                    token: token.accessToken,
                    exp_date: token.expiresOn.toISOString()
                }
            }, props.user.id)


            /**update new token*/
            let index_info_update = {
                "index": {
                    "collection_id": `${props.user.email}-${token.account.username}-onedrive`,
                    "email": token.account.username,
                    "token": token.accessToken,
                    "exp_date": token.expiresOn.toISOString()
                }
            }
            // const res = await updateIndexDriveService(index_info_update, props.user.id);
            // console.log('updated token', res);
            await props.updateIndexUser(index_info_update, props.user.id)
        } else {
            toastr.error('Incorrect User', `The User does not match the current integration.`);
        }
        setLoading(false);
        // setShowFolders(false);
    }

    const [oneDriveFolders, setOneDriveFolders] = useState([]);
    const [userMicrosoft, setUserMicrosoft] = useState(null);
    const [accessToken, setAccessToken] = useState("");
    const {instance, accounts} = useMsal();

        //extra function, control accounts login in teams
            const inspectMsalCache = () => {
                const storageItems = { ...localStorage };
                const msalItems = Object.keys(storageItems)
                    .filter(key => key.includes('msal'))
                    .reduce((obj, key) => {
                        try {
                            obj[key] = JSON.parse(storageItems[key]);
                        } catch (error) {
                            console.error(`Error parsing item with key ${key}:`, error);
                            toastr.error(`Something went wrong, please reload the page to try again`);
                        }
                        return obj;
                    }, {});
                console.log("MSAL Cache Items: ", msalItems);
            };
    
    
            const checkActiveAccount = () => {
                const activeAccount = msalInstanceOneDrive.getActiveAccount();
                console.log("Active Account: ", activeAccount);
            };
    
            const inspectMsalState = () => {
                const allAccounts = msalInstanceOneDrive.getAllAccounts();
                console.log("All Accounts: ", allAccounts);
                checkActiveAccount();
                inspectMsalCache();
            };

              
    const loginMicrosoft = async () => {
        let token;
        try {
            if (teamsContext.context !== undefined) {
                console.log("ONEDRIVE RAR token TEAMS POPUP: ");
                microsoftTeams.authentication.authenticate({
                    url: window.location.origin + "/auth-start-one-drive.html",
                    width: 600,
                    height: 535,
                    successCallback: async (tokenIntern) => {
                        console.log("Authenticated in Teams successfully", tokenIntern);
                        setAccessToken(tokenIntern);
                        setUserMicrosoft(tokenIntern);
                        token = tokenIntern;
                        console.log("accessToken --  : ", accessToken);
                        
                        //await authenticateAndAcquireToken()
                        
                        try {

                            const account = {
                                homeAccountId: tokenIntern.account.homeAccountId,
                                environment: tokenIntern.account.environment,
                                tenantId: tokenIntern.account.tenantId,
                                username: tokenIntern.account.username,
                                localAccountId: tokenIntern.account.localAccountId,
                                name: tokenIntern.account.name,
                            };
                            //msalInstanceOneDrive.setActiveAccount(account);
                            //msalInstanceOneDrive.ssoSilent(tokenIntern.account)
                            msalInstanceOneDrive.ssoSilent({loginHint: teamsContext.context.loginHint})
                            inspectMsalState();
                            let retries = 50;
                            while (retries > 0) {
                              const allAccounts = msalInstanceOneDrive.getAllAccounts();
                              //console.log("========       join in ",50-retries," times")
                              if (allAccounts.length > 0) break;
                              await new Promise(resolve => setTimeout(resolve, 200));
                              retries--;
                            }

                            const allAccounts = msalInstanceOneDrive.getAllAccounts();
                            const currentAccount = msalInstanceOneDrive.getAccountByUsername(tokenIntern.account.username);
                            console.log("All OneDrive Accounts: ", allAccounts, currentAccount);
            
                            const oneDriveAccount = msalInstanceOneDrive.getActiveAccount();
                            console.log("oneDriveAccount 2: ", oneDriveAccount);
                            console.log("tokenIntern: ", tokenIntern);
                            console.log("active account", oneDriveAccount);

                            const accessTokenSilent = await msalInstanceOneDrive.acquireTokenSilent({
                                ...loginRequestJWT,
                                account: currentAccount//tokenIntern.account
                            });
            
                            console.log("accessTokenSilent is new : ", accessTokenSilent);
                            //second token
                            setLoading(true);
                            setShowFolders(true);
                            const res = await storeTokenOneDriveService(accessTokenSilent.accessToken, accessTokenSilent.tenantId);
                            console.log("--==== > res is: ", res)
                            if (res === 'success') {
                                console.log("ACC tokens teams", tokenIntern, accessTokenSilent);
                                setUserMicrosoft(accessTokenSilent);
                                await loadFolderOneDrive(accessTokenSilent, tokenIntern.account?.username);
                            }
                            setLoading(false);
                        } catch (error) {
                            console.error("Failed to acquire token silently", error);
                            toastr.error(`Something went wrong, please reload the page to try again`);
                        }
                    },
                    failureCallback: (reason) => {
                        console.error("Authentication failed in Teams:", reason);
                        toastr.error(`Something went wrong, please reload the page to try again`);
                    }
                });
            }
            else {
                console.log('ONEDRIVE browser login')
                token = await msalInstanceOneDrive.loginPopup(loginRequest);
                console.log("---- > token normal is: ", token);
                const oneDriveAccount = msalInstanceOneDrive.getAllAccounts();
                const currentAccount = msalInstanceOneDrive.getAccountByUsername(token.account.username);
                console.log("normal login; test oneDriveAccount: ",oneDriveAccount , currentAccount)
                const accessTokenSilent = await msalInstanceOneDrive.acquireTokenSilent({
                    ...loginRequestJWT,
                    account: currentAccount
                })
                console.log("---- > accessTokenSilent normal is: ", accessTokenSilent);
                /// 1. save token in DB
                setLoading(true);
                setShowFolders(true);
                const res = await storeTokenOneDriveService(accessTokenSilent.accessToken, accessTokenSilent.tenantId);
                console.log("---- > res normal is: ", res);
                if (res === 'success') {
                    console.log("ACC tokens BRowser", token, accessTokenSilent);
                    setUserMicrosoft(accessTokenSilent);
                    await loadFolderOneDrive(accessTokenSilent)
                }
                setLoading(false);
            }
        } catch (e) {
            console.error("LOGIN ERROR", e)
            toastr.error(`Something went wrong, please reload the page to try again`);
        } finally{
            console.log("token finall: ", token)
            const allAccountstest = msalInstanceOneDrive.getAllAccounts();
            console.log("All OneDrive allAccountstest: ", allAccountstest);

        }
    }

    // console.log("accessToken -->  : ", accessToken)

    const loadOneDriveFolderFiles = async (folder) => {
        setLoading(true)
        try {
            const response = await loadOneDriveFolderFilesService(userMicrosoft.accessToken, folder.name, folder.id, props.user.email, userMicrosoft.tenantId)
            console.log(response);
            /**NEW message, because the process runs in the background*/
            toastr.success(`${response?.message}`);
            /*if (response.success_count) {
                toastr.success(`${response.success_count} files have been successfully indexed`);
            } else {
                toastr.info('Info', `${response.status[0].status}`);
            }*/

            const folders = await getFoldersOneDriveService(userMicrosoft.accessToken, props.user.email, userMicrosoft.tenantId)
            setOneDriveFolders(folders)
            setLoading(false);

            setTimeout(() => '', 500);

            /** for the alert*/
            setOpenAlert(true);
            // setFilesResult(response.status);
            setFilesResult(response?.message);

        } catch (e) {
            toastr.error(`Something went wrong, try again`);
        }

        setLoading(false)
    }

    const deleteOneDriveFolderFiles = async (folder, folder_id) => {
        setLoading(true)
        try {
            const response = await deleteOneDriveFolderFilesService(userMicrosoft.accessToken, folder, folder_id, props.user.email, userMicrosoft.tenantId)
            console.log(response);
            /**NEW message, because the process runs in the background*/
            toastr.success(`${response?.message}`);
            setLoading(false)

            const folders = await getFoldersOneDriveService(userMicrosoft.accessToken, props.user.email, userMicrosoft.tenantId)
            setOneDriveFolders(folders)

            /** for the alert*/
            setOpenAlert(true);
            setFilesResult(response?.message);

        } catch (e) {
            toastr.error(`Something went wrong, try again`);
        }

        setLoading(false)
    }

    const deleteOneDriveAccount = async () => {
        const onedriveId = props.user.microsoftAccounts[0].id;
        try {
            let response = await getAccessTokenOnedrive(onedriveId);
            console.log("RESRES", response)
            await deleteOneDriveAccountWithToken(response);
        }catch (e) {
            console.log("errr", e)
            toastr.warning("Warning!", "First log in again")
            let token = await msalInstanceOneDrive.loginPopup(loginRequest);
            if (token.uniqueId === props.user.microsoftAccounts[0].id) {
                let response = await getAccessTokenOnedrive(onedriveId);
                console.log("RESRES ERR", response)
                await deleteOneDriveAccountWithToken(response);
            } else {
                toastr.error("Error!", "Log in with the same account")
            }

        }
    }

    const deleteOneDriveAccountWithToken = async (response) => {
        if (window.confirm(`Are you sure you want to disconnect your OneDrive account ${props.user.microsoftAccounts[0]["email"]}?`)) {
            try {
                // const response = await deleteOneDriveAccountService(userMicrosoft.accessToken, props.user.email, userMicrosoft.tenantId)
                props.deleteDriveUser(response.accessToken, props.user.email, response.tenantId, response.uniqueId)
                // console.log(response);
                /** for the alert*/
                // setOpenAlert(true);
                // setFilesResult("Microsoft Onedrive account deleted successfully");

            } catch (e) {
                toastr.error(`Something went wrong, try again`);
            }

            // setLoading(false)
        }
    }

    const existDriveAccount =(type) =>{
        if (type === 'googledrive') {
            return props.user.googleAccounts !== null && props.user.googleAccounts?.length > 0;
        } else {
            if (type === 'onedrive') {
                return props.user.microsoftAccounts !== null && props.user.microsoftAccounts?.length > 0
            } else {
                return false;
            }
        }
    }

    /**for AWS PAYMENT*/
    const [hasAws, setHasAws] = useState(useAwsPostAIGuard())
    const [isSlackDatachatUser, setIsSlackDatachatUser] = useState(useSlackDatachatGuard())
    const [isTeamsDatachatUser, setIsTeamsDatachatUser] = useState(useTeamsDatachatGuard())
    const [isTeamsPostaiUser, setIsTeamsPostaiUser] = useState(useTeamsPostAIGuard())
    const [hasStripe, setStripe] = useState(useStripeGuard())

    return (
        <div className="container w-100 mt-3">

            {openAlert && <DriveStatusFiles response={filesResult} setOpen={setOpenAlert}/>}
            { props.user && <AuthenticatedTemplate>
            <h3>Integrations</h3>
            <div className="row">
                <div className="col-md-10 offset-md-1 my-3">
                    { (hasStripe || isSlackDatachatUser || isTeamsDatachatUser) && <div className="row mt-3">
                        <h4>Data Chat Integrations</h4>
                        <div className="row">
                            <div className="col-12 my-2 col-md-4 col-lg-3">
                                <Tooltip
                                    title={existDriveAccount('googledrive') ? props.user.googleAccounts[0]["email"] : ''} className="w-100">
                                    <Badge color="success" variant="dot" invisible={!existDriveAccount('googledrive')}>
                                        {/*<Fab variant="extended" className="w-100"
                                     onClick={() => openGoogleOauthPopUp(false)}>
                                    <img style={{width: '22%'}} src={logo} />
                                    {!existDriveAccount('googledrive') ? "Connect with Google Drive" :
                                        <small className="text-black-50"> Add folders from Google Drive </small>
                                    }
                                </Fab>*/}
                                        <Chip variant="filled"
                                              className="py-4 w-100 justify-content-between"
                                              avatar={<img style={{width: '35px', height: '35px'}} src={logo} />}
                                              label={!existDriveAccount('googledrive') ? "Connect with Google Drive" :
                                                  <small className="text-black-50"> Add more from Google Drive </small>
                                              }
                                              onClick={() => openGoogleOauthPopUp(false)}
                                              onDelete={()=> console.log("here delete google account")}
                                        />
                                    </Badge>
                                </Tooltip>
                            </div>
                            <div className="col-12 my-2 col-md-4 col-lg-3">
                                <OnedriveButton loginMicrosoft={loginMicrosoft} deleteOneDriveAccount={deleteOneDriveAccount}/>
                            </div>
                        </div>
                    </div>}
                    {(hasAws || hasStripe || isTeamsPostaiUser) && <div className="row mt-3">
                        {/*<div className="row mt-3">*/}
                        <h4>Post AI Integrations</h4>
                        <div className="col-12 my-2 col-md-4 col-lg-3">
                            <WordpressButton/>
                        </div>
                        <div className="col-12 my-2 col-md-4 col-lg-3">
                            <FacebookLoginButton/>
                        </div>
                        <div className="col-12 my-2 col-md-4 col-lg-3">
                            <LinkedinButton/>
                        </div>
                        <div className="col-12 my-2 col-md-4 col-lg-3">
                            <TiktokButton/>
                        </div>
                        <div className="col-12 my-2 col-md-4 col-lg-3">
                            <InstagramButton/>
                        </div>
                        <div className="col-12 my-2 col-md-4 col-lg-3">
                            <TwitterButton/>
                        </div>
                        <div className="col-12 my-2 col-md-4 col-lg-3">
                            <SharepointButton/>
                        </div>
                    </div>}
                </div>

                <Drawer
                    anchor="right"
                    open={showFolders}
                    onClose={() => setShowFolders(false)}
                >
                    {loading &&
                        <div className="mx-3">
                            {/*<div className="d-flex justify-content-center">
                                <RotatingSpinner/>
                            </div>
                            <p className="text-center">Loading... </p>
                            */}

                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

                            <Skeleton className="my-2" variant="rounded" width={210} height={60} />
                            <Skeleton className="my-2" variant="rounded" width={210} height={60} />
                            <Skeleton className="my-2" variant="rounded" width={210} height={60} />
                            <Skeleton className="my-2" variant="rounded" width={210} height={60} />
                            <Skeleton className="my-2" variant="rounded" width={210} height={60} />
                            <Skeleton className="my-2" variant="rounded" width={210} height={60} />

                        </div>
                    }

                    {!loading && integration === 'onedrive' &&
                        <OnedriveFolderIndex folders={oneDriveFolders} loadOnedriveFolder={loadOneDriveFolderFiles} deleteOneDriveFolderFiles={deleteOneDriveFolderFiles}/>}

                    {!loading && integration === 'googledrive' && googleDriveFolders?.length > 0 &&
                        <GoogleDriveFolderIndex folders={googleDriveFolders} loadGoogleDriveFolder={loadGoogleDriveFolderFiles} deleteGoogleDriveFolderFiles={deleteGoogleDriveFolderFiles}/>}
                </Drawer>

            </div>
            </AuthenticatedTemplate>}

            {/*<Unauthenticated/>*/}
        </div>
    )
}
const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
        status: state.users.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateDriveUser: (obj, id) => dispatch(updateDriveUserThunk(obj, id)),
        updateIndexUser: (obj, id) => dispatch(updateIndexUserThunk(obj, id)),
        deleteDriveUser: (token, masterEmail, tenantId, uniqueId) => dispatch(deleteDriveUserThunk(token, masterEmail, tenantId, uniqueId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationIndex);
