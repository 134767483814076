import {Button, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography} from "@mui/material";
import {useState} from "react";
import {Abc, AccessTime, AutoMode, SettingsSuggest} from "@mui/icons-material";

const AnswerInfo = (props) => {
    const {statistics} = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <div>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    size="small"
                    onClick={handleClick}
                >
                    <em>Time: {statistics.time}<small>s</small> <SettingsSuggest fontSize="small" className="mx-2"/> Tokens: {statistics.tokens}</em>
                </Button>
                <Menu id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                      }}
                      transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                      }}
                      MenuListProps={{
                          'aria-labelledby': 'basic-button',
                      }}>
                    <MenuList>
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <AccessTime fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Time</ListItemText>
                            <Typography variant="body2" color="text.secondary" className="ms-2">
                                {statistics.time}<small>s</small>
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <Abc fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Tokens</ListItemText>
                            <Typography variant="body2" color="text.secondary" className="ms-2">
                                {statistics.tokens}
                            </Typography>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </div>
        </>
    )
}

export default AnswerInfo
