import ContentActionTypes from "./content.types";

const INITIAL_STATE = {
    content: null,
    customeTopics: null,
    inputLanguage: 'en',
    outputLanguage: 'en',
    status: 'initial',
    form: null
};

const contentReducer = (state = INITIAL_STATE, action) => {
    console.log("PL", action);
    switch (action.type) {
        case ContentActionTypes.CHANGE_STATUS:
            return {...state, status: action.payload};
        case ContentActionTypes.GENERATE_CONTENT:
            return {...state, content: action.payload};
        case ContentActionTypes.GET_TOPICS:
            return {...state, customeTopics: action.payload};
        case ContentActionTypes.UPDATE_LANGUAGE:
            return {...state, inputLanguage: action.payload.input, outputLanguage: action.payload.output};
        case ContentActionTypes.SAVE_FORM:
            return {...state, form: action.payload};
        default:
            return state;
    }
};


export default contentReducer;
