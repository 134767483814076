import './App.css';
import './scroll.css';
import { Component } from "react";
import NavBar from "./components/layouts/NavBar";
import { Box, CircularProgress, Toolbar, Grid, IconButton, Drawer, AppBar, Typography, CssBaseline, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import AppRouting from "./app-routing";
import { MsalContext } from "@azure/msal-react";
import MenuDrawer from "./components/layouts/MenuDrawer";
import {addUserThunk, editUserThunk, getBotAccessUserThunk, getUserWithEmailThunk} from "./redux/users/users.thunk";
import { connect } from "react-redux";
import Zendesk from "react-zendesk";
import RotatingSpinner from "./components/layouts/RotatingSpinner";
import {updateCountry} from "./redux/users/users.actions";
import moment from 'moment-timezone';
import locations from 'moment-timezone/data/meta/latest.json'
import {determineLoginContext} from './azure_auth/lib/useTeamsFx';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import * as microsoftTeams from "@microsoft/teams-js";
import Unauthenticated from "./azure_auth/Unauthenticated";
import { toastr } from "react-redux-toastr";

const CustomIconButton = styled(IconButton)(({ theme }) => ({
    marginRight: theme.spacing(2),
    display: { sm: 'none' },
    backgroundColor: 'transparent',
    color: 'inherit' 
}));

const CustomMenuIcon = styled(MenuIcon)(({ theme }) => ({
    color: 'inherit', 
}));

const setting = {
    color: {
        theme: "#000"
    },
    launcher: {
        chatLabel: {
            "en-US": "Hi"
        }
    },
    contactForm: {
        fields: [
            { id: "description", prefill: { "*": "" } }
        ]
    }
};
// const teamsContext = useTeamsFx;
//const isInIframe = () => window.self !== window.top;

const isTeamsMobileApp = () => {
    return new Promise((resolve, reject) => {
        try {
            microsoftTeams.getContext((context) => {
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                const isMobile = /Teams\/\d+\.\d+\.\d+/i.test(userAgent) && /Mobile/i.test(userAgent);
                resolve(isMobile);
            });
        } catch (error) {
            console.error('Error detecting Teams mobile app:', error);
            toastr.error(`Something went wrong, please reload the page to try again`);
            resolve(false);
        }
    });
};

const isInIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

const determineLoginContext1 = () => {
    console.log("ingress in; determineLoginContext");
    return new Promise((resolve, reject) => {
        try {
            microsoftTeams.initialize(() => {
                microsoftTeams.getContext((context) => {
                    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                    const isMobile = /Teams\/\d+\.\d+\.\d+/i.test(userAgent) && /Mobile/i.test(userAgent);
                    if (context) {
                        console.log("determineLoginContext ans: teams");
                        resolve('teams');
                    } else if (isMobile) {
                        console.log("determineLoginContext ans: teams mobile");
                        resolve('teams');
                    } else if (isInIframe()) {
                        console.log("determineLoginContext ans: external");
                        resolve('external');
                    } else if (window.location.href.includes('app.mymoodbit') || window.location.href.includes('ngrok-free.app') || window.location.href.includes('localhost')) {
                        console.log("determineLoginContext ans: standard");
                        resolve('standard');
                    } else {
                        console.log("determineLoginContext ans: none");
                        resolve('none');
                    }
                });
            });
        } catch (error) {
            console.error('Error determining login context:', error);
            toastr.error(`Something went wrong, please reload the page to try again`);
            resolve('none');
        }
    });
};


class App extends Component {

    state = {
        mobileOpen: false,
        loading: false,
        open: false,
        createUser: false,
        loginContext: 'none',
        obtainingUser: false,
    };
    

    static contextType = MsalContext;

    constructor(props) {
        super(props);
        if (Intl) {
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            // const countries = moment().tz(userTimeZone);

            const idCountry = locations.zones[userTimeZone]?.countries[0];
            const country =  locations.countries[idCountry]?.name;
            console.log('TIME ZONE', userTimeZone, locations.countries, locations.zones, idCountry, locations.countries[idCountry]?.name)
            this.props.updateCountry(country);
            // localStorage.setItem('gtp-country', country);
        }
    }

    async componentDidMount() {
        console.warn('APP Mount', this.props);
        console.log("ACCOUNTS Mount: ", this.context.accounts)
        const isSmallScreen = this.props.width === 'xs' || this.props.width === 'sm';
        console.log("isSmallScreen: ", isSmallScreen);
        this.setState({ 
            loading: false,
            mobileOpen: false,
            open: false
        }, () => {
            console.log("Initial mobileOpen state: ", this.state.mobileOpen);
            console.log("Initial open state: ", this.state.open);
        });
        /*if (this.context.accounts.length > 0 && !this.props.user) {
            const email = this.context.accounts[0].idTokenClaims.email;
            await this.props.getUser(email);
            console.log('APP 2', this.props);
            if (this.statusCreate !== 'loading' && !this.props.user) {
                console.log("add new user");
                const account = this.context.accounts[0];
                await this.props.addUser({
                    // id: account.localAccountId,
                    email: account.idTokenClaims.email,
                    firstName: account.idTokenClaims.extension_firstName,
                    lastName: account.idTokenClaims.extension_lastName,
                    tenantId: account.tenantId
                });
            } else {
                console.log("user exists")
            }
            console.log('USER: ', this.props.user);
        }*/
        const context = determineLoginContext(this.props.teamsContext)
        this.setState({ loginContext: context });
        
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    async componentDidUpdate() {
        console.warn('APP 1', this.props);
        console.log("ACCOUNTS: ", this.context.accounts)
        if (!this.state.obtainingUser && this.context.accounts.length > 0 && !this.props.user) {
            const email = this.context.accounts[0].idTokenClaims?.email;
            this.setState({ obtainingUser: true });
            /**Get user bot permissions*/
            this.props.getBotAccessUser(email);
            /**Get user info*/
            await this.props.getUser(email);
            console.log('APP 2', this.props);
            if (!this.state.createUser && this.statusCreate !== 'loading' && !this.props.user) {
                this.setState({ createUser: true });
                console.log("add new user");
                const account = this.context.accounts[0];
                await this.props.addUser({
                    // id: account.localAccountId,
                    email: account.idTokenClaims?.email,
                    firstName: account.idTokenClaims?.extension_firstName,
                    lastName: account.idTokenClaims?.extension_lastName,
                    tenantId: account.tenantId
                });
            } else {
                if (this.props.user.tenantId != this.context.accounts[0].tenantId) {
                    console.warn("NEW TENANT DETECTED!", this.props.user.tenantId, this.context.accounts[0].tenantId)
                    if (this.props.user.microsoftTenantId == null) {
                        console.warn("update the user");
                        const account = this.context.accounts[0];

                        await this.props.editUser({
                            // id: account.localAccountId,
                            email: account.idTokenClaims?.email,
                            firstName: account.idTokenClaims?.extension_firstName,
                            lastName: account.idTokenClaims?.extension_lastName,
                            tenantId: account.tenantId
                        }, this.props.user.id);
                    }
                }
                console.log("user exists")
            }
            console.log('USER: ', this.props.user);
        }
    }
    /* state = {
        mobileOpen: false,
        loading: false,
    }; */

    handleDrawerToggle = () => {
        this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));
    };



    render() {
        /*const { mobileOpen, loading, loginContext } = this.state;
        const isSmallScreen = () => {
            return window.innerWidth <= 600;
        };*/
        
        console.log("CONTEXT ", this.context);
        const loginContext = determineLoginContext(this.props.teamsContext);
        const drawer = (
            <div>
                <Toolbar />
                <List>
                    <ListItem button>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary="About" />
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <ContactMailIcon />
                        </ListItemIcon>
                        <ListItemText primary="Contact" />
                    </ListItem>
                </List>
            </div>
        );
        const drawerWidth = 240;
        return (
            <div className="vh-100 bg-light-2">
                {this.context.accounts?.length === 0 || this.props.user ?
                    <Box>
                        <NavBar handleDrawerOpen={this.handleDrawerOpen} handleDrawerClose={this.handleDrawerClose}
                                loginContext={loginContext}
                            open={loginContext !== 'standard' ? true : this.state.open} />

                        <div className="w-100 d-flex">
                            {this.context.accounts?.length > 0 ?
                                <div>
                                    <MenuDrawer handleDrawerOpen={this.handleDrawerOpen}
                                        handleDrawerClose={this.handleDrawerClose} open={loginContext !== 'standard' ? true : this.state.open}
                                        smallOpen={this.state.open}
                                        user={this.props.user} />
                                </div>
                                : null}
                            <Box component="main" sx={{ flexGrow: 1, p: {xs: 0, sm: 0}}} className="w-100 vh-100 position-relative">
                                <Toolbar />
                                {this.state.loading ?
                                    <div className="text-center col-12">
                                        <p>{this.props.t('loading')}...</p>
                                        <RotatingSpinner/>
                                    </div>
                                    :
                                    <div>
                                        <AppRouting open={this.state.open}/>
                                        <Unauthenticated/>
                                        <Zendesk defer zendeskKey={process.env.REACT_APP_ZENDESK_KEY} {...setting}/>
                                    </div>
                                }
                            </Box>
                        </div>
                    </Box>
                    : <div className="text-center w-100 pt-5">
                        <p>{this.props.t('loading')}...</p>
                        <RotatingSpinner/>
                    </div>}
            </div>
        );
        {/* else {
            return (
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        sx={{
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                    >


                <Toolbar
                    sx={{ 
                        backgroundColor: 'white', 
                        color: 'black' 
                    }}
                >
                    {isSmallScreen() && (
                        <CustomIconButton
                            aria-label="open drawer"
                            edge="start"
                            onClick={this.handleDrawerToggle}
                            sx={{ backgroundColor: 'transparent', color: 'inherit' }}
                        >
                            <CustomMenuIcon />
                        </CustomIconButton>
                    )}
                    <NavBar 
                        handleDrawerOpen={this.handleDrawerOpen} 
                        handleDrawerClose={this.handleDrawerClose}
                        open={this.state.open} 
                    />
                </Toolbar>





                    </AppBar>
                    {this.context.accounts?.length > 0 ?
                    <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={this.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, 
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        <MenuDrawer 
                            handleDrawerOpen={this.handleDrawerOpen}
                            handleDrawerClose={this.handleDrawerClose} 
                            open={this.state.open}
                            user={this.props.user} 
                        />
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        <MenuDrawer 
                            handleDrawerOpen={this.handleDrawerOpen}
                            handleDrawerClose={this.handleDrawerClose} 
                            open={true} 
                            user={this.props.user} 
                        />
                    </Drawer>
                </Box>:null}
                    
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            p: 3,
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            mt: { xs: 7, sm: 0 },
                        }}
                    >
                        <Toolbar />
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                <p>{this.props.t('loading')}...</p>
                                <RotatingSpinner />
                            </Box>
                        ) : (
                            <Box sx={{ flexGrow: 1 }}>
                                <AppRouting open={this.state.open} />
                                <Zendesk defer zendeskKey={this.props.zendeskKey} {...setting} />
                            </Box>
                        )}
                    </Box>
                </Box>
            );

        */}
        
    }
}

const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
        status: state.users.status,
        statusCreate: state.users.statusCreate
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (email) => dispatch(getUserWithEmailThunk(email)),
        addUser: (obj) => dispatch(addUserThunk(obj)),
        editUser: (obj, id) => dispatch(editUserThunk(obj, id)),
        getBotAccessUser: (email) => dispatch(getBotAccessUserThunk(email)),

        updateCountry: (country) => dispatch(updateCountry(country))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
