import React, {Component} from "react";
import {Chip, Stack, Typography} from "@mui/material";
import {AccessTime, EventRounded} from "@mui/icons-material";
import * as moment from "moment";


class DisplaySchedule extends Component {
  
    // componentDidMount (){
    //     this.getDaysOfTheWeek(this.props.schedule-slack);
    // }

    getDaysOfTheWeek = (schedule) => {

        let days = {
            0: "Sun",
            1: "Mon",
            2: "Tue",
            3: "Wed",
            4: "Thur",
            5: "Fri",
            6: "Sat"
        }

        let daysString = [];

        for (let i = 0; i < schedule.days.length; i++) {

            console.log("loop");
            if (schedule.days[i] === true) {
                daysString.push(days[i]);
            }
        }
        return daysString;
    }

    constructTime = (hours, minutes) => {


        let prefix;
        if (hours >= 12) {
            prefix = "p.m"
        } else {
            prefix = "a.m"
        }
        let myHours = ((hours + 11) % 12 + 1);

        let myMinutes = minutes;
        if (minutes < 10) {

            myMinutes = "0" + myMinutes;

        }

        return myHours + ":" + myMinutes + " " + prefix;

    }

    nextPublishDate() {
        const createDate = this.props.schedule.createdAt
        const dayWeek = this.props.schedule.days.findIndex(value => value === true);
        const hour = this.props.schedule.hours;
        const minute = this.props.schedule.minutes;
        const dateSchedule = this.props.schedule.date;

        let date;
        if (dateSchedule)
            date = moment(dateSchedule);
        else {
            date = moment(createDate);
            const dayWeekCreation = date.weekday();

            if (dayWeek >= dayWeekCreation) {
                date.weekday(dayWeek);
            } else {
                date.add(1, 'week').weekday(dayWeek);
            }

        }
        date.set({
            hour: hour,
            minute: minute,
            second: 0,
            millisecond: 0
        });

        // return date.format('YYYY-MM-DD HH:mm:ss');
        return date.format('ddd, D MMM YYYY')
    }


    render() {

        let days = this.getDaysOfTheWeek(this.props.schedule);
        let time = this.constructTime(this.props.schedule.hours, this.props.schedule.minutes);

        return (
            <div>
                <div className="text-black-50 mb-2">
                    <AccessTime />
                    {/*<Stack direction="row" className="flex-wrap">
                        {days.map((day, index) => <Chip label={day} key={index} icon={<EventRounded/>} color="info" size="small" className="mb-1 ms-1"/>)}
                    </Stack>*/}
                    {/*<Stack direction="row" className="flex-wrap">
                        <Chip icon={<EventRounded/>} label={} color="info" size="small" className="mb-1 ms-1"/>
                    </Stack>*/}
                    <Typography className="text-info ms-2 d-inline">
                        <b><small><span className="fw-bold">{this.nextPublishDate()}</span> at <span className="fw-bold">{time}</span> ({this.props.schedule.timeZone || 'GMT'})</small></b>
                    </Typography>
                </div>
            </div>
        )
    }
}

export default DisplaySchedule;
