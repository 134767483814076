import {connect} from "react-redux";
import {Badge, Chip, CircularProgress, Tooltip} from "@mui/material";
import {Cloud} from "@mui/icons-material";
const CustomChip = (props) => {
    return (<Chip variant="filled"
                  disabled={props.status === 'loading'}
                  icon={props.status === 'loading' ? <CircularProgress size={20}/> : <Cloud color="info" fontSize="large" sx={{mr: 1}}/>}
                  label="ONEDRIVE"
                  {...props}/>)
}

const OnedriveButton = (props) => {
    const {loginMicrosoft, deleteOneDriveAccount} = props;

    const existDriveAccount = () => {
        return props.user.microsoftAccounts !== null && props.user.microsoftAccounts?.length > 0
    }

    return (
        <Tooltip
            title={existDriveAccount() ? props.user.microsoftAccounts[0]["email"] : ''} className="w-100">
            <Badge color="success" variant="dot" invisible={!existDriveAccount()}>
                {existDriveAccount() ?
                    <CustomChip label={<small className="text-black-50">Add more folders</small>}
                                className="py-4 w-100 justify-content-between"
                                onClick={() => loginMicrosoft()}
                                onDelete={() => deleteOneDriveAccount()}
                                status={props.status}
                /> : <CustomChip className="py-4 w-100"
                                 onClick={() => loginMicrosoft()}
                                 status={props.status}
                    />}
            </Badge>
        </Tooltip>)
}


const mapStateToProps = (state) => {
    return {
        user: state.users.user,
        status: state.users.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OnedriveButton);
