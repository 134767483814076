import {
    Autocomplete,
    Box,
    darken,
    lighten, List,
    ListItem,
    ListItemText,
    ListSubheader,
    styled,
    TextField
} from "@mui/material";
import {useField, useFormikContext} from "formik";

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.secondary,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const AutocompleteFieldWrapperList = (props) => {
    const {name, options, labelValue, idValue, setItem, ...otherProps} = props;
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (e, newValue) => {
        setFieldValue(name, newValue);
        //console.log('1. paso')
        // if (setItem) setItem(newValue);
        //setFieldValue(name, newValue[idValue]);
        //console.log(field);
        setFieldValue(name, newValue);
        if (otherProps.updateState) {
            otherProps.updateState(newValue);
        }
    };

    const configSelect = {
        ...field,
        ...otherProps,
        value: null,
        fullWidth: true,
        onChange: handleChange
    }

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    return (
        <Autocomplete
            disablePortal
            options={options}
            renderOption={(props, option) => (
                <Box {...props}>
                    <div className="w-100">
                    <p><b>{option[labelValue]}</b></p>
                        <ul>
                        {option.children.map((item, index) => (
                            item.label ? <li key={`item-topic-${index}`} className="">
                                {item.label}
                            </li> : null
                        ))}
                            {option.describe ? <li>
                                    {option.describe}
                                </li> : null}
                        </ul>
                    </div>
                </Box>
            )}
            autoHighlight
            {...configSelect}
            getOptionLabel={(option) => option[labelValue] ? option[labelValue] : ''}
            freeSolo
            renderInput={
                (params) =>
                    <TextField {...configSelect} {...params} label={otherProps.label}/>}
        />
    )
}

export default AutocompleteFieldWrapperList;
