import {MenuItem, TextField} from "@mui/material";
import {useField, useFormikContext} from "formik";
import {connect} from "react-redux";

const SelectFieldFacebookOrganizationsWrapper = (props) => {
    const {name, options, labelValue, idValue, simple, ...otherProps} = props;
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);


    const handleChange = (e) => {
        const {value} = e.target;
        console.log(value);
        setFieldValue(name, value);
        if (otherProps.updateState) {
            otherProps.updateState(value);
        }
    };

    const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        variant: 'outlined',
        fullWidth: true,
        onChange: handleChange
    }

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    const fetchFacebookOrganizations = () => {
        return JSON.parse(localStorage.getItem('facebookPages')) || [];
    };

    return (
        <TextField {...configSelect}>
            {fetchFacebookOrganizations()?.map((item, pos) => {
                return (
                    <MenuItem key={item.id} value={item.id}>
                        { item.name}
                    </MenuItem>
                )
            })}
        </TextField>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.users.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectFieldFacebookOrganizationsWrapper);
