import {Text} from "@fluentui/react";
import {Delete24Regular} from "@fluentui/react-icons";

import styles from "./clear-chat-button.module.css";
import {Button, Tooltip} from "@mui/material";

export const ClearChatButton = ({className, disabled, onClick}) => {
    return (
        <Tooltip title="Clear chat">
            <Button variant="contained"
                    color="error"
                    className={`${disabled && styles.disabled}`} onClick={onClick}>
                <Delete24Regular/>
            </Button>
        </Tooltip>
    );
};
