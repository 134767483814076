import {connect, useSelector} from "react-redux";
import {determineLoginContext, useTeamsFx} from "../azure_auth/lib/useTeamsFx";

export const useTeamsGuard = (route=null) => {
    // const user = useSelector(state => state.users.user)
    const teamsContext = useTeamsFx();
    // const awsSubscription = user?.all_aws_live_subscriptions;
    // console.log("AWS AWS", awsSubscription)

    return determineLoginContext(teamsContext) === 'standard';
}
