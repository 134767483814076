export const defaultTopics = [
    {
        topic: "Company updates and announcements: This includes important information such as financial performance, new initiatives, and changes in leadership or strategy.",
        groupName: 'Default Topics'
    },
    {
        topic: "Employee engagement and culture: This includes efforts to build a positive and inclusive work environment, as well as initiatives to promote employee well-being and satisfaction.",
        groupName: 'Default Topics'
    },
    {
        topic: "Professional development and training: This includes opportunities for employees to learn and grow, such as workshops, seminars, and mentorship programs.",
        groupName: 'Default Topics'
    },
    {
        topic: "Policies and procedures: This includes information about company policies, procedures and protocols, and how they apply to employees' day-to-day work.",
        groupName: 'Default Topics'
    },
    {
        topic: "Communication channels and technology: This includes information about the various communication channels available to employees, such as email, intranet, and instant messaging, as well as any technology-related updates or changes.",
        groupName: 'Default Topics'
    },
    {
        topic: "Employee recognition and rewards: This includes information about employee recognition programs, rewards, and other ways of showing appreciation for a job well done.",
        groupName: 'Default Topics'
    },
    {
        topic: "Crisis and emergency communication: This includes communication related to unexpected events, such as natural disasters, security threats, and other crises that may impact the organization and its employees.",
        groupName: 'Default Topics'
    },
]
export const tones = [
    {
        name: 'Friendly'
    },
    /*{
        name: "Luxury"
    },
    {
        name: "Relaxed"
    },*/
    {
        name: 'Professional'
    },
    /*{
        name: "Bold"
    },
    {
        name: "Adventurous"
    },
    {
        name: "Witty"
    },*/
    {
        name: 'Persuasive'
    },
    {
        name: 'Empathetic'
    }
];


export const platforms = [
    {
        platform: 'Newsletter',
        idPlatform: 'Newsletter'
    },
    {
        platform: 'Email',
        idPlatform: 'Email'
    },
    {
        platform: 'Intranet',
        idPlatform: 'Intranet'
    },
    {
        platform: 'DigitalSignage',
        idPlatform: 'Digital Signage'
    },
    {
        platform: 'SocialMedia',
        idPlatform: 'Social Media'
    },
    {
        platform: 'MessagingApp',
        idPlatform: 'Messaging App'
    },
    {
        platform: 'Blog',
        idPlatform: 'Blog'
    },
    {
        platform: 'Other',
        idPlatform: 'Other'
    },
]

export const recipients = [
    {
        recipient: 'Company Updates & Announcements',
        children: [
            {
                subrecipient: 'Company updates',
            },
            {
                subrecipient: 'Performance or progress',
            },
            {
                subrecipient: 'Announcements',
            },
            {
                subrecipient: 'Strategy overview',
            },
            {
                subrecipient: 'Company-wide accolades, awards, or recognition'
            }
        ]
    },
    {
        recipient: 'HR Comms',
        children: [
            {
                subrecipient: 'Promotion: event, activity, or initiative'
            },
            {
                subrecipient: 'Participation for a survey'
            },
            {
                subrecipient: 'Payroll calendar'
            },
            {
                subrecipient: 'Benefits'
            },
            {
                subrecipient: 'Social Sharing (birthdays, new hires, engagement, condolences, new borns)'
            },
            {
                subrecipient: 'Onboarding communication'
            },
            {
                subrecipient: 'Health & Safety'
            },
            {
                subrecipient: 'Rewards, recognition and encouragement'
            },
            {
                subrecipient: 'Remote work and flexibility'
            },
            {
                subrecipient: 'Diversity, Equity & Inclusion (DEI)'
            },
            {
                subrecipient: 'Employee training and development'
            },
            {
                subrecipient: 'Work-life balance'
            },
            {
                subrecipient: 'Collaboration and teamwork'
            },
        ]
    },
    {
        recipient: 'Information comms',
        children: [
            {
                subrecipient: 'Policies and procedures'
            },
            {
                subrecipient: 'Legal or compliance information'
            },
            {
                subrecipient: '‘How to’ -  business processes'
            },
            {
                subrecipient: 'Organizational information'
            },
            {
                subrecipient: 'Training tools and content'
            },
            {
                subrecipient: 'Customer Service'
            },
            {
                subrecipient: 'Procurement, Supply chain and logistics'
            },
            {
                subrecipient: 'Performance management and feedback'
            },
            {
                subrecipient: 'Data privacy and security'
            },
        ]
    },
    {
        recipient: 'Change communications',
        children: [
            {
                subrecipient: 'Structural business change'
            },
            {
                subrecipient: 'Rebranding'
            },
            {
                subrecipient: 'Office openings, moves, or closures'
            },
            {
                subrecipient: 'New technology or service'
            },
            {
                subrecipient: 'Regulatory or compliance changes'
            },
            {
                subrecipient: 'Political, economic or social change,'
            },
        ]
    },
    {
        recipient: 'Culture Communication',
        children: [
            {
                subrecipient: 'Company mission and values'
            },
            {
                subrecipient: 'Onboarding communication'
            },
            {
                subrecipient: 'Corporate Social Responsability (CSR)'
            },
            {
                subrecipient: 'Leadership Communication'
            },
            {
                subrecipient: 'Rewards, recognition and encouragement'
            },
            {
                subrecipient: 'Social Sharing (birthdays, new hires, engagement, condolences, new borns)'
            },
            {
                subrecipient: 'Company history and heritage'
            },
            {
                subrecipient: 'Sustainability and environmental impact'
            },
        ]
    },
    {
        recipient: 'Crisis communication',
        children: [
            {
                subrecipient: 'Natural disasters'
            },
            {
                subrecipient: 'Technological crisis'
            },
            {
                subrecipient: 'Environmental crisis'
            },
            {
                subrecipient: 'Employee or management misconduct'
            },
            {
                subrecipient: 'Leak of internal information'
            },
            {
                subrecipient: 'Product failings, faults, or recalls'
            },
            {
                subrecipient: 'An external threat'
            },
            {
                subrecipient: 'Conflict with interest groups'
            },
            {
                subrecipient: 'Financial crises'
            },
            {
                subrecipient: 'Organizational misdeeds'
            },
            {
                subrecipient: 'Process disruption'
            },
        ]
    }
]

export const testRecipients = [
    {
        recipient: 'Company updates and announcements',
        translate: 'category.one',
        describe: 'This includes important information such as financial performance, new initiatives, and changes in leadership or strategy',
        children: [
            {
                subrecipient: 'New Product or Service Launch',
                translate: 'category.oneOne',
                describe: 'Announcement about the launch of a new product or service, with details on features, benefits, and availability.'
            },
            {
                subrecipient: 'Company Milestones',
                translate: 'category.oneTwo',
                describe: 'Celebrating major achievements, such as reaching a revenue milestone, expanding operations to new markets, or winning a major award.'
            },
            {
                subrecipient: 'Partnership and Collaboration',
                translate: 'category.oneThree',
                describe: 'Announcement about new partnerships or collaborations with other companies or organizations.'
            },
            {
                subrecipient: 'Leadership Changes',
                translate: 'category.oneFour',
                describe: "Announcement about changes in the company's leadership, such as new hires, promotions, or departures."
            },
            {
                subrecipient: 'Financial Performance',
                translate: 'category.oneFive',
                describe: "Reporting on the company's financial performance, including quarterly or annual earnings reports, revenue growth, and other financial metrics."
            },
            {
                subrecipient: 'Corporate Social Responsibility',
                translate: 'category.oneSix',
                describe: "Updates on the company's efforts to make a positive impact in the community, including environmental sustainability initiatives, charitable donations, and employee volunteer programs."
            },
            {
                subrecipient: 'Industry Trends and Insights',
                translate: 'category.oneSeven',
                describe: "Commentary on trends and developments in the company's industry, along with insights into how the company is adapting to changes in the market."
            }
        ]
    },
    {
        recipient: 'Employee engagement and culture',
        translate: 'category.two',
        describe: 'This includes efforts to build a positive and inclusive work environment, as well as initiatives to promote employee well-being and satisfaction.',
        children: [
            {
                subrecipient: 'Leadership and management',
                translate: 'category.twoOne',
                describe: "This includes the leadership and management style of the organization, including communication, decision-making, and leadership development."
            },
            {
                subrecipient: 'Learning and development',
                translate: 'category.twoTwo',
                describe: "This includes employee training and development programs, mentorship, coaching, and career growth opportunities."
            },
            {
                subrecipient: 'Workplace diversity and inclusion',
                translate: 'category.twoThree',
                describe: "This includes promoting a diverse and inclusive workplace, including equal opportunity, fairness, and respect for all employees."
            },
            {
                subrecipient: 'Employee feedback and communication',
                translate: 'category.twoFour',
                describe: "This includes feedback mechanisms, such as employee surveys, focus groups, and suggestion boxes, as well as communication channels to promote transparency and open communication."
            },
            {
                subrecipient: 'Workplace environment and amenities',
                translate: 'category.twoFive',
                describe: "This includes the physical workplace environment, such as office layout, lighting, and noise levels, as well as amenities like break rooms, fitness facilities, and food options."
            },
        ]
    },
    {
        recipient: 'Professional development and training',
        translate: 'category.three',
        describe: 'This includes opportunities for employees to learn and grow, such as workshops, seminars, and mentorship programs.',
        children: [
            {
                subrecipient: 'Skills Training',
                translate: 'category.threeOne',
                describe: "Providing training programs to improve employees' skills and knowledge related to their job, including technical skills, software tools, communication skills, and other job-specific skills."
            },
            {
                subrecipient: 'Leadership Training',
                translate: 'category.threeTwo',
                describe: "Offering training programs for employees to develop their leadership skills, such as effective communication, conflict resolution, and decision-making."
            },
            {
                subrecipient: 'Management Training',
                translate: 'category.threeThree',
                describe: "Providing training programs for managers and supervisors to improve their leadership and management skills, including employee coaching and development, performance management, and team-building."
            },
            {
                subrecipient: 'Professional Certification',
                translate: 'category.threeFour',
                describe: "Supporting employees to attain professional certifications and credentials that are relevant to their job or industry, such as PMP, CPA, or CFA."
            },
            {
                subrecipient: 'Soft Skills Training',
                translate: 'category.threeFive',
                describe: "Providing training programs to improve employees' soft skills, such as teamwork, problem-solving, time management, and interpersonal communication."
            },
            {
                subrecipient: 'Executive Coaching',
                translate: 'category.threeSix',
                describe: "Offering one-on-one coaching and mentoring for executives and senior leaders to improve their leadership skills, decision-making, and strategic thinking."
            },
        ]
    },
    {
        recipient: "Policies and procedures",
        translate: 'category.four',
        describe: "This includes information about company policies, procedures and protocols, and how they apply to employees' day-to-day work.",
        children: [
            {
                subrecipient: 'Operational Policies and Procedures',
                translate: 'category.fourOne',
                describe: "These are policies and procedures that govern day-to-day operations in an organization, such as how to perform certain tasks or how to respond to specific situations."
            },
            {
                subrecipient: 'HR Policies and Procedures',
                translate: 'category.fourTwo',
                describe: "These are policies and procedures that relate to the management of employees, including recruitment, performance management, disciplinary procedures, and termination."
            },
            {
                subrecipient: 'Financial Policies and Procedures',
                translate: 'category.fourThree',
                describe: "These are policies and procedures that relate to the management of financial resources, including budgeting, accounting, and procurement."
            },
            {
                subrecipient: 'Information Technology (IT) Policies and Procedures',
                translate: 'category.fourFour',
                describe: "These are policies and procedures that relate to the management of an organization's IT systems, including security, data backup, and disaster recovery."
            },
            {
                subrecipient: 'Health and Safety Policies and Procedures',
                translate: 'category.fourFive',
                describe: "These are policies and procedures that relate to the health and safety of employees and customers, including workplace safety, emergency response, and first aid procedures."
            },
            {
                subrecipient: 'Legal and Regulatory Policies and Procedures',
                translate: 'category.fourSix',
                describe: "These are policies and procedures that relate to compliance with legal and regulatory requirements, including data protection, anti-corruption, and environmental regulations."
            },
            {
                subrecipient: 'Quality Assurance Policies and Procedures',
                translate: 'category.fourSeven',
                describe: "These are policies and procedures that relate to ensuring that products or services meet quality standards and customer expectations."
            },
        ]
    },
    {
        recipient: 'Communication channels and technology',
        translate: 'category.five',
        describe: 'This includes information about the various communication channels available to employees, such as email, intranet, and instant messaging, as well as any technology-related updates or changes.',
        children: [
            {
                subrecipient: 'Email',
                translate: 'category.fiveOne',
                describe: "This includes using email as a primary communication channel, setting guidelines for email etiquette, and managing email overload."
            },
            {
                subrecipient: 'Instant messaging',
                translate: 'category.fiveTwo',
                describe: "This includes using instant messaging tools such as Slack or Microsoft Teams for real-time communication, setting guidelines for proper use, and managing distractions."
            },
            {
                subrecipient: 'Video conferencing',
                translate: 'category.fiveThree',
                describe: "This includes using video conferencing tools such as Zoom or Google Meet for remote meetings, setting guidelines for scheduling and etiquette, and ensuring technical support is available."
            },
            {
                subrecipient: 'Social media',
                translate: 'category.fiveFour',
                describe: "This includes using social media channels such as LinkedIn or Twitter for business communication, setting guidelines for appropriate use, and managing privacy and security concerns."
            },
            {
                subrecipient: 'Intranet and collaboration platforms',
                translate: 'category.fiveFive',
                describe: "This includes using intranet portals and collaboration platforms such as SharePoint or Confluence for sharing information and collaborating on projects, setting guidelines for usage, and ensuring accessibility."
            },
            {
                subrecipient: 'Mobile communication',
                translate: 'category.fiveSix',
                describe: "This includes using mobile devices for communication, such as texting or mobile apps, setting guidelines for appropriate use, and ensuring security and privacy."
            },
            {
                subrecipient: 'Virtual reality and augmented reality',
                translate: 'category.fiveSeven',
                describe: "This includes using virtual reality or augmented reality technologies for remote collaboration, training, or customer engagement, setting guidelines for usage, and ensuring accessibility and technical support."
            },
        ]
    },
    {
        recipient: 'Employee recognition and rewards',
        translate: 'category.six',
        describe: 'This includes information about employee recognition programs, rewards, and other ways of showing appreciation for a job well done.',
        children: [
            {
                subrecipient: 'Monetary rewards',
                translate: 'category.sixOne',
                describe: "These are rewards that involve financial compensation, such as bonuses, commissions, profit-sharing, and stock options."
            },
            {
                subrecipient: 'Non-monetary rewards',
                translate: 'category.sixTwo',
                describe: "These are rewards that do not involve financial compensation, such as recognition programs, flexible work schedules, extra vacation days, and training opportunities."
            },
            {
                subrecipient: 'Performance-based rewards',
                translate: 'category.sixThree',
                describe: "These are rewards that are tied to an employee's performance, such as sales goals, customer satisfaction, productivity, and quality of work."
            },
            {
                subrecipient: 'Behavior-based rewards',
                translate: 'category.sixFour',
                describe: "These are rewards that are tied to an employee's behavior, such as teamwork, leadership, innovation, and problem-solving."
            },
            {
                subrecipient: 'Long-term rewards',
                translate: 'category.sixFive',
                describe: "These are rewards that are given over a longer period, such as retirement plans, sabbaticals, and career development programs."
            },
            {
                subrecipient: 'Immediate rewards',
                translate: 'category.sixSix',
                describe: "These are rewards that are given immediately, such as gift cards, vouchers, and certificates of achievement."
            },
            {
                subrecipient: 'Recognition programs',
                translate: 'category.sixSeven',
                describe: "These are programs designed to acknowledge employees for their achievements and contributions, such as employee of the month, peer recognition, and customer service awards."
            },
            {
                subrecipient: 'Wellness and health-based rewards',
                translate: 'category.sixEight',
                describe: "These are rewards designed to promote employee wellness and health, such as gym memberships, healthy snacks, and wellness programs."
            },
        ]
    },
    {
        recipient: 'Crisis and emergency communication',
        translate: 'category.seven',
        describe: 'This includes communication related to unexpected events, such as natural disasters, security threats, and other crises that may impact the organization and its employees.',
        children: [
            {
                subrecipient: 'Natural disasters',
                translate: 'category.sevenOne',
                describe: "This includes communication related to natural disasters such as hurricanes, tornadoes, earthquakes, and floods."
            },
            {
                subrecipient: 'Workplace accidents and incidents',
                translate: 'category.sevenTwo',
                describe: "This includes communication related to workplace accidents such as fires, chemical spills, and machinery accidents."
            },
            {
                subrecipient: 'Public health emergencies',
                translate: 'category.sevenThree',
                describe: "This includes communication related to public health emergencies such as pandemics, disease outbreaks, and bioterrorism threats."
            },
            {
                subrecipient: 'Security threats',
                translate: 'category.sevenFour',
                describe: "This includes communication related to security threats such as terrorism, active shooters, and cyber attacks."
            },
            {
                subrecipient: 'Product recalls',
                translate: 'category.sevenFive',
                describe: "This includes communication related to product recalls, such as food safety issues, automobile defects, and toy safety."
            },
            {
                subrecipient: 'Reputation management',
                translate: 'category.sevenSix',
                describe: "This includes communication related to managing the reputation of an organization during a crisis or emergency, such as a data breach or scandal."
            },
            {
                subrecipient: 'Crisis preparedness',
                translate: 'category.sevenSeven',
                describe: "This includes communication related to preparing for potential crises or emergencies, such as developing emergency response plans, conducting drills, and training employees."
            },
            {
                subrecipient: 'Crisis recovery',
                translate: 'category.sevenEight',
                describe: "This includes communication related to recovering from a crisis or emergency, such as providing updates, addressing concerns, and rebuilding trust with stakeholders."
            },
        ]
    },
    {
        recipient: 'Health and safety',
        translate: 'category.eight',
        describe: 'This includes information about company policies and procedures related to health and safety, as well as any updates or changes in these areas.',
        children: [
            {
                subrecipient: 'Occupational health and safety',
                translate: 'category.eightOne',
                describe: "This includes ensuring that the workplace is safe and healthy for employees, and protecting workers from hazards such as physical, chemical, biological, and psychological risks"
            },
            {
                subrecipient: 'Workplace ergonomics',
                translate: 'category.eightTwo',
                describe: "This includes ensuring that the workplace is designed to minimize the risk of musculoskeletal disorders, such as back pain, carpal tunnel syndrome, and tendonitis"
            },
            {
                subrecipient: 'Environmental health and safety',
                translate: 'category.eightThree',
                describe: "This includes ensuring that the workplace does not pose a risk to the environment, such as preventing pollution, managing hazardous waste, and complying with environmental regulations"
            },
            {
                subrecipient: 'Industrial hygiene',
                translate: 'category.eightFour',
                describe: "This includes assessing and managing workplace exposure to hazardous substances such as chemicals, dust, and noise"
            },
            {
                subrecipient: 'Hazard communication',
                translate: 'category.eightFive',
                describe: "This includes ensuring that employees are informed about hazardous materials and chemicals in the workplace, such as through safety data sheets and labeling requirements"
            },
            {
                subrecipient: 'Fire safety',
                translate: 'category.eightSix',
                describe: "This includes ensuring that the workplace is prepared for fire emergencies, such as through fire drills, fire suppression equipment, and emergency exit plans"
            },
            {
                subrecipient: 'Personal protective equipment (PPE)',
                translate: 'category.eightSeven',
                describe: "This includes ensuring that employees have access to and are trained on the use of PPE, such as safety glasses, gloves, hard hats, and respirators"
            },
            {
                subrecipient: 'Infectious disease control',
                translate: 'category.eightEight',
                describe: "This includes ensuring that the workplace is prepared for outbreaks of infectious diseases, such as through vaccination programs, hand hygiene, and infection control measures"
            },
        ]
    },
    {
        recipient: 'Diversity, equity, and inclusion',
        translate: 'category.nine',
        describe: 'This includes communication related to creating a more diverse, equitable, and inclusive workplace, as well as efforts to address any challenges in these areas.',
        children: [
            {
                subrecipient: 'Gender equity',
                translate: 'category.nineOne',
                describe: "This includes ensuring equal treatment and opportunities for people of all genders, addressing issues such as pay equity, parental leave, and workplace harassment and discrimination"
            },
            {
                subrecipient: 'Racial and ethnic equity',
                translate: 'category.nineTwo',
                describe: "This includes addressing systemic racism and ensuring that people of all races and ethnicities have equal access to opportunities, education, and employment, and that they are not subject to discrimination or bias"
            },
            {
                subrecipient: 'LGBTQ+ equity',
                translate: 'category.nineThree',
                describe: "This includes ensuring that people of all sexual orientations and gender identities are treated with respect and equality, and that they have equal access to opportunities and benefits"
            },
            {
                subrecipient: 'Disability inclusion',
                translate: 'category.nineFour',
                describe: "This includes ensuring that people with disabilities have equal access to opportunities and are not subject to discrimination or bias, and that workplaces and other environments are accessible and inclusive"
            },
            {
                subrecipient: 'Cultural competency',
                translate: 'category.nineFive',
                describe: "This includes ensuring that people have the skills and knowledge needed to work effectively with people from diverse cultures, and that they are sensitive to cultural differences and respect diversity"
            },
            {
                subrecipient: 'Age diversity',
                translate: 'category.nineSix',
                describe: "This includes addressing ageism and ensuring that people of all ages have equal access to opportunities and are not subject to discrimination or bias"
            },
            {
                subrecipient: 'Neurodiversity',
                translate: 'category.nineSeven',
                describe: "This includes addressing biases and stereotypes related to conditions such as autism, ADHD, and dyslexia, and ensuring that people with neurodiverse conditions have equal access to opportunities and support"
            },
        ]
    },
    {
        recipient: 'Work-life balance',
        translate: 'category.ten',
        describe: 'This includes information and resources to help employees achieve a healthy work-life balance, as well as any changes or updates in this area.',
        children: [
            {
                subrecipient: 'Time management',
                translate: 'category.tenOne',
                describe: "This includes managing time effectively to balance work responsibilities with personal responsibilities and interests, such as prioritizing tasks, setting boundaries, and delegating responsibilities"
            },
            {
                subrecipient: 'Flexible work arrangements',
                translate: 'category.tenTwo',
                describe: "This includes implementing flexible work schedules and remote work options to accommodate employees' needs, such as allowing for telecommuting, part-time work, or job sharing"
            },
            {
                subrecipient: 'Health and wellness',
                translate: 'category.tenThree',
                describe: "This includes promoting physical and mental health through programs and benefits such as wellness coaching, fitness facilities, and stress management resources"
            },
            {
                subrecipient: 'Family and caregiving',
                translate: 'category.tenFour',
                describe: "This includes supporting employees with family and caregiving responsibilities through benefits such as parental leave, elder care support, and flexible scheduling"
            },
            {
                subrecipient: 'Personal development',
                translate: 'category.tenFive',
                describe: "This includes promoting personal growth and development through training and education programs, mentoring, and coaching"
            },
            {
                subrecipient: 'Workload management',
                translate: 'category.tenSix',
                describe: "This includes managing workload and work expectations to prevent overwork and burnout, such as monitoring workload, managing deadlines, and encouraging employees to take breaks"
            },
            {
                subrecipient: 'Workplace culture',
                translate: 'category.tenSeven',
                describe: "This includes creating a positive workplace culture that values work-life balance, such as promoting work-life balance policies and encouraging employees to take time off and prioritize self-care"
            },
        ]
    },
    {
        recipient: 'Remote work and flexibility',
        translate: 'category.eleven',
        describe: 'This includes communication related to remote work and flexible work arrangements, including any policies or procedures in these areas.',
        children: [
            {
                subrecipient: 'Technology',
                translate: 'category.elevenOne',
                describe: "This includes providing the necessary technology and tools for remote work, such as laptops, software, and virtual communication tools like video conferencing and messaging apps"
            },
            {
                subrecipient: 'Performance management',
                translate: 'category.elevenTwo',
                describe: "This includes establishing performance goals and expectations for remote or flexible work, such as setting clear metrics and targets, providing feedback and coaching, and addressing performance issues"
            },
            {
                subrecipient: 'Culture and engagement',
                translate: 'category.elevenThree',
                describe: "This includes building a remote or flexible work culture that supports employee engagement and connection, such as scheduling regular team meetings, social events, and opportunities for virtual collaboration"
            },
            {
                subrecipient: 'Work arrangements',
                translate: 'category.elevenFour',
                describe: "This includes establishing policies and guidelines around remote work and flexibility, such as outlining eligibility criteria, determining work schedules, and addressing legal and compliance issues related to remote work"
            },
        ]
    }
]

export const testTopics = [
    {
        topic: 'Technical design and architecture',
        children: [
            {
                label: 'Evaluating the trade-offs between different architectural approaches, such as microservices vs monolithic architecture',
            },
        ]
    },
    {
        topic: 'Product development',
        children: [
            {
                label: 'We have a new roadmap for the launch of product Beta 3.0, including milestones and deadlines. '
            },
            {
                label: 'We have a new roadmap for the launch of product Beta 3.0, including milestones and deadlines. '
            },
        ]
    },
    {
        topic: 'Performance and productivity',
        children: [
            {
                label: 'Discussing and implementing best practices for workflow efficiency'
            },
        ]
    },
    {
        topic: 'Workload and resources',
        children: [
            {
                label: 'Developing strategies for utilizing resources more effectivelyEmployee Development'
            },
            {
                label: 'Discussing and implementing employee recognition and rewards programs.'
            },
        ]
    },
    {
        topic: 'Industry trends and technology',
        children: [
            {
                label: 'Keeping up-to-date with new technologies and developments in the industry'
            },
            {
                label: 'Evaluating the potential impact of the new AI models in the industry.'
            },
        ]
    }
]

export const lngs = [
    {key: 'en', code: 'us', nativeName: 'English'},
    // {key: 'ru', code: 'ru', nativeName: 'Русский'},
    {key: 'es', code: 'es', nativeName: 'Español'},
    {key: 'ja', code: 'jp', nativeName: "日本語"},
    // {key: 'zh', code: 'tw', nativeName: "臺灣話"}
];
