import styles from './example.module.css'
import {alpha, Card, CardActions, CardContent, IconButton} from "@mui/material";

export const Example = ({ text, value, icon, onClick, autoHeight, chatTheme }) => {
  return (
    <Card onClick={() => onClick(value)} sx={{
        minHeight: '100%',
        cursor: 'pointer',
        color: chatTheme.answerText,
        background: chatTheme.answerBg,
        '&:hover': {
            background: alpha(chatTheme.answerBg, 0.5),
        },
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    }}>
        <CardContent className="pb-0">
                <p className="" dangerouslySetInnerHTML={{__html: text}}/>
        </CardContent>
        <CardActions className="justify-content-end pt-0">
            <IconButton size="small" sx={{
                color: chatTheme.answerText
            }}>
                {icon}
            </IconButton>
        </CardActions>
    </Card>
  )
}
