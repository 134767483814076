import {Alert, Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {Cancel, Check, CheckCircle, Close, StarBorder} from "@mui/icons-material";
import {useEffect, useState} from "react";

const DriveStatusFiles = (props) => {
    const {response, setOpen} = props;
    const [successFiles, setSuccessFiles] = useState(0);
    const [failFiles, setFailFiles] = useState(0);
    const [uploaded, setUploaded] = useState(false);
    // const [open, setOpen] = useState(openAlert);
    const [openCollapse, setOpenCollapse] = useState(false);

    // response = [{file_name: "1 The Final Problem Autor Arthur Conan Doyle.pdf", status: "added correctly"}]

    console.log(response)
    useEffect(() => {
        console.log("IN EFFECT", response)
        let success = 0;
        let fail = 0;
        if (response?.length >= 0) {
            /*response?.map((file) => {
                file.status === 'added correctly' ? success++ : fail++
            })*/
            setUploaded(true);
        }
        setSuccessFiles(success);
        setFailFiles(fail)
        //
        setUploaded(true);
    }, [])
    return (
        <div>
            <Alert icon={<Check fontSize="inherit"/>} severity="success"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        <Close fontSize="inherit" />
                                    </IconButton>
                                }
                           sx={{
                               overflow: 'auto',
                               maxHeight: '100vh',
                               '& ul': { padding: 0 }
                           }}
                                onClick={() => setOpenCollapse(!openCollapse)}>
                {/*{successFiles} files indexed successfully and {failFiles} failures*/}
                {response}
            </Alert>

            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {uploaded ?
                        {/*response?.map((file) =>
                            <ListItemButton sx={{pl: 4}}>
                                <ListItemIcon>
                                    {file.status === 'added correctly' ? <CheckCircle color="success"/> : <Cancel color="error"/>}
                                </ListItemIcon>
                                <ListItemText primary={file.file_name} secondary={file.status}/>
                            </ListItemButton>)*/} :
                        <ListItemButton sx={{pl: 4}}>
                            <ListItemIcon>
                                <Cancel color="error"/>
                            </ListItemIcon>
                            <ListItemText primary="Check status"/>
                        </ListItemButton>
                    }
                </List>
            </Collapse>
        </div>
    )
}

export default DriveStatusFiles;
