import {connect} from "react-redux";
import {Badge, Chip, CircularProgress, Tooltip} from "@mui/material";
import logo from '../../../assets/SharePoint-Logo.wine.png';
import {toastr} from "react-redux-toastr";
import {
    disconnectLinkedinIntegrationService, disconnectSharepointIntegrationService,
    setNewLinkedinIntegrationService, setNewSharepointIntegrationService
} from "../../../services/agent-post-ai.service";
import {getUserWithEmailThunk,} from "../../../redux/users/users.thunk";
import {useState} from "react";
import {msalInstanceOneDrive, msalInstanceSharepoint} from "../../../index";
import {loginRequest, loginRequestJWT} from "../../../azure_auth/authConfigSharepoint";

const CustomChip = (props) => {
    return (
        <Chip
            variant="filled"
            disabled={props.status === 'loading'}
            avatar={
                props.status === 'loading' ? (
                    <CircularProgress size={20}/>
                ) : (
                    <img style={{width: '35px', height: '35px'}} src={logo} alt="Sharepoint Logo"/>
                )
            }
            label="SharePoint"
            {...props}
        />
    );
}

const SharepointButton = (props) => {
    const [status, setStatus] = useState("initial")

    const existSharepointAccount = () => {
        return props.user.analytics_sharepoint_integrations !== null && props.user.analytics_sharepoint_integrations?.length > 0;
    }

    const disconnect = async () => {
        if (window.confirm(`Are you sure you want to disconnect your SharePoint account ${props.user.analytics_sharepoint_integrations[0]['email']}?`)) {
            setStatus("loading")
            await disconnectSharepointIntegrationService(props.user.id);
            await props.getUser(props.user.email);
            setStatus("loaded")
        }
    }

    const loginMicrosoft = async () => {
        let token;
        try {
            console.log('browser login')
            token = await msalInstanceSharepoint.loginPopup(loginRequest);
            console.log("Sharepoint token normal is: ", token);
            const sharepointAccount = msalInstanceSharepoint.getAllAccounts();
            const currentAccount = msalInstanceSharepoint.getAccountByUsername(token.account.username);
            console.log("normal login; test oneDriveAccount: ", sharepointAccount, currentAccount)
            const accessTokenSilent = await msalInstanceSharepoint.acquireTokenSilent({
                ...loginRequestJWT,
                account: currentAccount
            })
            console.log("accessTokenSilent normal is: ", accessTokenSilent);
            /// 1. save token in DB
            setStatus("loading");
            const res = await setNewSharepointIntegrationService(accessTokenSilent.accessToken, {
                tenantId: accessTokenSilent.tenantId,
                analyticsUserId: props.user.id
            });
            console.log("---- > res normal is: ", res);
            if (res === 'success') {
                console.log("ACC tokens BRowser", token, accessTokenSilent);
                props.getUser(props.user.email);
            }
            setStatus("loaded");
        } catch (e) {
            console.error("LOGIN ERROR", e)
            toastr.error(`Something went wrong, please reload the page to try again`);
        } finally {
            console.log("token finall: ", token)
            const allAccountstest = msalInstanceSharepoint.getAllAccounts();
            console.log(" allAccounts test: ", allAccountstest);
            setStatus("loaded");
        }
    }

    const handleButtonClick = () => {
        const popup = window.open(`${process.env.REACT_APP_BACK_AI_POST}/login`, 'popup', 'width=600,height=600');
        console.log("Popup opened");

        const handleMessage = async (event) => {
            console.log("Received event:", event);

            const {accessToken, sub, vanityName, organizationList} = event.data;
            console.log("Received data:", accessToken, "\nSUB: ", sub, "\nVanityName: ", vanityName, "\nList Org: ", organizationList);

            try {
                const response = await setNewLinkedinIntegrationService({
                    accessToken: accessToken,
                    userSub: sub,
                    vanityName: vanityName,
                    organizationList: organizationList,
                    analyticsUserId: props.user.id
                });
                console.log("RESPONSE INTEGRATION: ", accessToken, sub, vanityName, organizationList, props.user.id);
                console.log("RESPONSE INTEGRATION: ", response);
                props.getUser(props.user.email);

                toastr.success("Connected!", "You have successfully logged in to your LinkedIn account");
                alert("LinkedIn integration successful");
            } catch (error) {
                console.error("Failed to set LinkedIn integration:", error);
                toastr.error("Failed to set LinkedIn integration");
                alert("Failed to set LinkedIn integration");
            } finally {
                window.removeEventListener('message', handleMessage);
                if (popup) {
                    popup.close();
                }
            }
        };

        window.addEventListener('message', handleMessage);
    };

    return (
        <Tooltip
            title={existSharepointAccount() ? props.user.analytics_sharepoint_integrations[0]["username"] : ''}
            className="w-100"
        >
            <Badge color="success" variant="dot" invisible={!existSharepointAccount()}>
                {existSharepointAccount() ? (
                    <CustomChip
                        label={<small className="text-black-50">Connected</small>}
                        className="py-4 w-100 justify-content-center"
                        onClick={() => {
                        }}
                        onDelete={() => disconnect()}
                        status={status}
                    />
                ) : (
                    <CustomChip
                        className="py-4 w-100"
                        onClick={loginMicrosoft}
                        status={status}
                    />
                )}
            </Badge>
        </Tooltip>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.users.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (email) => dispatch(getUserWithEmailThunk(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SharepointButton);
