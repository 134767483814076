import {Drawer, Skeleton, styled, SwipeableDrawer, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import './action-plan.css';
import {useMemo} from "react";
import {parseTextToHtml} from "./answer-parser";
import {connect} from "react-redux";

const StyledBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));


const ActionPlan = (props) => {
    const {open, toggleDrawer, actionPlan, loading} = props;

    const parsedText = useMemo(
        () => parseTextToHtml(actionPlan || "", props.chatTheme),
        [actionPlan]
    )

    const sanitizedAnswerHtml = parsedText.textHtml;

    return (
        <div className="">
            <Drawer
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
            >

                <StyledBox
                    sx={{
                        position: 'absolute',
                        top: -56,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                    }}
                >
                    <Puller />
                    <Typography sx={{ p: 2, color: 'text.secondary' }}><b>Action Plan</b></Typography>
                </StyledBox>
                <div>
                    {actionPlan && !loading && <Typography dangerouslySetInnerHTML={{__html: sanitizedAnswerHtml}}
                        sx={{ p: 2, color: 'text.secondary', whiteSpace: "pre-line", maxHeight: "calc(70vh - 60px)", overflowY: "auto"}}/>}

                    {loading && <Skeleton className="m-2" variant="rectangular" height="90%" />}
                </div>
            </Drawer>
        </div>
    )
}

const mapStateToProps = (state) => {
    console.log('state app:', state)
    return {
        chatTheme: state.agentHrs.chatTheme
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionPlan)
