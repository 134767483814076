export const getY = (doc, txt, y) => {
    txt = txt.replace(/<[^>]+>/g, '').trim();
    const pdfInMM = doc.internal.pageSize.width - 30;
    const lines = doc.splitTextToSize(txt, pdfInMM);
    const dim = doc.getTextDimensions(txt);
    const lineHeight = dim.h;

    console.log('values', txt, lines, lineHeight, pdfInMM);
    doc.text(15, y, lines);

    return lineHeight * lines.length;
}
