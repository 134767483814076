import {
    Grid, IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader, Tooltip,
    Typography
} from "@mui/material";
import * as PropTypes from "prop-types";
import {CheckCircle, Folder, InsertDriveFile, RemoveCircle} from "@mui/icons-material";
import {useState} from "react";
import IconFile from "./icon-file";

function Demo(props) {
    return null;
}

Demo.propTypes = {children: PropTypes.node};
const OnedriveFolderIndex = (props) => {
    const {folders, loadOnedriveFolder, deleteOneDriveFolderFiles} = props;
    const [dense, setDense] = useState(true);
    const [secondary, setSecondary] = useState(false);
    return (
                    <List
                        sx={{
                            width: '100%',
                            bgcolor: 'background.white',
                            overflow: 'auto',
                            maxHeight: '100vh',
                            '& ul': { padding: 0 }
                        }}
                        subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            OneDrive Folders
                        </ListSubheader>
                    }>
                        {folders.length > 0 ? folders.map((folder, index) =>
                            <ListItem
                                key={index}
                                secondaryAction={
                                    folder.stored ? <Tooltip title="Remove">
                                        <IconButton color="error" edge="end" aria-label="remove" onClick={() => deleteOneDriveFolderFiles(folder.name, folder.id)} >
                                            <RemoveCircle />
                                        </IconButton> </Tooltip> : null
                                }
                                disablePadding>
                            <ListItemButton onClick={() => loadOnedriveFolder(folder)} disabled={folder.stored}>
                                <ListItemIcon>
                                    {folder.folder ? <Folder/> : <IconFile item={folder}/>}
                                </ListItemIcon>
                                <ListItemText
                                    primary={folder.name}
                                    secondary={
                                        folder.folder ?
                                            `Number of items: ${folder?.folder?.childCount || 0}` : ''
                                    }
                                />
                            </ListItemButton>
                            </ListItem>,
                        ) : <ListItem disablePadding>
                            <ListItemText
                                primary={<span className="ms-3 text-secondary">Root is empty</span>}
                            />
                        </ListItem>}
                    </List>
    )
}

export default OnedriveFolderIndex;
