import { Card, CardContent, CardHeader, CircularProgress, Divider, TextField } from "@mui/material";
import { MsalContext } from "@azure/msal-react";
import TextFieldWrapper from "../layouts/forms/text-field-wrapper";
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import ButtonWrapper from "../layouts/forms/button-wrapper";
import { connect } from "react-redux";
import { editUserThunk, getUserWithEmailThunk } from "../../redux/users/users.thunk";
import React, { useEffect, useState, useContext } from "react";
import { toastr } from "react-redux-toastr";
import { useNavigate } from 'react-router-dom';
import RotatingSpinner from "../layouts/RotatingSpinner";

const UserProfile = ({ t, user, status, getUser, editUser }) => {
    const msalContext = useContext(MsalContext);
    const navigate = useNavigate();
    const [updateMsg, setUpdateMsg] = useState(false);

    const INITIAL_FROM_STATE = {
        firstName: '',
        lastName: '',
    };

    const FORM_VALIDATION = Yup.object().shape({
        firstName: Yup.string().required(t('user.validFirstNameRequired')),
        lastName: Yup.string().required(t('user.validLastNameRequired')),
    });

    useEffect(() => {
        const email = msalContext.accounts[0].idTokenClaims?.email;
        getUser(email);
    }, [msalContext, getUser]);

    const initialValues = () => {
        if (user) {
            INITIAL_FROM_STATE.firstName = user.firstName;
            INITIAL_FROM_STATE.lastName = user.lastName;
        }
        return INITIAL_FROM_STATE;
    };

    const onSubmit = async (values) => {
        await editUser(values, user.id);
        toastr.success(`${t('success')}!`, t('user.successUpdated'));
        navigate('/integrations');
    };

    return (
        <div className="row">
            <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                {user && status !== 'loading' ?
                    <Card>
                        <CardHeader title={t('user.userProfile')} />
                        <CardContent>
                            <Formik initialValues={initialValues()}
                                    validationSchema={FORM_VALIDATION}
                                    onSubmit={values => onSubmit(values)}>
                                <Form>
                                    <div className="my-3">
                                        <TextFieldWrapper name="firstName" label={t('user.firstName')} />
                                    </div>
                                    <div className="my-3">
                                        <TextFieldWrapper name="lastName" label={t('user.lastName')} />
                                    </div>
                                    <div className="my-4 text-center">
                                        <ButtonWrapper>{t('user.update')}</ButtonWrapper>
                                    </div>
                                </Form>
                            </Formik>

                            <Divider />

                            <div className="my-4">
                                <TextField fullWidth={true} variant='outlined' label={t('user.email')} disabled value={user.email} />
                            </div>

                        </CardContent>
                    </Card> : null}
                <div>
                    <div className="d-flex justify-content-center">
                        {!user || status === 'loading' ? <RotatingSpinner /> : null}
                    </div>
                    {!user || status === 'loading' ?
                        <p className="text-center">{t('status.loading')}... </p> : null}
                </div>
            </div>
        </div>
    );
};

// Map state to props;
const mapStateToProps = (state) => {
    return {
        user: state.users.user,
        status: state.users.status
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        editUser: (obj, id) => dispatch(editUserThunk(obj, id)),
        getUser: (email) => dispatch(getUserWithEmailThunk(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
