import {MenuItem, TextField} from "@mui/material";
import {useField, useFormikContext} from "formik";
import {useTranslation} from "react-i18next";
import * as moment from "moment";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";

const SelectFieldPlatformsWrapper = (props) => {
    const {name, options, labelValue, idValue, simple, ...otherProps} = props;
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (e) => {
        const {value} = e.target;
        console.log(value);
        if (isValidPlatform(value)) {
            setFieldValue(name, value);
            console.log("PLATTTTT ", value)
            if (otherProps.updateState) {
                otherProps.updateState(value);
            }
        } else {
            toastr.warning("Warning", `To select ${value.toUpperCase()} you must first integrate it`)
        }
    };

    const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        variant: 'outlined',
        fullWidth: true,
        onChange: handleChange
    }

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    const isValidPlatform = (platform) => {
        switch (platform) {
            case 'linkedin': return !!props.user?.analytics_linkedin_integrations?.length;
            case 'facebook': return !!props.user?.analytics_facebook_integrations?.length;
            case 'wordpress': return !!props.user?.analytics_wordpress_integrations?.length;
            case 'tiktok': return !!props.user?.analytics_tiktok_integrations?.length;
            case 'instagram': return !!props.user?.analytics_instagram_integrations?.length;
            case 'twitter': return !!props.user?.analytics_twitter_integrations?.length;
            case 'sharepoint': return !!props.user?.analytics_sharepoint_integrations?.length;
            default: return false;
        }
    }

    return (
        <TextField {...configSelect}>
            {options.map((item, pos) => {
                return (
                    <MenuItem key={pos + item[idValue]} value={item[idValue]}>
                        { item[labelValue]}
                    </MenuItem>
                )
            })}
        </TextField>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.users.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectFieldPlatformsWrapper);
