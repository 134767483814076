import {
    Button,
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Slider,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React, { Component } from "react";
import { AuthenticatedTemplate, MsalContext, UnauthenticatedTemplate, } from "@azure/msal-react";
import axios from "axios";
import { connect } from "react-redux";
import './billing-stripe-index.css';
import {format} from "date-fns";
import {addUserThunk, getUserThunk, getUserWithEmailThunk} from "../../redux/users/users.thunk";
import {SignInButton} from "../../azure_auth/components/SignInButton";
import {Done} from "@mui/icons-material";
import DialogContact from "./dialog-contact/dialog-contact";
import { loginRequest } from "../../azure_auth/authConfig";

import BillingContact from "./billing-contact";

import {
    createCustomePortalService,
    createPortalService,
    createSessionService,
    createSessionTrialService
} from "../../services/billing.service";
import RotatingSpinner from "../layouts/RotatingSpinner";
import TagManager from "react-gtm-module";

const PrettoSlider = styled(Slider)({
    color: '#0000FE',
    height: 12,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 35,
        width: 35,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#0000FE',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

window.dataLayer = window?.dataLayer || [];
function gtag(){
    window.dataLayer.push(arguments);
}
gtag('js', new Date());
gtag('config', 'G-M7GH1CQKB4');


class Billing extends Component {

    static contextType = MsalContext;

    state = {
        price: 49,
        totalPrice: 49,
        secondPrice: 499,
        secondTotalPrice: 499,
        openForm: false,

        loadingPurchase: false,
        loadingPortal: false,
    }


    async componentDidMount() {
        console.log("component mounted");
        /*console.log(this.context.accounts);
        const account = this.context.accounts[0];
        if (!this.props.user) {
            const email =  this.context.accounts[0].idTokenClaims.email;
            await this.props.getUser(email);
            if (!this.props.user?.id) {
                console.log("add new user");

                await this.props.addUser({
                    // id: account.localAccountId,
                    email: account.idTokenClaims.email,
                    firstName: account.idTokenClaims.extension_firstName,
                    lastName: account.idTokenClaims.extension_lastName,
                    tenantId: account.tenantId
                });
            } else {

                console.log("user exists")
            }

            console.log('USER billing: ', this.props.user);
        }*/
    }


    /*this is the first step in the flow, it creates a url with a checkout session and redirects to it
    at this point, if the user purchases the first subscription, he gets a stripeCustomerId assigned in the backend
    */

    purchaseSubscription = async (e) => {
        console.log(e.currentTarget.value);
        this.setState({loadingPurchase: true})

        const email = this.context.accounts[0].idTokenClaims?.email;


        let obj = {
            //email is important since this is how backend searches for the user in the table, and updates it with stripeCustomerId
            // email: email,
            //type of subscription plan, available types standard_plan_native, advanced_plan_native
            type: e.currentTarget.value
        };

        let res;

        //if user has stipeCustomerId, that means user already purchased a plan before, don't offer trial
        if (this.props.user && this.props.user.stripeCustomerId) {
            obj.stripeCustomerId = this.props.user.stripeCustomerId
            // res = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/stripe/create-checkout-session`, obj);
            res = await createSessionService(obj);

        } 
        //this is new user since stripeCustomerId is empty, offer trial
        else {
            obj.email = email;
            // res = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/stripe/create-checkout-session-trial`, obj);
            res = await createSessionTrialService(obj);
        }


        // window.location.href = res;

        gtag("event", "begin_checkout", {
            event_callback: function () {
                window.location.href = res;
            },
        });

        this.setState({loadingPurchase: false})

        /*TagManager.dataLayer({
            dataLayer: {
                event: 'begin_checkout',
                function () {
                    window.location.href = res;
                }
            }
        })*/
    }

    /*
    this creates a link to stripe customer portal
    in order to created it, you need to pass stripeCustomerId
    this is the reason we have to first call the above method, so user can get stripeCustomerAssigned
    also, users are not able to purchase subscriptions from the portal (try cancelling all your subs, and then go to the portal again, there would be no option to purchase a plan), 
    so if user cancelled subs and wants to subscribe again, use purchaseSubscription()
    */

    stripePortal = async () => {
        let obj = {
            stripeCustomerId: this.props.user.stripeCustomerId
        }
        // let res = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/stripe/create-customer-portal-session`, obj);
        this.setState({loadingPortal: true})
        let res = await createCustomePortalService(obj);
        window.location.href = res;
        this.setState({loadingPortal: false})
    }

    setOpenForm = (open) => {
        this.setState({ openForm: open });
    }

    render() {
        console.log(this.props.user?.stripe_live_subscriptions_analytics);
        return (
            <div>

                <AuthenticatedTemplate>
                    <div className="row">
                        <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">

                            {this.props.user && this.props.user.stripe_live_subscriptions_analytics && this.props.user.stripe_live_subscriptions_analytics.length < 1 ?
                                <div>
                                    <div className="text-center mb-4">
                                        <h2>Better prices, better services</h2>
                                        <p className="text-secondary">Buy at any time</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 my-2">
                                            <div className="card card-area-animation text-center">
                                                <div className="card-body">
                                                    <Typography variant="h2" className="my-3">
                                                        <span className="price">${this.state.totalPrice}</span><h4
                                                            className="d-inline-block"><small>/{"month"}</small></h4>
                                                    </Typography>
                                                    <p className="text-primary"><b>All basic resources</b></p>
                                                    {/*<div className="w-100 my-3">
                                                    <PrettoSlider
                                                        aria-label="Price"
                                                        defaultValue={1}
                                                        getAriaValueText={(value) => `${value} month(s)`}
                                                        valueLabelDisplay="on"
                                                        onChange={(e) => this.setState({totalPrice: e.target.value * this.state.price})}
                                                        step={1}
                                                        marks
                                                        min={1}
                                                        max={12}
                                                    />
                                                </div>*/}
                                                    <div className="text-center my-3">
                                                        <button value="content_generator_plan"
                                                            onClick={(e) => this.purchaseSubscription(e)}
                                                                disabled={this.state.loadingPurchase}
                                                            className="btn btn-block btn-primary mr-2">
                                                            {!this.state.loadingPurchase ?
                                                                "Buy Now" : <CircularProgress color="inherit" size={20}/>
                                                            }
                                                        </button>
                                                    </div>
                                                    <div className="text-start">
                                                        <List dense={false}>
                                                            {['5 AI Agents', 'Unlimited projects.', '+28 languages.', 'Premium email support.'].map((item) => (
                                                                <ListItem>
                                                                    <ListItemIcon>
                                                                        <Done className="text-success" />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={item}
                                                                        secondary={''}
                                                                    />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <BillingContact openForm={this.state.openForm} setOpenForm={this.setOpenForm}/>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>

                    </div>
                    <div className="col-md-12 my-4">
                        <h6><b>{('Your Subscriptions')}</b></h6>
                        {this.props.user && this.props.user.stripe_live_subscriptions_analytics && this.props.user.stripe_live_subscriptions_analytics.length > 0 ?

                            <div>
                                <div className="text-center my-5">
                                    <Button variant="contained" size="large"
                                            disabled={this.state.loadingPortal}
                                        onClick={() => this.stripePortal()}>
                                        {!this.state.loadingPortal ?
                                        "Manage Billing" : <CircularProgress color="inherit" size={20}/>
                                    }</Button>
                                </div>

                                <TableContainer className="w-100">
                                    <Table className="w-100" aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                {/* <TableCell>Product Id</TableCell>
                                            <TableCell align="right">Subscription Id</TableCell>*/}
                                                <TableCell align="center">{('Plan')}</TableCell>
                                                <TableCell align="center">{('Quantity')}</TableCell>
                                                <TableCell align="center">{('Billing Period')}</TableCell>
                                                <TableCell align="center">{('Status')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.props.user.stripe_live_subscriptions_analytics.map((row, index) => (
                                                row.active ?
                                                    <TableRow key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        {/* <TableCell component="th" scope="row">
                                                        {row.productId}
                                                    </TableCell>
                                                    <TableCell align="right">{row.subscriptionId}</TableCell>*/}
                                                        <TableCell
                                                            align="center">{row.subscriptionJson.plan.amount / 100} {' ' + row.subscriptionJson.plan.currency + ' ' + row.subscriptionJson.plan.interval}</TableCell>
                                                        <TableCell align="center">{row.quantity}</TableCell>
                                                        <TableCell align="center">
                                                            {format(new Date(row.subscriptionJson.current_period_start * 1000), 'MM/dd/yyyy')
                                                                + ' to '
                                                                + format(new Date(row.subscriptionJson.current_period_end * 1000), 'MM/dd/yyyy')}
                                                        </TableCell>
                                                        <TableCell align="center">{row.status}</TableCell>
                                                    </TableRow> : null
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            : <p>{('No Subscriptions')}</p>}
                    </div>

                </AuthenticatedTemplate>


                <UnauthenticatedTemplate>

                    {this.context.inProgress !== 'none' ?
                        this.context.inProgress === 'login' || this.context.inProgress === 'logout' ?
                            {/*<div className="text-center">
                                <p className="text-capitalize">{`${this.context.inProgress} in progress...`}</p>
                                <RotatingSpinner/>
                            </div>*/} : null :
                        <div className="row h-100 mt-5">
                            {/* <div className="col-md-12">
                                <p className="fs-lg-1">
                                    <b>PBI ProtoType with CI/CD</b>
                                </p>
                                <p className="fs-5">please Sign in</p>
                                <SignInButton variant="outlined"
                                              color="primary"
                                              size="large" className="mt-2"/>

                            </div> */}
                            {this.context.accounts.length == 0 ? this.context.instance.loginRedirect(loginRequest) : <></>}


                        </div>}
                </UnauthenticatedTemplate>
            </div>


        )
    }


    /*<Button onClick={()=>this.stripePortal()}>Customer Portal</Button>*/
    /* */

    /* now user objects have subscriptions field, it will include only currently active subscriptions (no old, cancelled, etc) */

    /*this.props.user && this.props.user.stripe_subscriptions_natives.length>0 ? <p>*/
    /* this can be used to control what content user has access to based on plan, etc */
    /*{JSON.stringify(this.props.user.stripe_subscriptions_natives)}</p> : <p>You don't have any valid subscriptions yet</p>*/

}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state billing", state);
    return {
        user: state.users.user,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (email) => dispatch(getUserWithEmailThunk(email)),
        addUser: (obj) => dispatch(addUserThunk(obj))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
