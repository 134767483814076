import React from 'react';
import { useRef, useState } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Bar } from "react-chartjs-2";
import Draggable from "react-draggable";
import {ChartGenerator, ChartGeneratorData} from './ChartGenerator';

const chartOptions = {
    scales: {
        y: {
            beginAtZero: true,
        },
    },
};
// const chartRef = useRef(null);

export function ChartMaximized({ jsonChartData, onClose }) {
    const isWindowSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const handleClose = () => {
        onClose();
    };

    return (
        <Draggable>
            <Box
                sx={{
                    position: "absolute",
                    top: "15%",
                    left: "2vw",
                    width: "900px",
                    height: "500px",
                    zIndex: "Tooltip",
                    ...(isWindowSmall && { width: "95%" }),
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography sx={{ fontWeight: "600", color: "black", bgcolor: "" }}>
                        Charts panel
                    </Typography>
                    <Button onClick={handleClose}>
                        <CloseIcon />
                    </Button>
                </Box>
                <Box
                    sx={{
                        bgcolor: "#f9f9f9",
                        borderRadius: "8px",
                        boxShadow: "0 0 0 1px rgb(0 0 0/5%), 0 2px 3px 0 rgba(0,0,0,.1)",
                        display: "flex",
                        flexDirection: "column",
                        outline: "50px solid rgba(182, 187, 196, .8)",
                        padding: "20px",
                        fontSize: "1.1rem",
                        width: "100%",
                        maxHeight: "70vh",
                        overflow: "auto",
                    }}
                >
                    <div>
                        <ChartGenerator answer={jsonChartData}/> 
                    </div>
                </Box>
            </Box>
        </Draggable>
    );
}
