import {useNavigate} from "react-router-dom";
import PostMonthListIndex from "./post-month-list/post-month-list-index";
import {usePostAiIntegrationsGuard} from "../../../guards/post-ai-integrations-guard";
import PostMonthShow from "./post-month-show/post-month-show";
import {useState} from "react";

const AgentPostMonthIndex = () => {
    const [post, setPost] = useState(null)
    const [postAiGuard, setPostAiGuard] = useState(usePostAiIntegrationsGuard())
    const navigate = useNavigate()
    return (
        <div className="container-fluid mt-2">
            {post === null && <div className="">
                {postAiGuard && <button className="btn btn-primary" onClick={() => navigate('/new-post-month')}>
                    NEW
                </button>}

                {postAiGuard && <button className="btn btn-outline-success mx-1" onClick={() => navigate('/post-month')}>
                    SEE ALL
                </button>}

                {/*usePostAiIntegrationsGuard() && <button className="ms-2 btn btn-primary" onClick={() => navigate('/show-post-month')}>
                    SEE ALL
                </button>*/}
            </div>}
            <div className="row">
                <div className={post ? "col-md-12" : "col-md-9"}>
                    <PostMonthShow post={post} setPost={setPost}/>
                </div>
                {post === null && <div className="col-md-3">
                    <PostMonthListIndex/>
                </div>}
            </div>
        </div>
    )
}

export default AgentPostMonthIndex;
