import { Stack, PrimaryButton } from "@fluentui/react";
import { ErrorCircle24Regular } from "@fluentui/react-icons";

import styles from "./answer.module.css";
import RotatingSpinner from "../../../../layouts/RotatingSpinner";

export const AnswerError = ({ error, onRetry, loadGoogleLogin }) => {
    console.log("ERROR SHOW BTN", loadGoogleLogin)
    return (
        <Stack className={styles.answerContainer} verticalAlign="space-between">
            <ErrorCircle24Regular aria-hidden="true" aria-label="Error icon" primaryFill="red" />

            <Stack.Item grow>
                <p className={styles.answerText}>{error}</p>
            </Stack.Item>

            {!loadGoogleLogin ? <PrimaryButton className={styles.retryButton} onClick={onRetry} text="Retry" /> : <RotatingSpinner/>}
        </Stack>
    );
};
