import {Card, CardContent, CardHeader} from "@mui/material";
import * as React from "react";
import {Component} from "react";
import {updateProfilePhotoThunk} from "../../../redux/users/users.thunk";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
// import {MutatingDots} from "react-loader-spinner";
import {Form, Formik} from "formik";
import TextFieldWrapper from "../../layouts/forms/text-field-wrapper";
import ButtonWrapper from "../../layouts/forms/button-wrapper";
import * as Yup from "yup";
import {MsalContext} from "@azure/msal-react";
import axios from "axios";
import {InfinitySpin} from "react-loader-spinner";
import {palette} from "../../../assets/styles/theme";

const style = {
    textImg: {
        color: 'white',
        position: 'absolute',
        textShadow: '0 1px 2px rgba(0, 0, 0, 0.8)',
        fontWeight: 600,
        fontSize: '0.7em',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0
    }
}

class DialogContact extends Component {

    state = {
        updateMsg: false,
        sentMessage: '',
        sending: false,
        other: null
    }
    static contextType = MsalContext;

    INITIAL_FROM_STATE = {
        email: '',
        firstName: '',
        lastName: '',
        company: '',
        interest: '',
    };

    FORM_VALIDATION = Yup.object().shape({
        email: Yup.string().required('Required'),
        firstName: Yup.string().required(this.props.t('user.validFirstNameRequired')),
        lastName: Yup.string().required(this.props.t('user.validLastNameRequired')),
        company: Yup.string().required('Required'),
        interest: Yup.string().required('Required'),
    });

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
    }

    initialValues() {
        return this.INITIAL_FROM_STATE;
    }

    onSubmit = async (values, {resetForm}) => {
        console.log("SUBMIT form", values)
        this.setState({sending: true});
        let obj = {
            email: values.email,
            name: values.firstName + " " + values.lastName,
            body: values.interest,
            subject: "Content Generator Custom Plan",
            company: values.company
        }
        let res = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/zendesk/newTicket`, obj).catch(err => toastr.error("", "Somethig went wrong when submitting the request. Try again"))
        if (res.status === 201) {
            // toastr.success(`${this.props.t('success')}!`, 'Your request has been sent successfully');
            localStorage.setItem('analyticsContactTicked', this.props.user?.id);
            this.setState({sentMessage: 'Thank you for contacting us, your request was sent successfully.'})
        }
        this.setState({sending: false, other: false});
        resetForm();
    }

    onAnotherQuestion = () => {
        if (localStorage.analyticsContactTicked && localStorage.analyticsContactTicked === this.props.user?.id) {
            localStorage.removeItem('analyticsContactTicked');
            this.setState({other: true})
        }
    }


    render() {
        const {edit} = this.state;
        const {t} = this.props;
        return (
            <div className="row">
                <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                    {localStorage.analyticsContactTicked && localStorage.analyticsContactTicked === this.props.user?.id ?
                        <Card>
                            <CardHeader className="text-center" title=""/>
                            <CardContent className="text-center">
                                <p className="text-secondary h4">Thank you for contacting us, your request was sent
                                    successfully</p>
                                <p className="text-secondary my-4">You already sent a request before, if you want to
                                    send another request please click on the button below.</p>
                                <button onClick={() => this.onAnotherQuestion()} className="btn btn-primary">Another
                                    Question?
                                </button>
                            </CardContent>
                        </Card> :
                        !this.state.sending ?
                        <Card>
                            <CardHeader title="Fill out this form to request a custom plan"/>
                            <CardContent>
                                <Formik initialValues={this.initialValues()}
                                        validationSchema={this.FORM_VALIDATION}
                                        onSubmit={this.onSubmit}>
                                    <Form>
                                        <div className="my-3">
                                            <TextFieldWrapper name="email" label="Email" type="email"/>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 my-3">
                                                <TextFieldWrapper name="firstName" label={t('user.firstName')}/>
                                            </div>
                                            <div className="col-md-6 my-3">
                                                <TextFieldWrapper name="lastName" label={t('user.lastName')}/>
                                            </div>
                                        </div>
                                        <div className="my-3">
                                            <TextFieldWrapper name="company" label="Company"/>
                                        </div>

                                        <div className="my-3">
                                            <TextFieldWrapper name="interest" label="I'm looking for..."
                                                              multiline={true} rows={3}/>
                                        </div>
                                        <div className="my-4 text-center">
                                            <ButtonWrapper>Request</ButtonWrapper>
                                        </div>
                                    </Form>
                                </Formik>
                            </CardContent>
                        </Card> :
                            <div className="d-flex justify-content-center text-center">
                                <InfinitySpin
                                    color={palette.primary}
                                    width="200"/>
                            </div>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        user: state.users.user,
        status: state.users.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProfilePhoto: (obj, id) => dispatch(updateProfilePhotoThunk(obj, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogContact);

