import {connect, useSelector} from "react-redux";

export const usePostAiIntegrationsGuard = (route=null) => {
    const user = useSelector(state => state.users.user)
    const wordpressIntegrations = !!user?.analytics_wordpress_integrations?.length;
    const linkedinIntegrations = !!user?.analytics_linkedin_integrations?.length;
    const facebookIntegrations = !!user?.analytics_facebook_integrations?.length;
    const tiktokIntegrations = !!user?.analytics_tiktok_integrations?.length;
    const instagramIntegrations = !!user?.analytics_instagram_integrations?.length;
    const twitterIntegrations = !!user?.analytics_twitter_integrations?.length;
    // console.log("AWS AWS", awsSubscription)

    return wordpressIntegrations || linkedinIntegrations || facebookIntegrations || instagramIntegrations || tiktokIntegrations || twitterIntegrations ;
}
