import {Button} from "@mui/material";
import {useFormikContext} from "formik";

const ButtonWrapper = ({children, ...otherProps}) => {
    const {submitForm} = useFormikContext();

    const handleSubmit = async () => {
        await submitForm();
    }

    const config = {
        variant: 'contained',
        color: 'primary',
        fullWidth: true,
        size: 'large',
        onClick: handleSubmit
    }

    return (
        <button type="submit" className="btn btn-primary mr-2" {...otherProps}>{children}</button>
    )
}

export default ButtonWrapper;
