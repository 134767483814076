import React, { useState } from 'react';
import { Button, TextField, Box } from '@mui/material';
import {CloudUpload, UploadFile} from "@mui/icons-material";

const UploadFileInput = (props) => {
    const {handleYesFeedback} = props;
    const [fileName, setFileName] = useState('');

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            setFileName(event.target.files[0].name);
            handleYesFeedback(event.target.files[0])
        }
    };

    return (
        <Box className="d-flex align-items-center">
            <input
                accept="image/*"
                id="upload-file"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <label htmlFor="upload-file" className="mb-0">
                <Button variant="contained" color="primary" component="span" disabled={props.disabled}>
                    <CloudUpload/>
                    Upload Image
                </Button>
            </label>
        </Box>
    );
}

export default UploadFileInput;
