import { Box, IconButton, useMediaQuery } from "@mui/material";
import RotatingSpinner from "../../../../layouts/RotatingSpinner";


export function LoadingButton({
  isLoading = false,
  onClick,
  title = "",
  children,
}) {
  const isWindowSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleClick = () => {
    onClick();
  };

  if (isLoading) {
    return <Box display="inline"><RotatingSpinner/></Box>;
  } else {
    return (
      <IconButton
        title={title}
        onClick={() => handleClick()}
        sx={{
          paddingY: "4px",
          borderRadius: "18px",
          width: "60px",
          ...(isWindowSmall && { padding: "0px", width: "40px" }),
        }}
      >
        {children}
      </IconButton>
    );
  }
}
