import { parseSupportingContentItem } from "./supporting-content-parser";

import styles from "./supporting-content.module.css";
import {objToTable} from "../../helpers/htmls";

function compareStrings(str1, str2) {
    // 1. Convert both strings to lowercase
    const lowerStr1 = str1.trim().toLowerCase();
    const lowerStr2 = str2.trim().toLowerCase();
  
    // 2. Remove extra spaces using regular expression
    const trimmedStr1 = lowerStr1.replace(/\s+/g, " "); // Replace multiple spaces with single space
    const trimmedStr2 = lowerStr2.replace(/\s+/g, " ");
  
    // 3. Compare the trimmed strings
    return trimmedStr1 === trimmedStr2;
  }

export const SupportingContent = ({ supportingContent, fileUrls, notable, fileName }) => {
    return (
        <ul className={styles.supportingContentNavList}>
            {supportingContent.map((x, i) => {
                const parsed = parseSupportingContentItem(x);

                if(!notable) {
                    parsed.content = objToTable(JSON.parse(parsed.content));

                    if(i === 0)/// TODO added temporally, delete in the future
                        return (
                            <li className={styles.supportingContentItem}>
                                <h4 className={styles.supportingContentItemHeader}>{parsed.title}</h4>
                                <p dangerouslySetInnerHTML={{ __html: parsed.content}}/>
                            </li>
                        );
                    else return null
                }
                console.log("FILENAME", parsed.title, fileName, parsed.title == fileName, compareStrings(parsed.title, fileName))
                console.log("PARSED ", parsed)
                if(compareStrings(parsed.title, fileName)){
                    let url = "";
                    fileUrls.forEach(element => {
                        if(element.file_name == parsed.title)
                            // url = `\n\n <b> Reference:</b> <a href='${element.url}' target='_blank'> ${element.file_name} </a> `
                            url = `\n\n <b> Reference:</b> <span> ${element.file_name} </span> `
                    });
                    return (
                        <li className={styles.supportingContentItem}>
                            <h4 className={styles.supportingContentItemHeader}>{parsed.title}</h4>
                            <p dangerouslySetInnerHTML={{ __html: (parsed.content)}} style={{whiteSpace: 'pre-line'}} />
                            <p dangerouslySetInnerHTML={{ __html: (url)}} style={{whiteSpace: 'pre-line'}} />
                        </li>
                    );
                }
                else return null
            })}
        </ul>
    );
};
