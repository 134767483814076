import {useEffect, useRef, useState} from 'react'
import axios from "axios";
import "./Form.css";
import {lngs, platforms, testRecipients, tones} from './Data';
import {Chip, CircularProgress, FormControlLabel, IconButton, InputAdornment, Radio, Tooltip} from "@mui/material";

import SelectFieldWrapper from "../layouts/forms/select-field-wrapper";
import * as Yup from "yup";
import {Form, Formik, useField, useFormikContext} from "formik";

import TextFieldWrapper from "../layouts/forms/text-field-wrapper";
import ButtonWrapper from "../layouts/forms/button-wrapper";
import CheckFieldWrapper from "../layouts/forms/check-field-wrapper";
import Content from "./Content";
import AutocompleteFieldWrapper from "../layouts/forms/autocomplete-field-wrapper";
import AutocompleteFieldWrapperList from "../layouts/forms/autocomplete-field-wrapper-list";
import {useTranslation} from "react-i18next";
import {toastr} from "react-redux-toastr";

import {Edit, FormatListBulleted, HelpOutline} from "@mui/icons-material";
import {
    generateContentService,
    getCustomeTopicsService,
    saveCountTokensWordsService
} from "../../services/content.service";
import {connect} from "react-redux";
import {InfinitySpin} from "react-loader-spinner";
import {palette} from "../../assets/styles/theme";
import {useNavigate} from "react-router-dom";
import {countWords} from "../../helpers/text";
import AutocompleteFieldWrapperChip from "../layouts/forms/autocomplete-field-wrapper-chip";
import RotatingSpinner from "../layouts/RotatingSpinner";
import {Persist} from "formik-persist";
import {saveForm} from "../../redux/content/content.actions";

function FormContent(props) {

    console.warn("FORM CONTENT PROPS: ", props)

    const [platformResult, setPlatformResult] = useState([]);
    const [topics, setTopics] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataAux, setDataAux] = useState(null);
    const [subtype, setSubtipo] = useState(null);
    const [customeType, setCustometype] = useState(false);
    const [countResults, setCountResults] = useState(0);
    const [sizePlatform, setSizePlatform] = useState(null);
    const [openLanguage, setOpenLanguage] = useState(false);

    const [persistForm, setPersistForm] = useState(false);
    const [ref, setRef] = useState({});

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const INITIAL_FROM_STATE = {
        topic: '',
        otherTopic: '',
        platform: [],
        tone: '',
        audience: '',
        comments: '',
        otherType: '',
        type: '',
        subtype: ''
    };

    const FORM_VALIDATION = Yup.object().shape({
        //topic: Yup.mixed().required('The Topic is required'),
        otherType: Yup.mixed().when([], {
            is: () => customeType,
            then: Yup.mixed().required('The Type topic is required'),
            otherwise: Yup.mixed().notRequired()
        }),
        type: Yup.mixed().when([], {
            is: () => !customeType,
            then: Yup.mixed().required('The Type topic is required'),
            otherwise: Yup.mixed().notRequired()
        }),
        subtype: Yup.mixed().when([], {
            is: () => !customeType,
            then: Yup.mixed().required('The Subtype topic is required'),
            otherwise: Yup.mixed().notRequired()
        }),
        platform: Yup.mixed().required('The Platform is required'),
        tone: Yup.mixed().required('The Tone is required'),
        // audience: Yup.mixed().required('The Audience is required'),
        comments: Yup.mixed().required('The Comments is required')
    });

    useEffect( ()=> {
        if (props.form !== null) {
            const fun = async () => {
                // console.log('pre props', props);
                // await fun (idProject);
                // console.log('post props', project);
                setSubtipo(props.form.type);

                INITIAL_FROM_STATE.topic = props.form.topic;
                INITIAL_FROM_STATE.otherTopic = props.form.otherTopic;
                INITIAL_FROM_STATE.platform = props.form.platform;
                INITIAL_FROM_STATE.tone = props.form.tone;
                INITIAL_FROM_STATE.audience = props.form.audience;
                INITIAL_FROM_STATE.comments = props.form.comments;
                INITIAL_FROM_STATE.type = props.form.type;
                INITIAL_FROM_STATE.subtype = props.form.subtype;
                INITIAL_FROM_STATE.otherType = props.form.otherType;

                setCustometype(props.form.otherProps?.customeType);

                /*setDataAux({
                    topic: props.form.topic,
                    otherTopic: props.form.otherTopic,
                    platform: props.form.platform,
                    tone: props.form.tone,
                    audience: props.form.audience,
                    comments: props.form.comments,
                    type: props.form.type,
                    subtype: props.form.subtype,
                    otherType: props.form.otherType,
                    // language: form.outputLanguage,
                    // emailName: t('content.create.platformEmail'),
                })*/
                //setSaved(true);

                props.saveCurrentForm(null);
            }
            fun();
        } else {
            // props.resetProject(null);
            /*props.updateContentLanguage({
                input: 'en',
                output: 'en'
            })*/
        }
    }, []);


    const getContent = (data = null) => {
        const cont = data !== null ? data : {};
        const res = cont.map((item) => {
            return {
                loading: false,
                platform: item.platform,
                title: item.platform,
                content: item.text
            }
        })

        setPlatformResult(res);
        console.log('PLAT N', platformResult);
    }

    const onSubmitForm = async (values) => {
        // setContent("Generating...")
        setLoading(true);
        setPlatformResult([]);

        const subres = {};
        subres.topic = null;
        if (values.otherTopic !== '') {
            values.topic = values.otherTopic;
        } else {
            if (values.topic.topic) {
                subres.topic = values.topic;
                values.topic = values.topic.topic;
            }
        }

        const submitData = {
            topic: values.topic,
            otherTopic: values.otherTopic,
            platform: values.platform,
            tone: values.tone,
            audience: values.audience,
            comments: values.comments,
            type: customeType ? values.otherType : values.type?.recipient,
            // language: i18n.resolvedLanguage,
            subtype: customeType ? '' : values.subtype?.subrecipient,
            language: props.outputLanguage,
            emailName: t('content.create.platformEmail'),
        }

        subres.type = values.type;
        subres.subtype = values.subtype;


        console.log('NEW SUBMIT', values);
        console.log('NEW SUBMIT 2', submitData);

        setDataAux(submitData);

        // "usage" contain the number of the tokens(prompts_tokens, completion_tokens, total_tokens)
        let {content, prompt, usage} = await generateContentService(submitData);

        // console.log(platforms);
        console.log(content, prompt);
        // setContent(content);
        getContent(content);
        // setPlatformResult(tempPlatform);

        console.log('PLAT2', platformResult);
        setLoading(false);

        toastr.success('', t('content.create.success'));
        // console.log(data);

        if (usage) {
            // count number of words generated
            let completionWords = 0;
            content.map((item) => {
                completionWords += countWords(item.text, props.outputLanguage);
            })

            // update number of words, number of tokens generated
            ///TODO replace this for the current workspace selected
            if (props.user?.analytics_workspaces?.length > 0) {
                await saveCountTokensWordsService({
                    tokenCount: usage.completion_tokens,
                    wordCount: completionWords,
                    analyticsWorkspaceId: props.user.analytics_workspaces[0].id
                })
            }
        }
    }

    const date = new Date();
    const minutes = date.getMinutes();
    let nextUpdate;
    if (minutes <= 30) nextUpdate = 30 - minutes;
    else nextUpdate = 60 - minutes;

    async function getTopics() {

        // let {data} = await axios.post('https://new-messages-webhook-dev.azurewebsites.net/api/get-chats-topics', {tenantId: props.tenantId});
        let data = await getCustomeTopicsService({tenantId: props.tenantId});

        if (data.length > 0) {
            data = data.map((item) => {
                item.groupName = 'Customized topics';
                return item;
            })
            setTopics(data);
        } else setTopics([{
            empty: true,
            topic: `To access integration features, you'll need to upgrade your license. Please reach out to support for assistance at support@mymoodbit.com`

        }])
        // setTopics(data.concat(defaultTopics));
        console.log(data);
    }

    if (topics.length === 0)
        getTopics();

    const getLang = (key) => {
        let ln = null;
        lngs.map((it) => {
            if (it.key === key) {
                ln = it;
            }
        })

        return ln;
    }

                const goToContact = () => {
        console.log(ref);
        props.saveCurrentForm({...ref, otherProps: {customeType}});
        navigate('/contact-sales')
    }

    return (
        <div className="w-100">
            {/*<p className="text-secondary">Next update in {nextUpdate} minute(s)</p>*/}
            <div className="row">
                <div className="col-md-6">
                    <Formik
                            innerRef={(fa) => fa ? setRef(fa.values) : setRef({})}
                            initialValues={INITIAL_FROM_STATE}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={values => onSubmitForm(values)}>
                        <Form>
                            <div className="row mb-3 max-h-60">
                                {/*its for*/}
                                <div className="col-md-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title text-primary"><Chip size="small"
                                                                                          label="1"/> {t('content.create.categoryTitle')}:
                                            </h4>
                                            {!customeType ?
                                                <div className="form-group my-2 d-flex align-items-center">
                                                    <AutocompleteFieldWrapperChip name="type"
                                                                                  label={t('content.create.selectOne')}
                                                                                  labelValue="recipient"
                                                                                  labelTranslate="translate"
                                                                                  updateState={setSubtipo}
                                                                                  idValue="recipient"
                                                                                  options={testRecipients}/>
                                                    <Tooltip title="Custom Type">
                                                        <IconButton className="ms-2"
                                                                onClick={() => setCustometype(!customeType)}>
                                                        <Edit color="primary"/>
                                                    </IconButton>
                                                    </Tooltip>

                                                    {/*<AutocompleteFieldWrapper name="type"
                                                                          label={t('content.create.selectOne')}
                                                                          labelValue="recipient"
                                                                          updateState={setSubtipo}
                                                                          idValue="recipient"
                                                                          options={recipients}/>*/}
                                                </div> :
                                                <div className="form-group my-2">
                                                    <TextFieldWrapper name="otherType"
                                                                      label={'Type one'}
                                                                      InputProps={{
                                                                          endAdornment:
                                                                              <InputAdornment position="end">
                                                                                  <Tooltip title="Select Type">
                                                                                  <IconButton
                                                                                      color="primary"
                                                                                      onClick={() => setCustometype(!customeType)}
                                                                                      edge="end">
                                                                                      <FormatListBulleted/>
                                                                                  </IconButton>
                                                                                  </Tooltip>
                                                                              </InputAdornment>

                                                                      }}
                                                    />
                                                </div>}
                                            {subtype && !customeType ? <div className="mt-3">
                                                <p className="card-subtitle">{t('content.create.subCategory')}*</p>
                                                <div className="form-group my-2">
                                                    <AutocompleteFieldWrapperChip name="subtype"
                                                                                  label={t('content.create.selectOne')}
                                                                                  labelValue="subrecipient"
                                                                                  labelTranslate="translate"
                                                                                  idValue="subrecipient"
                                                                                  options={subtype.children}/>
                                                </div>
                                            </div> : null}
                                        </div>
                                    </div>
                                </div>
                                {/*topics*/}
                                <div className="col-md-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title text-primary"><Chip size="small"
                                                                                          label="2"/> {t('content.create.topicTitle')}:
                                            </h4>
                                            <p className="card-description">{t('content.create.topicSubtitle')}</p>
                                            {/*<RadioFieldWrapper options={topics} idValue="topic" labelValue="topic" name="topic"/>*/}
                                            {/*<AutocompleteFieldWrapper name="topic"
                                                                      label="Topics" labelValue="topic" idValue="topic"
                                                                      options={topics}/>
                                            <RadioCustome name="topic"/>*/}
                                            <div className="form-group my-2">
                                                <TextFieldWrapper name="otherTopic"
                                                                  label={t('content.create.topic')}
                                                                  multiline={true}
                                                                  rows={3}/>
                                            </div>
                                            {topics.length > 0 ?
                                                <div className="mt-4">
                                                    <h4 className="card-title text-primary">
                                                        {t('content.create.customeTopicTitle')}
                                                        <Tooltip title={<em>{topics[0].topic}</em>}>
                                                            <HelpOutline className="ms-2 text-info"
                                                                         style={{cursor: 'pointer'}} fontSize="small"/>
                                                        </Tooltip>
                                                    </h4>
                                                    <p className="card-description">{t('content.create.topicSubtitle')} </p>
                                                    {/*<RadioFieldWrapper options={topics} idValue="topic" labelValue="topic" name="topic"/>*/}

                                                    {topics[0].empty === true ?

                                                        <AutocompleteFieldWrapper name="topic-none"
                                                                                  style={{cursor: 'pointer'}}
                                                                                              onClick={goToContact}
                                                                                  readOnly
                                                                                  value={topics[0]}
                                                                                  label={t('content.create.topics')}
                                                                                  labelValue="topic" idValue="topic"
                                                                                  options={topics}/>

                                                        :
                                                        <AutocompleteFieldWrapper name="topic"
                                                            // disabled
                                                                                  label={t('content.create.topics')}
                                                                                  labelValue="topic" idValue="topic"
                                                                                  options={topics}/>
                                                    }

                                                    {/*<AutocompleteFieldWrapperList name="topic"
                                                                                  label={t('content.create.topics')}
                                                                                  labelValue="topic" idValue="topic"
                                                                                  options={testTopics}/>*/}

                                                </div> : <div className="text-center">
                                                    <RotatingSpinner/>
                                                    <p>{t('content.create.topicLoading')}...</p>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                                {/*platform*/}
                                <div className="col-md-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title text-primary">
                                                <Chip size="small" label="3"/> {t('content.create.channelTitle')}
                                            </h4>
                                            <CheckFieldWrapper options={platforms} idValue="idPlatform"
                                                               prefixTranslate={'content.create.platform'}
                                                               labelValue="platform" name="platform"/>
                                        </div>
                                    </div>
                                </div>
                                {/*details*/}
                                <div className="col-md-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title text-primary"><Chip size="small" label="4"
                                            /> {t('content.create.detailTitle')}</h4>
                                            <div className="form-group my-2">
                                                <TextFieldWrapper name="comments"
                                                                  label={t('content.create.comments')}
                                                                  multiline={true}
                                                                  rows={3}/>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                {/*tone*/}
                                <div className="col-md-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title text-primary"><Chip size="small" label="5"
                                            /> {t('content.create.toneTitle')}</h4>
                                            <div className="form-group">
                                                <SelectFieldWrapper name="tone"
                                                                    label={t('content.create.tone')}
                                                                    simple={true}
                                                                    prefixTranslate={'content.create.tone'}
                                                                    labelValue="name"
                                                                    idValue="name"
                                                                    options={tones}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*audience*/}
                                <div className="col-md-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title text-primary"><Chip size="small" label="6"
                                            /> {t('content.create.audienceTitle')}</h4>
                                            <div className="form-group my-2">
                                                <TextFieldWrapper name="audience"
                                                                  label={t('content.create.typeAudience')}
                                                                  type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mb-3">
                                <ButtonWrapper>{t('content.create.generate')}</ButtonWrapper>
                            </div>
                            {/** For persist data form*/}
                            {/*persistForm ? <Persist name="content-form" /> : null*/}
                        </Form>
                    </Formik>
                </div>
                <div className="col-md-6 grid-margin stretch-card max-h-60">
                    <div className="w-100">
                        {loading ?
                            <div className="card">
                                <div className="card-body">
                                    <div className="w-100 text-center">
                                        {/*<img className="my-2" width="50%" src={Making} alt=""/>*/}
                                        <div className="text-center">
                                            <div className="d-flex justify-content-center text-center">
                                                <InfinitySpin
                                                    color={palette.primary}
                                                    width="200"
                                                    visible={true}/>
                                            </div>
                                            <p className='card-description line-break'>{t('content.create.rendering')}...</p>
                                            {/*<p><small><em>{countResults} / {sizePlatform}</em></small></p>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :

                            platformResult.length > 0 ?
                                platformResult.map((plat, index) =>
                                    <Content plat={plat}
                                             key={index + plat.platform}
                                             onDelete={() => {
                                                 if (window.confirm(`${t('content.create.confirmDelete')} ${plat.platform}?`)) {
                                                     const temp = platformResult.filter((it, i) => i !== index)
                                                     console.log(temp);
                                                     setPlatformResult(temp);
                                                     console.log('RES', platformResult);
                                                 }
                                             }}
                                             dataAux={dataAux}/>
                                ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        inputLanguage: state.contents.inputLanguage,
        outputLanguage: state.contents.outputLanguage,
        form: state.contents.form,
        user: state.users.user,
        status: state.users.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveCurrentForm: (obj) => dispatch(saveForm(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContent);
